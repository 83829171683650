import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { AppSettings } from '../../../settings';
import CallApi from 'components/common/custom-components/CallApi';

const Create = ({ loadListing, setShow, competitorId, setCompetitorId }) => {
  const [newCompetitor, setNewCompetitor] = useState({
    name: '',
    url: '',
    description: ''
  });

  useEffect(() => {
    if (competitorId) {
      CallApi.get(`/competitors/${competitorId}`, false).then(data => {
        if (data.status == 200) {
          setNewCompetitor({
            name: data.data.name,
            url: data.data.url,
            description: data.data.description ? data.data.description : ''
          });
        }
      });
    }
  }, [competitorId]);

  const { name, url, description } = newCompetitor;

  const [validated, setValidated] = useState(false);

  const onInputChange = e => {
    setNewCompetitor({ ...newCompetitor, [e.target.name]: e.target.value });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    setValidated(true);

    let formData = new FormData();

    for (var key in newCompetitor) {
      formData.append(key, newCompetitor[key]);
    }

    if (competitorId === '') {
      CallApi.post(`/competitors`, formData).then(response => {
        if (response) {
          loadListing();
          setShow(false);
          setCompetitorId('');
        }
      });
    } else {
      CallApi.put(`/competitors/${competitorId}`, formData).then(response => {
        if (response) {
          loadListing();
          setShow(false);
          setCompetitorId('');
        }
      });
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            placeholder="Enter name"
            onChange={e => onInputChange(e)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicUrl">
          <Form.Label>Url</Form.Label>
          <Form.Control
            type="text"
            name="url"
            value={url}
            placeholder="Enter url"
            onChange={e => onInputChange(e)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a url.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            value={description}
            name="description"
            onChange={e => onInputChange(e)}
            placeholder="Enter description"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicBtn">
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Button variant="muted" onClick={() => setShow(false)}>
            Close
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default Create;
