import PageInfo from 'components/common/custom-components/PageInfo';
import React from 'react';
import Listing from './components/Listing';

const Reports = () => {
  return (
    <>
      <PageInfo slug="reports" />
      <Listing />
    </>
  );
};

export default Reports;
