import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import SingleTask from './SingleTask';

export const TasksListing = () => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [currentListing, setCurrentListing] = useState('my-tasks');
  const [previousListing, setPreviousListing] = useState('');
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  useEffect(() => {
    loadTasks();
  }, [currentListing]);

  const loadTasks = () => {
    if (currentListing != previousListing) setApiCallInProgress(true);

    let url =
      currentListing == 'my-tasks'
        ? '/task/user?status=open'
        : '/task?status=open';
    CallApi.get(url, false).then(json => {
      if (json.status == 200) {
        setData(json.data);
        setApiCallInProgress(false);
        setPreviousListing(currentListing);
      }
    });
  };

  const columns = [
    {
      accessor: 'client_or_user',
      Header: 'Client/User',
      Cell: ({ cell }) => (
        <NameWithIcon
          icon={cell.value?.favicon || cell.value?.avatar_url}
          name={cell.value?.name}
        />
      )
    },
    {
      accessor: 'related_to',
      Header: 'Related To',
      Cell: ({ cell }) => {
        return (
          <Button
            className="ps-0"
            variant="link"
            size="sm"
            onClick={() => showModal(cell.row.original.id)}
          >
            {cell.value}
          </Button>
        );
      }
    },
    {
      accessor: 'level',
      Header: 'Task Level'
    },
    {
      accessor: 'name',
      Header: 'Task'
    },
    {
      accessor: 'priority_level',
      Header: 'Priority',
      Cell: ({ cell }) => {
        if (cell.row.values.priority_level == 1) {
          return (
            <SoftBadge pill bg="danger">
              High
            </SoftBadge>
          );
        }
        if (cell.row.values.priority_level == 2) {
          return (
            <SoftBadge pill bg="warning">
              Medium
            </SoftBadge>
          );
        }
        if (cell.row.values.priority_level == 3) {
          return (
            <SoftBadge pill bg="info">
              Low
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="primary">
            Normal
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Created'
    },
    {
      accessor: 'updated_at',
      Header: 'Last Modified'
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ cell }) => {
        return (
          <SoftBadge
            className="text-capitalize"
            pill
            bg={cell.value == 'open' ? 'success' : 'dark'}
          >
            {cell.value}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'seen',
      Header: 'Seen/New',
      Cell: ({ cell }) => {
        return (
          <SoftBadge
            className="text-capitalize"
            pill
            bg={cell.value ? 'dark' : 'success'}
          >
            {cell.value ? 'Seen' : 'New'}
          </SoftBadge>
        );
      }
    }
  ];

  const showModal = taskId => {
    if (taskId) setCurrentTaskId(taskId);
    setModal(true);
  };

  const TasksTable = () => {
    return (
      <Card>
        <Card.Header className="pb-0">
          <Row>
            <Col className="d-flex align-items-center">
              <Nav
                className="nav-pills-falcon m-0"
                defaultActiveKey={currentListing}
                variant="pills"
              >
                <Nav.Item>
                  <Nav.Link
                    as={Button}
                    size="sm"
                    onClick={() => setCurrentListing('my-tasks')}
                    eventKey="my-tasks"
                  >
                    My Tasks
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Button}
                    size="sm"
                    onClick={() => setCurrentListing('all-tasks')}
                    eventKey="all-tasks"
                  >
                    All Tasks
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="px-0 py-2">
          <AdvanceTableWrapper
            columns={columns}
            data={data}
            sortable
            pagination
            selection
            selectionColumnWidth={50}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                size: 'sm',
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProgress}
            />
            <AdvanceTableFooter
              className="mt-3 mx-3"
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <TasksTable />
      <SingleTask
        modal={modal}
        setModal={setModal}
        taskId={currentTaskId}
        setTaskId={setCurrentTaskId}
        refreshListing={loadTasks}
      />
    </>
  );
};
