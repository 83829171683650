import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Spinner, Table } from 'react-bootstrap';
const AdvanceTable = ({
  getTableProps,
  highlightSelectedRow = false,
  headers,
  page,
  prepareRow,
  rows,
  getTableBodyProps,
  headerGroups,
  headerClassName,
  rowClassName,
  tableProps,
  tableFooterClassName = 'sticky-bottom shadow bg-200',
  footerGroups,
  totalColumnsWidth,
  selectedFlatRows,
  virtualRows = false,
  virtualRowsTableOffsetHeight = `30rem`,
  rowProps = row => {},
  SelectedRowsTableFooterRow = selectedFlatRows => {},
  renderRowSubComponent,
  useTableFooter,
  apiCallInProcess = false
}) => {
  const TableJSX = () => (
    <Table {...getTableProps(tableProps)}>
      <thead
        className={`${
          virtualRows ? `sticky-top z-index-1 shadow` : ''
        } ${headerClassName}`}
      >
        <tr>
          {headers.map((column, index) => (
            <th
              key={index}
              {...column.getHeaderProps({
                ...column.headerProps,
                ...{
                  className: column.canResize
                    ? `bg-200 ${column.headerProps?.className || ''}`
                    : column.headerProps?.className || ''
                }
              })}
            >
              {column.canFilter ? column.render('Filter') : null}
              {column.render('Header')}
              <span {...column.getSortByToggleProps()}>
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc ms-1" />
                    ) : (
                      <span className="sort asc ms-1" />
                    )
                  ) : (
                    <span className="sort ms-1" />
                  )
                ) : (
                  ''
                )}
              </span>
              {column.canResize ? (
                <div
                  {...column?.getResizerProps(column?.resizingProps)}
                  className={`resizer ${column.canResize ? '' : 'd-none'} ${
                    column?.isResizing ? 'bg-primary' : 'bg-secondary'
                  }`}
                  style={{
                    display: 'inline-block',
                    width: '2px',
                    height: '100%',
                    position: 'absolute',
                    right: 0,
                    cursor: 'col-resize',
                    top: 0,
                    transform: 'translateX(50%)',
                    zIndex: 1
                  }}
                />
              ) : null}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {apiCallInProcess ? (
          <tr>
            <td colSpan={headers.length} className="text-center">
              <div>
                <Spinner
                  size="sm"
                  as="span"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
                <span className="ms-2">Loading ....</span>
              </div>
            </td>
          </tr>
        ) : page.length == 0 ? (
          <tr>
            <td colSpan={headers.length} className="text-center">
              <div>
                <span className="ms-2">No Results found</span>
              </div>
            </td>
          </tr>
        ) : (
          page.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr
                  className={`${
                    row.isSelected ? 'highlight-table-row' : ''
                  } ${rowClassName}`}
                  {...row.getRowProps(rowProps(row))}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...{
                          ...cell.getCellProps(cell.column.cellProps),
                          ...{
                            className: `overflow-hidden ${cell.column.cellProps?.className}`
                          }
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={headers.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })
        )}
      </tbody>
      {useTableFooter && (
        <tfoot className={tableFooterClassName}>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <td {...column.getFooterProps(column.footerProps)}>
                  {column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
          {SelectedRowsTableFooterRow(selectedFlatRows)}
        </tfoot>
      )}
    </Table>
  );

  return (
    <>
      {virtualRows ? (
        <div
          className="virtualized-rows-wrapper"
          style={{
            height: `calc(100vh - ${virtualRowsTableOffsetHeight})`,
            minHeight: `300px`
          }}
        >
          <TableJSX />
        </div>
      ) : (
        <SimpleBarReact>
          <TableJSX />
        </SimpleBarReact>
      )}
    </>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  apiCallInProcess: PropTypes.bool
};

export default AdvanceTable;
