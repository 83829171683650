import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import { deleteItemFromStore } from 'helpers/utils';
import { AppSettings } from '../../../settings';
import { profileDropdownRoutes } from 'routes/routes';
import { AidaAppContext } from 'context/Context';

const ProfileDropdown = () => {
  const [user, setUser] = useState({});
  const { aidaAppContext, setAidaAppContext } = useContext(AidaAppContext);

  useEffect(() => {
    CallApi.get('/me').then(json =>
      json.status == 200 ? setUser(json.data) : null
    );
  }, []);

  const getName = () => {
    if (user.first_name) {
      if (user.last_name) {
        return user.first_name + ' ' + user.last_name;
      } else {
        return user.first_name;
      }
    } else {
      return user.username;
    }
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={'a'}
        className="pe-0 nav-link cursor-pointer"
      >
        <Avatar src={user.avatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            className="fw-bold text-warning"
            target="_blank"
            href={
              aidaAppContext.user_permissions?.role == 'Admin'
                ? AppSettings.apiHost + '/profile'
                : null
            }
          >
            <span>{getName()}</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          {aidaAppContext.user_permissions?.access_profile_links && (
            <>
              {profileDropdownRoutes.map((route, index) => (
                <Dropdown.Item key={index} as={Link} to={route.to}>
                  {route.name}
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item
            as={Link}
            to="/app/logout"
            onClick={() => {
              CallApi.post('/logout').then(json => {
                setAidaAppContext({ ...aidaAppContext, user_permissions: {} });
                deleteItemFromStore('aidaToken');
              });
            }}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
