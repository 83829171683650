import ExportXLSX from 'components/aida/budget-overviews/components/ExportXLSX';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const DomainHistoryOverviewTable = ({ source, domain, refetch }) => {
  const columns = [
    { accessor: 'year', Header: 'Year' },
    {
      accessor: 'month',
      Header: 'Month',
      Cell: ({ cell }) => cell.row.original.month_name
    },
    {
      accessor: 'keywords_count',
      Header: 'Keywords Count',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'top1_5',
      Header: 'Top 1-5',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'top6_10',
      Header: 'Top 6-10',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'top11_20',
      Header: 'Top 11-20',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'top21_50',
      Header: 'Top 21-50',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'top51_100',
      Header: 'Top 51-100',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'traffic_sum',
      Header: 'Traffic Sum',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'price_sum',
      Header: 'Price Sum',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  ];
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const loadListing = () => {
    if (domain && source) {
      setApiCallInProcess(true);
      CallApi.get(
        `/competitors/se-ranking-reports/domain-history-overview?source=${source}&domain=${domain}`,
        apiCallInProcess
      ).then(json => {
        if (json.status == 200) {
          setApiCallInProcess(false);
          setData(json.data);
        }
      });
    }
  };

  useEffect(() => {
    loadListing();
  }, [refetch]);

  return (
    <Card>
      <Card.Body>
        <AdvanceTableWrapper columns={columns} data={data} sortable pagination>
          <Row className="align-items-center mb-3">
            <Col>
              <h5>Domain History</h5>
            </Col>
            <Col xs="auto" className="text-end">
              <Row xs="auto">
                <Col className="pe-0">
                  <AdvanceTableSearchBox table />
                </Col>
                <Col xs="auto" className="ps-0">
                  <ExportXLSX
                    table
                    sheetHeaderRow={[
                      'Year',
                      'Month',
                      'Keywords Count',
                      'Top 1-5',
                      'Top 6-10',
                      'Top 11-20',
                      'Top 21-50',
                      'Top 51-100',
                      'Traffic Sum',
                      'Price Sum'
                    ]}
                    filename={`${domain} - ${source} - Domain History`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden border'
            }}
            apiCallInProcess={apiCallInProcess}
          />
          <AdvanceTableFooter
            table
            className="mt-3 mx-3"
            rowCount={data.length}
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default DomainHistoryOverviewTable;
