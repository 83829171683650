import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';

const ReactSelectBoxIconLabel = ({ icon, title }) => {
  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon={icon} className="me-1" />
      {title}
    </div>
  );
};

export default ReactSelectBoxIconLabel;
