import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import ErrorBoundary from 'components/common/custom-components/ErrorBoundry';
import { AidaAppContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import useCustomTimeIntervalSelectBox from '../../custom-form-hooks/useCustomTimeIntervalSelectbox';

const ScriptSettings = ({ invokerId, register, scriptData }) => {
  const { aidaAppContext } = useContext(AidaAppContext);
  const [script, setScript] = useState(scriptData);
  const [data, setData] = useState([]);

  const getSettings = () =>
    CallApi.get(`/lambda_script_settings?invoker_id=${invokerId}`, false).then(
      json => setData(json.data)
    );

  useEffect(() => {
    if (aidaAppContext.client?.id) getSettings();
  }, [aidaAppContext.client?.id, invokerId]);

  const IntervalForClient = useCustomTimeIntervalSelectBox();
  const IntervalForScript = useCustomTimeIntervalSelectBox();

  const getBgClassesFor = value =>
    classNames({
      'bg-soft-dark': script.current_settings == value
    });

  const onScriptParameterChange = (event, index) => {
    let newData = [...data];
    newData[index] = {
      ...data[index],
      value:
        event.target.type == 'checkbox'
          ? event.target.checked
          : event.target.value
    };
    setData(newData);
  };

  const onClientParameterChange = (event, index) => {
    let newData = [...data];
    newData[index] = {
      ...data[index],
      client_value:
        event.target.type == 'checkbox'
          ? event.target.checked
          : event.target.value
    };
    setData(newData);
  };

  return (
    <Table
      bordered
      className="fs--1 mb-0 overflow-hidden"
      id="scriptSettings"
      responsive
    >
      <thead className="bg-200 text-900 text-nowrap align-middle">
        <tr>
          <th>Setting/Parameter</th>
          <th className="text-center">Global Values</th>
          <th className="text-center">Custom Values</th>
        </tr>
      </thead>
      <tbody>
        <tr className="align-middle white-space-nowrap">
          <td>Active</td>
          <td className={'text-center ' + getBgClassesFor('script')}>
            <Form.Switch
              name={`status_for_script`}
              value={script.status_for_script || ''}
              checked={script.status_for_script || false}
              onChange={e =>
                setScript({
                  ...script,
                  status_for_script: e.target.checked
                })
              }
              disabled
            />
          </td>
          <td className={'text-center ' + getBgClassesFor('client')}>
            <Form.Switch
              name={`status_for_client`}
              value={script.status_for_client || ''}
              checked={script.status_for_client || false}
              onChange={e =>
                setScript({
                  ...script,
                  status_for_client: e.target.checked
                })
              }
            />
          </td>
        </tr>
        <tr className="align-middle white-space-nowrap">
          <td>Interval</td>
          <td className={'text-center ' + getBgClassesFor('script')}>
            <IntervalForScript
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              name={`interval_for_script`}
              value={script.interval_for_script_id || ''}
              onChange={interval =>
                setScript({
                  ...script,
                  interval_for_script_id: interval.id
                })
              }
              isDisabled
            />
          </td>
          <td className={'text-center ' + getBgClassesFor('client')}>
            <IntervalForClient
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              name={`interval_for_client`}
              value={script.interval_for_client_id || ''}
              onChange={interval =>
                setScript({
                  ...script,
                  interval_for_client_id: interval.id
                })
              }
            />
          </td>
        </tr>
        {data.map((row, i) => (
          <tr className="align-middle white-space-nowrap" key={i}>
            <td>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Default: {data[i].value}
                    <br /> Info: {data[i].tooltip_text}
                  </Tooltip>
                }
              >
                <span className="me-2">
                  <FontAwesomeIcon icon="info-circle" />
                </span>
              </OverlayTrigger>
              {data[i].parameter_title}
              <input
                hidden
                readOnly
                name={`parameters[parameter_${data[i].parameter_id}][id]`}
                value={data[i].parameter_id || ''}
              />
            </td>
            <td className={'text-center ' + getBgClassesFor('script')}>
              {data[i].parameter_type == 'checkbox' ? (
                <Form.Check
                  value={data[i].value || ''}
                  checked={+data[i].value || false}
                  name={`parameters[parameter_${data[i].parameter_id}][value]`}
                  onChange={e => onScriptParameterChange(e, i)}
                  disabled
                />
              ) : (
                <Form.Control
                  size="sm"
                  type={data[i].parameter_type}
                  value={data[i].value || ''}
                  name={`parameters[parameter_${data[i].parameter_id}][value]`}
                  onChange={e => onScriptParameterChange(e, i)}
                  disabled
                />
              )}
            </td>
            <td className={'text-center ' + getBgClassesFor('client')}>
              {data[i].parameter_type == 'checkbox' ? (
                <Form.Check
                  value={data[i].client_value || ''}
                  checked={+data[i].client_value || false}
                  name={`parameters[parameter_${data[i].parameter_id}][client_value]`}
                  onChange={e => onClientParameterChange(e, i)}
                />
              ) : (
                <Form.Control
                  size="sm"
                  type={data[i].parameter_type}
                  value={data[i].client_value || ''}
                  name={`parameters[parameter_${data[i].parameter_id}][client_value]`}
                  onChange={e => onClientParameterChange(e, i)}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ScriptSettings;
