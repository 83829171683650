import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Button, Row, Col, Modal, Form, Card } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Create from './Create';
import { AppSettings } from '../../../settings';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';

const MonthlyNotes = () => {
  const [notes, setNotes] = useState([]);
  const [show, setShow] = useState(false);
  const [noteId, setNoteId] = useState('');
  const [clients, setClients] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);

  const columns = [
    {
      accessor: 'month',
      Header: 'Month'
    },
    {
      accessor: 'year',
      Header: 'Year'
    },
    {
      accessor: 'note',
      Header: 'Note'
    },
    {
      accessor: 'client.name',
      Header: 'Client'
    },
    {
      accessor: 'id',
      Header: 'Action',
      Cell: ({ cell }) => {
        return (
          <>
            <IconButton
              icon="trash"
              size="sm"
              className="me-2"
              onClick={() => deleteNote(cell.row.values.id)}
            ></IconButton>
            <IconButton
              icon="edit"
              size="sm"
              onClick={() => updateNote(cell.row.values.id)}
            ></IconButton>
          </>
        );
      }
    }
  ];

  function deleteNote(id) {
    var result = confirm("Attention! Are you sure you'd like to delete?");
    if (!result) {
      return;
    }
    CallApi.delete(`/monthly-notes/${id}`).then(record => {
      if (record) loadListing();
    });
  }

  function updateNote(id) {
    setNoteId(id);
    setShow(true);
  }

  function loadListing() {
    setApiCallInProcess(true);
    CallApi.get(`/monthly-notes`).then(data => {
      setApiCallInProcess(false);
      if (data.status == 200) {
        setNotes(data.data);
      }
    });
  }

  function loadClientListing() {
    CallApi.get(`/clients`, false).then(data => {
      if (data.status == 200) setClients(data.data);
    });
  }

  useEffect(() => {
    loadListing();
    loadClientListing();
  }, []);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setNoteId('');
    setShow(false);
  };

  return (
    <>
      <PageInfo slug="monthly_notes" />
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={notes}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col className="col-auto" xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
              <Col className="col">
                <IconButton
                  icon="plus"
                  size="sm"
                  className="float-end"
                  onClick={handleShow}
                >
                  Add New
                </IconButton>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProcess}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={notes.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add a new Monthly Note
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Create
            loadListing={loadListing}
            setShow={setShow}
            noteId={noteId}
            setNoteId={setNoteId}
            clients={clients}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MonthlyNotes;
