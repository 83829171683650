import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import useUrl from 'hooks/useUrl';
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ExportXLSX from '../budget-overviews/components/ExportXLSX';
import { GlobalNegativeKeywordContext } from './GlobalNegativeKeywords';

const Listing = () => {
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(true);
  const { context, setContext } = useContext(GlobalNegativeKeywordContext);
  const query = useUrl();

  useEffect(() => {
    CallApi.get('/global-negatives?account_id=' + query.get('account_id')).then(
      json => {
        setApiCallInProcess(false);
        if (json.status == 200) {
          setData(json.data);
        }
      }
    );
  }, [context.refresh, query.get('account_id')]);

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: ({ cell }) => {
        const [disabled, setDisabled] = useState(false);
        return (
          <IconButton
            icon="trash-alt"
            variant="danger"
            size="sm"
            disabled={disabled}
            onClick={() => {
              setDisabled(true);
              CallApi.delete('/global-negatives/' + cell.value).then(json =>
                json
                  ? setContext({ ...context, refresh: !context.refresh })
                  : console.error('Global Negative Keyword Deletion Failed')
              );
            }}
          ></IconButton>
        );
      }
    },
    { accessor: 'keyword', Header: 'Keyword' },
    { accessor: 'category', Header: 'Category' },
    { accessor: 'match_type', Header: 'Match Type' },
    { accessor: 'created_at', Header: 'Date Added' },
    { accessor: 'created_by', Header: 'Added By' }
  ];

  return (
    <Card className="mb-3">
      <Card.Body>
        <AdvanceTableWrapper columns={columns} data={data} sortable pagination>
          <Row className="align-items-end g-2 row mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col />
            <Col xs="auto">
              <ExportXLSX
                table
                sheetHeaderRow={[
                  'Keyword',
                  'Category',
                  'Match Type',
                  'Date Added',
                  'Added By'
                ]}
                filename={`${getYYYYMMDDFormatDate(new Date())} - ${query.get(
                  'account_id'
                )} - Kw Global Negatives`}
                ignoreableColumnIndexes={[4]}
              />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={apiCallInProcess}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default Listing;
