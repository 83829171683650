import PageInfo from 'components/common/custom-components/PageInfo';
import React, { createContext, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import FlexAbsenceApprove from './FlexAbsenceApprove';
import FlexAbsenceCreate from './FlexAbsenceCreate';
import FlexAbsenceList from './FlexAbsenceList';
import FlexAbsenceTotal from './FlexAbsenceTotal';

export const FlexAbsenceContext = createContext({});

function FlexAbsence() {
  const [context, setContext] = useState({ refresh: false });

  return (
    <FlexAbsenceContext.Provider value={{ context, setContext }}>
      <PageInfo slug="flex_absences" />
      <Row>
        <Col md={6} lg={8}>
          <FlexAbsenceTotal className="d-block d-lg-none" />
          <FlexAbsenceList />
        </Col>
        <Col md={6} lg={4}>
          <FlexAbsenceTotal className="d-none d-lg-block" />
          <FlexAbsenceCreate />
          <Alert className="mt-3">
            - Time means you have worked less than expected. <br /> + Time means
            you have worked extra.
          </Alert>
          <FlexAbsenceApprove />
        </Col>
      </Row>
    </FlexAbsenceContext.Provider>
  );
}

export default FlexAbsence;
