import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FlexAbsenceContext } from './FlexAbsence';

function FlexAbsenceList() {
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(true);
  const { context, setContext } = useContext(FlexAbsenceContext);
  const history = useHistory();

  useEffect(() => {
    CallApi.get('/flex-absence').then(json => {
      if (json.status == 200) {
        setContext({
          ...context,
          approved_flex_absences_till: json.approved_flex_absences_till
        });
        setData(json.data);
        setApiCallInProcess(false);
      }
      if (json.status == 403) {
        history.push('/unauthorized');
      }
    });
  }, [context.refresh]);

  const checkIfRemoveable = entryDate => {
    return entryDate <= context.approved_flex_absences_till;
  };

  const columns = [
    {
      accessor: 'removable',
      Header: 'Actions',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: ({ cell }) => {
        const [disabled, setDisabled] = useState(false);
        return (
          <>
            {checkIfRemoveable(cell.row.original.date) ? (
              <FontAwesomeIcon
                icon={['fas', 'check']}
                size="lg"
                className="text-success"
                title="Active"
              />
            ) : (
              <IconButton
                icon="trash"
                variant="danger"
                size="sm"
                disabled={disabled}
                onClick={() => {
                  setDisabled(true);
                  CallApi.delete('/flex-absence/' + cell.row.original.id).then(
                    json =>
                      json
                        ? setContext({ ...context, refresh: !context.refresh })
                        : console.debug('Flex Record Deletion Failed')
                  );
                }}
              ></IconButton>
            )}
          </>
        );
      }
    },
    { accessor: 'date', Header: 'Date' },
    { accessor: 'day', Header: 'Day' },
    { accessor: 'category', Header: 'Category' },
    { accessor: 'entire_day', Header: 'Entire Day' },
    {
      accessor: 'sortable_hours',
      Header: 'Hours',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' },
      sortType: 'basic',
      Cell: ({ cell }) => cell.row.original.hours
    },
    { accessor: 'note', Header: 'Note' }
  ];

  return (
    <Card className="mb-3">
      <Card.Body>
        <AdvanceTableWrapper columns={columns} data={data} sortable pagination>
          <Row className="align-items-end g-2 row mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={apiCallInProcess}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
}

export default FlexAbsenceList;
