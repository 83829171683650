import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import CallApi from 'components/common/custom-components/CallApi';
import { setItemToStore } from 'helpers/utils';
import { AidaAppContext } from 'context/Context';

const LoginForm = ({ hasLabel, layout }) => {
  const { aidaAppContext, setAidaAppContext } = useContext(AidaAppContext);
  const history = useHistory();

  // State
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    remember: false,
    device_name: 'Web App'
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    let payload = new FormData();
    for (const field in formData) {
      if (Object.hasOwnProperty.call(formData, field)) {
        const value = formData[field];
        payload.append(field, value);
      }
    }
    CallApi.post('/login', payload).then(json => {
      if (json.status == 200) {
        setItemToStore('aidaToken', json.token);
        toast.success('Logged In successfully.');
        setAidaAppContext({
          ...aidaAppContext,
          refetchPermissions: !aidaAppContext.refetchPermissions
        });
        history.push('/');
      }
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Username</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Username' : ''}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="username"
          autoComplete="username"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          autoComplete="current-password"
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          {/* <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="ms-2 mb-0">
              Remember Me
            </Form.Check.Label>
          </Form.Check> */}
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forget Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.username || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider> */}

      {/* <SocialAuthButtons /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
