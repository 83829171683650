import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import EditableCell from 'components/common/advance-table/EditableCell';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import { capitalize } from 'helpers/utils';
import React, { useEffect, useState } from 'react';

const MonthlyNotes = ({ client_id }) => {
  const [data, setData] = useState([]);

  const columns = [
    {
      accessor: 'month',
      Header: 'Month',
      Cell: ({ cell }) => capitalize(cell.value)
    },
    {
      accessor: 'note',
      Header: 'Note',
      Cell: ({ cell }) => (
        <EditableCell cell={cell} updateDataFunction={upsertMonthlyNote} />
      )
    }
  ];

  const upsertMonthlyNote = (row, newValue) => {
    let formData = new FormData();

    if (typeof newValue === 'string') {
      formData.append('note', newValue);
    }

    formData.append('month', row.month);
    formData.append('client_id', client_id);

    CallApi.post(`/clients/monthly-notes`, formData, false);
  };

  const getData = () => {
    CallApi.get(`/clients/${client_id}/monthly-notes`, false).then(json => {
      if (json.status == 200) setData(json.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h5 className="mb-2">
        Monthly Notes{' '}
        <LocalStorageTooltip
          className="fs-1"
          identifier={`form_client_field_monthly_notes`}
        />
      </h5>
      <AdvanceTableWrapper
        perPage={20}
        columns={columns}
        data={data}
        sortable
        pagination
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden rounded',
            size: 'sm'
          }}
        />
      </AdvanceTableWrapper>
    </>
  );
};

export default MonthlyNotes;
