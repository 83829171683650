import React from 'react';
import { Card } from 'react-bootstrap';
import Listing from '../notes-components/Listing';

const ReportNotes = ({ report }) => {
  return (
    <Card>
      <Card.Body>
        <Listing report={report} />
      </Card.Body>
    </Card>
  );
};

export default ReportNotes;
