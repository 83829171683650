import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { AppSettings } from '../../../settings';
import CallApi from 'components/common/custom-components/CallApi';

const Create = ({ loadListing, setShow, noteId, setNoteId, clients }) => {
  const [newNote, setNewNote] = useState({
    month: '',
    year: '',
    note: '',
    client_id: ''
  });

  useEffect(() => {
    if (noteId) {
      CallApi.get(`/monthly-notes/${noteId}`, false).then(data => {
        if (data.status == 200) {
          setNewNote({
            month: data.data.month,
            year: data.data.year,
            note: data.data.note,
            client_id: data.data.client_id
          });
        }
      });
    }
  }, [noteId]);

  const { month, year, note, client_id } = newNote;

  const [validated, setValidated] = useState(false);

  const onInputChange = e => {
    setNewNote({ ...newNote, [e.target.name]: e.target.value });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    setValidated(true);

    let formData = new FormData();

    for (var key in newNote) {
      formData.append(key, newNote[key]);
    }

    if (noteId === '') {
      CallApi.post(`/monthly-notes`, formData).then(response => {
        if (response) {
          loadListing();
          setShow(false);
          setNoteId('');
        }
      });
    } else {
      CallApi.put(`/monthly-notes/${noteId}`, formData).then(response => {
        if (response) {
          loadListing();
          setShow(false);
          setNoteId('');
        }
      });
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Month</Form.Label>
          <Form.Control
            type="number"
            min="1"
            max="12"
            name="month"
            value={month}
            placeholder="Enter month"
            onChange={e => onInputChange(e)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a month.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicUrl">
          <Form.Label>Year</Form.Label>
          <Form.Control
            type="number"
            name="year"
            min="1950"
            max="2099"
            step="1"
            value={year}
            placeholder="Enter year"
            onChange={e => onInputChange(e)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a year.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicDescription">
          <Form.Label>Region</Form.Label>
          <Form.Select
            name="client_id"
            value={client_id}
            onChange={e => onInputChange(e)}
          >
            <option value="">Please select a client</option>
            {clients.map(clients => (
              <option key={clients.id} value={clients.id}>
                {clients.name}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please choose a client.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicDescription">
          <Form.Label>Note</Form.Label>
          <Form.Control
            type="text"
            value={note}
            name="note"
            onChange={e => onInputChange(e)}
            placeholder="Enter note"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicBtn">
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Button variant="muted" onClick={() => setShow(false)}>
            Close
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default Create;
