import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/digipartner-logo.png';
import { getItemFromStore } from 'helpers/utils';
import { AidaAppContext } from 'context/Context';

const CustomLogoColor = {
  color: 'rgb(94, 110, 130)',
  fontSize: '22px'
};

const Logo = ({ at, width, className, textClass, ...rest }) => {
  const { aidaAppContext } = useContext(AidaAppContext);

  return (
    <Link
      to={'/'}
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img className="me-2" src={logo} alt="Logo" width={width} />
        <span
          className={classNames('font-sans-serif', textClass)}
          style={CustomLogoColor}
        >
          AIDA 2.0
        </span>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
