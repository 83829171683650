import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CallApi from 'components/common/custom-components/CallApi';

const Footer = () => {
  const [version, setVersion] = useState('');

  useEffect(() => {
    CallApi.get(`/dashboard/application-version`, false).then(json =>
      json.status == 200 ? setVersion(json.data) : ''
    );
  }, []);

  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            Copyright © {new Date().getFullYear()}{' '}
            <Link
              to={{
                pathname: `https://digipartner.se`
              }}
              target="_blank"
            >
              Digipartner AB.
            </Link>{' '}
            All rights reserved.
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">{version}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
