import CallApi from 'components/common/custom-components/CallApi';
import useUrl from 'hooks/useUrl';
import React, { createContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ReportNotes from './ReportNotes';
import ReportPreview from './ReportPreview';
import OverviewHero from './OverviewHero';
import { useContext } from 'react';

export const ReportOverviewPageContext = createContext({
  refreshNotes: true,
  loadingNotes: true,
  currentOpenReportNote: {},
  setReportOverviewContext: contextValue => {}
});

const ReportOverview = () => {
  const [reportOverviewContext, setReportOverviewContext] = useState(
    useContext(ReportOverviewPageContext)
  );
  const url = useUrl();
  const reportId = url.get('id');
  const [report, setReport] = useState({});

  useEffect(() => {
    CallApi.get('/report/' + reportId).then(json => {
      if (json.status == 200) {
        setReport(json.data);
      }
    });
  }, [reportId]);

  return (
    <ReportOverviewPageContext.Provider
      value={{ reportOverviewContext, setReportOverviewContext }}
    >
      <Card className="mb-3">
        <Card.Body>
          <h3 className="mb-0">{report?.name_long}</h3>
          <p className="mt-2">{report?.description}</p>
        </Card.Body>
      </Card>
      <Card className="mb-3 flex">
        <Card.Body>
          <OverviewHero report={report} />
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} lg={7}>
          <ReportPreview report_iframeUrl={report?.data_studio_iframe} />
        </Col>
        <Col xs={12} lg={5}>
          <ReportNotes report={report} />
        </Col>
      </Row>
    </ReportOverviewPageContext.Provider>
  );
};

export default ReportOverview;
