import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const Create = ({ loadListing, setShow, networkId, setNetworkId }) => {
  const [newNetwork, setNewNetwork] = useState({
    name: '',
    url: '',
    description: '',
    icon_prefix: '',
    icon_name: ''
  });

  useEffect(() => {
    if (networkId) {
      CallApi.get(`/networks/${networkId}`, false).then(data => {
        if (data.status == 200) {
          setNewNetwork({
            name: data.data.name,
            url: data.data.url,
            description: data.data.description ? data.data.description : '',
            icon_prefix: data.data.icon_prefix ? data.data.icon_prefix : '',
            icon_name: data.data.icon_name ? data.data.icon_name : ''
          });
        }
      });
    }
  }, [networkId]);

  const { name, url, description, icon_prefix, icon_name } = newNetwork;

  const [validated, setValidated] = useState(false);

  const onInputChange = e => {
    setNewNetwork({ ...newNetwork, [e.target.name]: e.target.value });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    setValidated(true);

    let formData = new FormData();

    for (var key in newNetwork) {
      if (newNetwork[key]) {
        formData.append(key, newNetwork[key]);
      }
    }

    if (networkId === '') {
      CallApi.post(`/networks`, formData).then(json => {
        if (json.status == 200) {
          loadListing();
          setShow(false);
          setNetworkId('');
        }
      });
    } else {
      CallApi.put(`/networks/${networkId}`, formData).then(json => {
        if (json.status == 200) {
          loadListing();
          setShow(false);
          setNetworkId('');
        }
      });
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>
            Name <LocalStorageTooltip identifier={`form_network_field_name`} />
          </Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            placeholder="Enter name"
            onChange={e => onInputChange(e)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="description">
          <Form.Label>
            Description{' '}
            <LocalStorageTooltip
              identifier={`form_network_field_description`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={description}
            name="description"
            onChange={e => onInputChange(e)}
            placeholder="Enter description"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="icon_prefix">
          <Form.Label>
            Icon Prefix{' '}
            <LocalStorageTooltip
              identifier={`form_network_field_icon_prefix`}
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={icon_prefix}
            name="icon_prefix"
            onChange={e => onInputChange(e)}
            placeholder="Enter icon prefix"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="icon_name">
          <Form.Label>
            Icon Name
            <LocalStorageTooltip identifier={`form_network_field_icon_name`} />
          </Form.Label>
          <Form.Control
            type="text"
            value={icon_name}
            name="icon_name"
            onChange={e => onInputChange(e)}
            placeholder="Enter icon name"
          />
        </Form.Group>

        <Form.Group className="d-flex justify-content-end">
          <Button variant="muted" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default Create;
