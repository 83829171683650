import { faBolt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import { upperCase } from 'lodash';
import React, { useContext, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { keywordResearchSources } from '../competitor-analysis/CompetitorAnalysis';
import { KeywordResearchPageContext } from './KeywordResearch';

const ScriptInputs = ({ isLoading, setIsLoading }) => {
  const { keywordResearchContext, setKeywordResearchContext } = useContext(
    KeywordResearchPageContext
  );
  const [errors, setErrors] = useState({});
  const { register, handleSubmit, control } = useForm();
  const validateUrl = e => {
    const value = e.target.value;
    if (value == '') {
      setErrors([]);
    } else {
      const urls = value.split('\n');
      const pattern = /[a-zA-z0-9-]{1,65}\.[a-z]{2,10}/;

      let correct = true;

      urls.every((url, i) => {
        if (pattern.test(url)) {
          return true;
        } else {
          correct = false;
          return false;
        }
      });

      if (correct) {
        setErrors([]);
      } else {
        setErrors({
          competitor_domains: [`Make sure domain name(s) are correct`]
        });
      }
    }
  };

  const onSubmit = (data, form) => {
    setIsLoading(true);
    const getCompetitorDomains = () =>
      data.competitor_domains.split('\n').map(domain => domain.trim());
    setKeywordResearchContext({
      ...keywordResearchContext,
      currentCompetitorDomains: getCompetitorDomains(),
      myDomain: data.my_domain,
      location: data.location,
      refetchCompetitorSummary: !keywordResearchContext.refetchCompetitorSummary
    });

    CallApi.post('/research-keywords', new FormData(form.target)).then(json => {
      const newContext = {
        ...keywordResearchContext,
        keywordResults: json.data,
        currentCompetitorDomains: getCompetitorDomains(),
        myDomain: data.my_domain,
        location: data.location,
        refetchCompetitorSummary:
          !keywordResearchContext.refetchCompetitorSummary
      };
      setKeywordResearchContext(newContext);
      setIsLoading(false);
    });
  };
  return (
    <Card>
      <Card.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs="12" lg="7">
              <h5 className="mb-3">Script Inputs</h5>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={5}>
                  My Domain
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    size="sm"
                    {...register('my_domain')}
                    placeholder="Enter domain"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={5}>
                  Language
                </Form.Label>
                <Col sm={7}>
                  <Form.Select
                    size="sm"
                    {...register('language')}
                    placeholder="Select Language"
                  >
                    <option value=""></option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={5}>
                  Location
                </Form.Label>
                <Col sm={7}>
                  <Form.Select
                    size="sm"
                    {...register('location')}
                    placeholder="Select Location"
                  >
                    {keywordResearchSources.sort().map(source => (
                      <option value={source}>{upperCase(source)}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <hr />
              <div>
                <span className="badge badge-soft-primary">Note:</span>{' '}
                <span style={{ fontSize: '0.9em' }}>
                  When adding domains only add main domain like digipartner.se
                  don't add subdomains like{' '}
                  <strong>shopping.digipartner.se</strong> or{' '}
                  <strong>digipartner.se/om-oss</strong>.{' '}
                </span>
                <br />
                <span style={{ fontSize: '0.9em' }}>
                  {' '}
                  Also skip www, https://
                </span>
              </div>
            </Col>
            <Col>
              <Form.Label className="fs-1">Competitor Domains</Form.Label>
              <Form.Control
                as="textarea"
                {...register('competitor_domains', {
                  onChange: validateUrl
                })}
                rows={9}
                className="mb-3"
                isInvalid={errors.competitor_domains}
              />
              <Form.Control.Feedback type="invalid">
                {errors.competitor_domains &&
                  errors.competitor_domains.map(error => error)}
              </Form.Control.Feedback>
              <IconButton
                icon={isLoading ? faSpinner : faBolt}
                type="submit"
                spin={isLoading}
                size="sm"
                className="float-end"
              >
                Run
              </IconButton>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ScriptInputs;
