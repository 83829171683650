import React, { useContext } from 'react';
import NavbarDropdown from './NavbarDropdown';
import { appRoutes, toolRoutes } from 'routes/routes';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
import NavbarDropdownApp from './NavbarDropdownApp';
import NavbarDropdownPages from './NavbarDropdownPages';
import NavbarDropdownModules from './NavbarDropdownModules';
import AppContext, { AidaAppContext } from 'context/Context';

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);
  const { aidaAppContext } = useContext(AidaAppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      {aidaAppContext.user_permissions?.access_top_nav_links && (
        <>
          <NavbarDropdown label={appRoutes.label} type={appRoutes.dropdownType}>
            {appRoutes.children.map(route => (
              <Dropdown.Item
                key={route.name}
                as={Link}
                className={route.active ? 'link-600' : 'text-500'}
                to={route.to}
                onClick={handleDropdownItemClick}
              >
                {route.name}
              </Dropdown.Item>
            ))}
          </NavbarDropdown>
          <NavbarDropdown
            label={toolRoutes.label}
            type={toolRoutes.dropdownType}
          >
            {toolRoutes.children.map(route => (
              <Dropdown.Item
                key={route.name}
                as={Link}
                className={route.active ? 'link-600' : 'text-500'}
                to={route.to}
                onClick={handleDropdownItemClick}
              >
                {route.name}
              </Dropdown.Item>
            ))}
          </NavbarDropdown>
        </>
      )}
    </>
  );
};

export default NavbarTopDropDownMenus;
