import React, { useEffect, useState } from 'react';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper, {
  SelectBoxFilterMenu
} from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  ToggleButton,
  Tooltip
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { getYYYYMMDDFormatDate, roundToTwoDecimals } from 'helpers/utils';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import ExportXLSX from '../budget-overviews/components/ExportXLSX';
import useClientSelectBox from '../custom-form-hooks/useClientSelectBox';
import FalconCardHeader from 'components/common/FalconCardHeader';

const Listing = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [toggleListingButtonValue, setToggleListingButtonValue] =
    useState('missing');
  const [action, setAction] = useState('');
  const [showAskModal, setShowAskModal] = useState(false);
  const [bulkSelectedIds, setBulkSelectedIds] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const [client, setClient] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    loadListing(
      listingToggleButtons.find(button =>
        button.value === toggleListingButtonValue ? button.url : null
      )
    );
  }, [toggleListingButtonValue, endDate, client, user]);

  const loadListing = listing => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      setApiCallInProcess(true);
      let filters = `?${
        toggleListingButtonValue ? `type=${toggleListingButtonValue}` : ''
      }${startDate ? `&start_date=${getYYYYMMDDFormatDate(startDate)}` : ''}${
        endDate ? `&end_date=${getYYYYMMDDFormatDate(endDate)}` : ''
      }${client ? '&client=' + client : ''}${user ? '&user=' + user : ''}`;

      CallApi.get(listing.url + filters).then(json => {
        setApiCallInProcess(false);
        if (json.status == 200) {
          setData(json.data);
        }
      });
    }
  };

  const columns = [
    {
      accessor: 'client_name',
      Header: 'Client',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.client_favicon || false}
        />
      )
    },
    {
      accessor: 'project_title',
      Header: 'Project',
      Filter: SelectBoxFilterMenu
    },
    {
      accessor: 'type',
      Header: 'Type',
      Filter: SelectBoxFilterMenu,
      cellProps: { className: 'text-center' },
      headerProps: { className: 'text-center' },
      Cell: ({ cell }) => {
        if (cell.value == 'Uppstart') {
          return (
            <Badge pill bg="warning">
              {cell.value}
            </Badge>
          );
        }

        if (cell.value == 'Avtalade timmar') {
          return (
            <span
              className="rounded-pill badge text-white"
              style={{
                backgroundColor: '#57A5B8'
              }}
            >
              {cell.value}
            </span>
          );
        }
        return cell.value;
      }
    },
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'user',
      Header: 'User',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <a
          href={`https://digipartner.avaza.com/timesheet/day/${cell.row.original.entry_date}?userID=${cell.row.original.avaza_user_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'hours_eu_format',
      Header: 'Hours',
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) =>
        cell.row.original.reminder ? (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{cell.row.original.reminder}</Tooltip>}
            >
              <span className="ms-1">
                <FontAwesomeIcon icon="info-circle" />
              </span>
            </OverlayTrigger>{' '}
            <span title={`${cell.row.original?.id}`}>
              {cell.row.original.hours}
            </span>
          </>
        ) : (
          cell.row.original.hours
        ),
      footerProps: {
        className: 'text-end white-space-nowrap'
      },
      Footer: table =>
        roundToTwoDecimals(
          React.useMemo(
            () => table.page.reduce((sum, row) => +row.original.hours + sum, 0),
            [table.page]
          )
        )
    },
    {
      accessor: 'task_title',
      Header: 'Task',
      Filter: SelectBoxFilterMenu
    },
    {
      accessor: 'notes',
      Header: 'Note'
    },
    {
      accessor: 'invoice_task_id',
      Header: 'Invoice Task',
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'invoice_id',
      Header: 'Invoice ID',
      cellProps: { className: 'text-end' },
      headerProps: { className: 'text-end' }
    },
    {
      accessor: 'avaza_timesheet_reminder.updated_by',
      Header: 'Updated by',
      Cell: ({ cell }) => {
        return (
          <NameWithIcon name={cell.value?.name} icon={cell.value?.avatar_url} />
        );
      }
    },
    {
      accessor: 'avaza_timesheet_reminder.dont_send_invoice',
      Header: 'Not billable?',
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <FontAwesomeIcon
          className={cell.value ? 'text-success' : 'text-danger'}
          icon={cell.value ? 'check' : 'times'}
          title={`${cell.row.original?.avaza_timesheet_reminder?.id}`}
        />
      )
    },
    {
      accessor: 'avaza_timesheet_reminder.dont_send_invoice_reason',
      Header: 'Not billable reason'
    }
  ];

  const LISTING_TYPE = {
    ALL: 'all',
    MISSING: 'missing'
  };

  const ACTION_TYPE = {
    ADD_TASK_ID: 'add_task_id',
    ADD_REMINDER: 'add_reminder',
    MARK_AS_NOT_BILLABLE: 'mark_as_dont_send_invoice'
  };

  const listingToggleButtons = [
    {
      value: LISTING_TYPE.MISSING,
      label: 'Missing',
      url: `/avaza/timerows`,
      variant: 'outline-primary'
    },
    {
      value: LISTING_TYPE.ALL,
      label: 'All',
      url: `/avaza/timerows`,
      variant: 'outline-primary'
    }
  ];

  const updateTimerow = e => {
    e.preventDefault();

    // make form data
    let formdata = new FormData(e.currentTarget);
    formdata.append('action', action);
    formdata.append('timesheet_ids', bulkSelectedIds);

    // send post request to update timesheets
    CallApi.post('/avaza/update-timerows', formdata).then(json => {
      if (json.status == 200) {
        setShowAskModal(false);
        loadListing(
          listingToggleButtons.find(button =>
            button.value === toggleListingButtonValue ? button.url : null
          )
        );
      }
    });
  };

  const clearAndCloseModal = () => {
    setShowAskModal(false);
  };

  const BulkAction = ({ selectedRowIds, page, className }) => {
    let selectedIds = [];

    for (const key in selectedRowIds) {
      if (Object.hasOwnProperty.call(selectedRowIds, key)) {
        page.map(row => {
          if (row.id == key) selectedIds.push(row.original.id);
        });
      }
    }

    return Object.keys(selectedRowIds).length > 0 ? (
      <div className={'d-flex ' + className}>
        <h5 className="fs-0 mb-0 mx-auto text-nowrap pe-2 pt-1">
          {Object.keys(selectedRowIds).length > 0
            ? 'You have selected ' +
              Object.keys(selectedRowIds).length +
              ' rows'
            : ''}
        </h5>
        <Form.Select
          size="sm"
          aria-label="Bulk actions"
          onChange={e => setAction(e.currentTarget.value)}
          value={action}
        >
          <option value="">Bulk Actions</option>
          <option value={ACTION_TYPE.ADD_TASK_ID}>Add Invoice Task ID</option>
          <option value={ACTION_TYPE.ADD_REMINDER}>Add Reminder</option>
          <option value={ACTION_TYPE.MARK_AS_NOT_BILLABLE}>
            Mark as "not billable"
          </option>
        </Form.Select>
        <Button
          type="button"
          variant="falcon-default"
          size="sm"
          className="ms-2"
          onClick={() => {
            setShowAskModal(true);
            setBulkSelectedIds(selectedIds);
          }}
        >
          Apply
        </Button>
      </div>
    ) : null;
  };

  const ClientFilter = useClientSelectBox();

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <AdvanceTableWrapper
                columns={columns}
                data={data}
                sortable
                pagination
                selection
                renderRowSubComponent={false}
                useTableFooter={true}
              >
                <Row className="align-items-end g-2 row mb-3">
                  <Col xs={5} lg={'auto'}>
                    <ButtonGroup>
                      {listingToggleButtons.map((button, index) => (
                        <ToggleButton
                          key={index}
                          id={`radio-${index}`}
                          type="radio"
                          className="mb-0"
                          variant={button.variant}
                          name="toggleBudgetOverviewListing"
                          value={button.value}
                          checked={toggleListingButtonValue === button.value}
                          onChange={e => {
                            return setToggleListingButtonValue(
                              e.currentTarget.value
                            );
                          }}
                          size="sm"
                        >
                          {button.label}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>
                  <Col xs={7} lg={'auto'}>
                    <AdvanceTableSearchBox table />
                  </Col>
                  <Col xs={0} lg={true} />
                  <Col xs={2} lg={'auto'} className="text-center">
                    <FontAwesomeIcon icon="calendar-day" className="mb-1" />
                  </Col>
                  <Col xs={10} lg={'auto'}>
                    <DatePicker
                      title="Date"
                      dateFormat="yyyy-MM-dd"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={update => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                      className="form-control pb-0 pt-1"
                      calendarStartDay={1}
                    />
                  </Col>
                  <Col xs={8} lg={2}>
                    <ClientFilter
                      placeholder="Filter client"
                      value={client}
                      onChange={client =>
                        setClient(client ? client.id : client)
                      }
                      size="sm"
                      isClearable
                      isSearchable
                    />
                  </Col>
                  <Col xs={4} lg={'auto'} className="text-end">
                    <ExportXLSX
                      className="ms-0"
                      table
                      sheetHeaderRow={[
                        'Client',
                        'Project',
                        'Type',
                        'Date',
                        'User',
                        'Hours',
                        'Task',
                        'Note',
                        'Invoice Task',
                        'Invoice ID'
                      ]}
                      filename={`${getYYYYMMDDFormatDate(
                        new Date()
                      )} - Avaza Timerows`}
                      ignoreableColumnIndexes={[0]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col />
                  <Col>
                    <BulkAction className="mb-3" table />
                  </Col>
                </Row>
                <AdvanceTable
                  table
                  highlightSelectedRow
                  loading={loading}
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    striped: true,
                    id: 'avaza-timerows-table',
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                  apiCallInProcess={apiCallInProcess}
                  tableFooterClassName="table-secondary"
                  SelectedRowsTableFooterRow={selectedFlatRows => (
                    <tr className="table-info">
                      <td>Marked</td>
                      <td colSpan={5}></td>
                      <td className="text-end">
                        {roundToTwoDecimals(
                          selectedFlatRows.reduce(
                            (sum, row) => +row.original.hours + sum,
                            0
                          )
                        )}
                      </td>
                      <td colSpan={7}></td>
                    </tr>
                  )}
                />
                <AdvanceTableFooter
                  table
                  className="mt-3 mx-3"
                  rowCount={data.length}
                  rowInfo
                  navButtons
                />
              </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showAskModal} onHide={clearAndCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {action == '' && 'Bulk Action'}
            {action == ACTION_TYPE.ADD_TASK_ID && 'Add Task ID'}
            {action == ACTION_TYPE.ADD_REMINDER && 'Add a Reminder'}
            {action == ACTION_TYPE.MARK_AS_NOT_BILLABLE && (
              <>
                Mark as <b>not billable</b>
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="add-budget-overview"
            encType="multipart/form-data"
            onSubmit={updateTimerow}
          >
            {action == '' && <h5>Please choose an action</h5>}
            {action == ACTION_TYPE.ADD_TASK_ID && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Task ID
                </Form.Label>
                <Col sm="9">
                  <Form.Control name="invoice_task_id" type="number" />
                </Col>
              </Form.Group>
            )}
            {action == ACTION_TYPE.ADD_REMINDER && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Reminder
                </Form.Label>
                <Col sm="9">
                  <Form.Control name="reminder" type="text" />
                </Col>
              </Form.Group>
            )}
            {action == ACTION_TYPE.MARK_AS_NOT_BILLABLE && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                  Reason
                </Form.Label>
                <Col sm="9">
                  <Form.Control name="dont_send_invoice_reason" type="text" />
                </Col>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="falcon-light"
            size="sm"
            icon="times-circle"
            onClick={clearAndCloseModal}
          >
            Close
          </IconButton>
          <IconButton
            size="sm"
            icon="save"
            form="add-budget-overview"
            type="submit"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function AvazaTimerows() {
  return (
    <>
      <PageInfo slug="avaza_timerows" />
      <Listing />
    </>
  );
}

export default AvazaTimerows;
