import React, { useContext, useEffect } from 'react';
import Select from 'react-select';
import CallApi from 'components/common/custom-components/CallApi';
import { AidaAppContext } from 'context/Context';
import ReactSelectBoxImageLabel from 'components/common/custom-components/ReactSelectBoxImageLabel';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import useUrl from 'hooks/useUrl';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * This component is made to manage client scope in whole application
 *
 * for this, current url will be the 1st source of truth (if `?client_id={number}` found)
 *  else last selected client will be picked up from local storage will be 2nd source of truth.
 *
 * to manage client scope in app, this will update the url of pages, like:
 * if some page uses `?client_id={number}` it needs to update that client_id parameter.
 *
 * componenet self's client listing can be updated when new client get adds to the app.
 *
 * To Do's:
 * append client_id in sidebar links where required.
 *
 * Impact on:
 * 1. When adding new client, listing will be refetched.
 * 2. on change of clients, some page data will be changed.
 *
 * @returns JSX
 */
const NavbarClientSelector = ({ className }) => {
  const { aidaAppContext, setAidaAppContext } = useContext(AidaAppContext);
  const url = useUrl();
  const history = useHistory();
  let urlClientId = url.get('client_id');
  const { pathname, search } = useLocation();

  // fetch active clients
  const { data, refetch, isFetched } = useQuery(
    ['active-clients'],
    () => CallApi.get(`/clients/active`, false),
    {
      placeholderData: { data: [] }
    }
  );

  // refreshing list when user adds or deletes client.
  useEffect(() => {
    refetch();
  }, [aidaAppContext.refreshClientSelectBox]);

  /**
   * This function sets new client to localStorage & in whole app's context.
   *
   * @param {number} clientId
   */
  const setClientAppWide = clientId => {
    let client = data.data.find(client => client.id == clientId);

    // don't set until user_permissions are not fetched.
    if (client && Object.keys(aidaAppContext.user_permissions).length > 0) {
      setItemToStore('currentClientId', client.id);
      setAidaAppContext({
        ...aidaAppContext,
        client: client
      });
    }

    // changing url upon changing client from sidebar
    if (url.has('client_id')) {
      url.set('client_id', `${clientId}`);

      if (search !== `?${url.toString()}`) {
        history.push({
          pathname: pathname,
          search: `?${url.toString()}`
        });
      }
    }
  };

  useEffect(() => {
    // when clients are fetched, do setting or picking up client into/from local-storage.
    setClientAppWide(
      urlClientId ||
        getItemFromStore('currentClientId', false) ||
        data.data[0]?.id
    );
  }, [
    urlClientId,
    isFetched,
    aidaAppContext.user_permissions // Because user permission api & client setting on first load are done during concurrent api requests, so client should be set after setting user_permissions for the app
  ]);

  return (
    <div className={`${className} overflow-initial`}>
      <Select
        options={data.data}
        onChange={client => setClientAppWide(client.id)}
        formatOptionLabel={client => (
          <ReactSelectBoxImageLabel src={client.favicon} title={client.name} />
        )}
        getOptionValue={client => client.id}
        getOptionLabel={client => client.name}
        value={aidaAppContext.client}
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default NavbarClientSelector;
