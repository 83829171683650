import AidaDatePicker from 'components/common/custom-components/AidaDatePicker';
import ErrorBoundary from 'components/common/custom-components/ErrorBoundry';
import IconButton from 'components/common/IconButton';
import { AidaAppContext } from 'context/Context';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import useClientScopedBudgetOverviews from './components/useClientScopedBudgetOverviews';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from 'routes/routes';
import FirstRowCharts from './components/FirstRowCharts';
import Pivot from './components/Pivot';
import SecondRowCharts from './components/SecondRowCharts';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths
} from 'date-fns';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import { eachMonthOfInterval } from 'date-fns/esm';
import { capitalize, isObject } from 'lodash';
import { isArray } from 'lodash';

const ClientOverview = () => {
  const { aidaAppContext } = useContext(AidaAppContext);
  const [chartLabels, setChartLabels] = useState([]);
  const checkboxesColumnColors = ['primary', 'success', 'danger'];
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 5)),
    endOfMonth(new Date())
  ]);
  const TableRef = useRef();
  const [startDate, endDate] = dateRange;
  const { RenderClientScopedBudgetOverviews, data } =
    useClientScopedBudgetOverviews(
      startDate,
      endDate,
      checkboxesColumnColors,
      TableRef
    );
  const [interval, setInterval] = useState('daily');
  const getChartLabels = () => {
    let labels = [];

    // primary column for  dates.
    if (
      data.some(
        budget =>
          budget.primary_column || budget.success_column || budget.danger_column
      )
    ) {
      let smallestDate;
      let biggestDate;

      data.map(budget => {
        if (
          budget.primary_column ||
          budget.success_column ||
          budget.danger_column
        ) {
          if (
            !smallestDate ||
            isBefore(new Date(budget.start_date), new Date(smallestDate))
          )
            smallestDate = new Date(budget.start_date);

          if (
            !biggestDate ||
            isAfter(new Date(budget.end_date), new Date(biggestDate))
          )
            biggestDate = new Date(budget.end_date);
        }
      });

      switch (interval) {
        case 'weekly':
          labels = eachWeekOfInterval(
            {
              start: smallestDate,
              end: biggestDate
            },
            { weekStartsOn: 1 }
          );
          break;

        case 'monthly':
          labels = eachMonthOfInterval({
            start: smallestDate,
            end: biggestDate
          });
          break;

        default:
          labels = eachDayOfInterval({
            start: smallestDate,
            end: biggestDate
          });
          break;
      }
    }

    setChartLabels(labels.map(date => getYYYYMMDDFormatDate(date)));
  };

  const getIntervalBasedResult = (
    uniqueData,
    interval,
    calculateChartData,
    columnName
  ) => {
    //checking if blue/red/green is checked or not.
    if (!data.some(row => row[columnName])) {
      return [];
    }

    const getResult = () => {
      let lastDate;

      // generate data according to labels
      return chartLabels.map((labelDate, i) => {
        // it will be new week's start date.
        lastDate = chartLabels[i + 1];

        return Object.keys(uniqueData).reduce((prevSum, nextCurrentDate) => {
          const labelDateIsInLastInterval =
            isAfter(new Date(nextCurrentDate), new Date(labelDate)) &&
            !lastDate;

          const labelDateIsBetweenCurrentInterval =
            isAfter(new Date(nextCurrentDate), new Date(labelDate)) &&
            isBefore(new Date(nextCurrentDate), new Date(lastDate));

          return labelDateIsBetweenCurrentInterval || labelDateIsInLastInterval
            ? prevSum + calculateChartData(nextCurrentDate)
            : prevSum;
        }, 0);
      });
    };

    let result = [];
    switch (interval) {
      case 'weekly':
        result = getResult();
        break;

      case 'monthly':
        result = getResult();
        break;

      default:
        result = chartLabels.map((currentDate, i) =>
          calculateChartData(currentDate)
        );
        break;
    }

    return result;
    // NOTE: if they have asked for gradually summation of values.
    // return result.map((value, i) =>
    //   // adding values by iterating to show gradually upgoing graph
    //   result.slice(0, i).reduce((prev, next) => prev + +next, value)
    // );
  };

  const getFormattedChartLabels = interval => {
    let labels = [];

    switch (interval) {
      case 'weekly':
        labels = chartLabels.map(date => 'Week ' + format(new Date(date), 'w'));
        break;

      case 'monthly':
        labels = chartLabels.map(date => format(new Date(date), 'MMMM'));
        break;

      default:
        labels = chartLabels;
        break;
    }

    return labels;
  };

  useEffect(() => {
    getChartLabels();
    TableRef.current?.resetGlobalFilterToLastQuery();
  }, [data, interval]);

  return (
    <>
      <Row className="my-2">
        <Col className="col-auto">
          <Link
            to={appRoutes.children.find(route => route.name == 'Clients').to}
          >
            <IconButton
              icon="arrow-left"
              variant="falcon-link"
              size="sm"
              className="mb-3"
            >
              Back
            </IconButton>
          </Link>
        </Col>
        <Col className="col-auto ps-0">
          <img src={aidaAppContext.client?.favicon} width="30" />
        </Col>
        <Col className="col-auto ps-0">
          <h4>{aidaAppContext.client?.name || ''}</h4>
        </Col>
        <Col />
        <Col className="col-auto pe-0">
          <Form.Select
            className=""
            value={interval}
            onChange={e => setInterval(e.target.value)}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Form.Select>
        </Col>
        <Col className="col-auto">
          <AidaDatePicker
            date={startDate}
            endDate={endDate}
            selectsRange
            setDateRange={setDateRange}
          />
        </Col>
      </Row>
      {RenderClientScopedBudgetOverviews}
      <FirstRowCharts
        interval={interval}
        checkboxesColumnColors={checkboxesColumnColors}
        budgetOverviews={data}
        getFormattedChartLabels={getFormattedChartLabels}
        getIntervalBasedResult={getIntervalBasedResult}
      />
      <SecondRowCharts
        interval={interval}
        checkboxesColumnColors={checkboxesColumnColors}
        budgetOverviews={data}
        getFormattedChartLabels={getFormattedChartLabels}
        getIntervalBasedResult={getIntervalBasedResult}
      />

      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Body>
              <ErrorBoundary>
                <Pivot
                  pivotData={[...data]
                    .filter(
                      budgetOverview =>
                        budgetOverview.primary_column ||
                        budgetOverview.success_column ||
                        budgetOverview.danger_column
                    )
                    .map(budgetOverview =>
                      Object.keys(budgetOverview)
                        .filter(field => !isArray(budgetOverview[field]))
                        .reduce((p, n) => {
                          const key = capitalize(n.replace(/_/g, ' '));
                          return {
                            ...p,
                            [key]: isObject(budgetOverview[n])
                              ? budgetOverview[n]?.name ||
                                '❗ Error accessing value.'
                              : budgetOverview[n]
                          };
                        }, {})
                    )}
                />
              </ErrorBoundary>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClientOverview;

export const getColorName = color => {
  let colorName = 'Blue';

  switch (color) {
    case 'danger':
      colorName = 'Red';
      break;

    case 'success':
      colorName = 'Green';
      break;

    default:
      colorName = 'Blue';
      break;
  }

  return colorName;
};
