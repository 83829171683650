import PageInfo from 'components/common/custom-components/PageInfo';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import useUrl from 'hooks/useUrl';
import { upperCase } from 'lodash';
import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CompetitorsTable from './components/CompetitorsTable';
import DomainHistoryOverviewTable from './components/DomainHistoryOverviewTable';

export const keywordResearchSources = [
  'us',
  'uk',
  'se',
  'fi',
  'no',
  'dk',
  'fr',
  'de',
  'nl',
  'ca',
  'es'
];

const CompetitorAnalysis = () => {
  const url = useUrl();
  const [refetch, setRefetch] = useState(true);
  const [source, setSource] = useState('');
  const [domain, setDomain] = useState(url.get('domain') || '');
  const handleSubmit = e => {
    e.preventDefault();
    setRefetch(!refetch);
  };

  return (
    <>
      <PageInfo slug="competitor_analysis" />
      <Row className="g-3">
        <Col xs={12}>
          <Card>
            <Card.Body className="p-2">
              <Form onSubmit={handleSubmit}>
                <Flex alignItems={'center'}>
                  <Form.Group className="col me-2">
                    <Form.Control
                      size="sm"
                      placeholder="Enter Competitor domain"
                      onChange={e => setDomain(e.target.value)}
                      value={domain}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col me-2">
                    <Form.Select
                      size="sm"
                      placeholder="Select Region"
                      onChange={e => setSource(e.target.value)}
                      value={source}
                      required
                    >
                      <option value="">Select source </option>
                      {keywordResearchSources.sort().map(source => (
                        <option value={source}>{upperCase(source)}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="col-auto">
                    <IconButton
                      size="sm"
                      variant="primary"
                      icon={'play'}
                      type="submit"
                    >
                      Run Now
                    </IconButton>
                  </Form.Group>
                </Flex>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <CompetitorsTable refetch={refetch} source={source} domain={domain} />
        </Col>
        <Col xs={12} lg={6}>
          <DomainHistoryOverviewTable
            refetch={refetch}
            source={source}
            domain={domain}
          />
        </Col>
      </Row>
    </>
  );
};

export default CompetitorAnalysis;
