import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { AppSettings } from '../../../settings';
import CallApi from 'components/common/custom-components/CallApi';

const Create = ({ loadListing, setShow, metricId, setMetricsId }) => {
  const [newMetrics, setNewMetrics] = useState({
    name: '',
    url: '',
    description: ''
  });

  useEffect(() => {
    if (metricId) {
      CallApi.get(`/metrics/${metricId}`, false).then(data => {
        if (data.status == 200) {
          setNewMetrics({
            name: data.data.name,
            url: data.data.url,
            description: data.data.description ? data.data.description : ''
          });
        }
      });
    }
  }, [metricId]);

  const { name, url, description } = newMetrics;

  const [validated, setValidated] = useState(false);

  const onInputChange = e => {
    setNewMetrics({ ...newMetrics, [e.target.name]: e.target.value });
  };

  const manageResponse = json => {
    if (json.status == 200) {
      loadListing();
      setShow(false);
      setMetricsId('');
    }
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    setValidated(true);

    let formData = new FormData();

    for (var key in newMetrics) {
      formData.append(key, newMetrics[key]);
    }

    if (metricId === '') {
      CallApi.post(`/metrics`, formData).then(json => manageResponse(json));
    } else {
      CallApi.put(`/metrics/${metricId}`, formData).then(json =>
        manageResponse(json)
      );
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            placeholder="Enter name"
            onChange={e => onInputChange(e)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please choose a name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            value={description}
            name="description"
            onChange={e => onInputChange(e)}
            placeholder="Enter description"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicBtn">
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Button variant="muted" onClick={() => setShow(false)}>
            Close
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default Create;
