import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getItemFromStore } from 'helpers/utils';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Flip, toast } from 'react-toastify';

const copyToClipBoard = text => {
  /* Copy the text inside the text field */
  navigator.clipboard.writeText(text);
  toast.info('Identifier copied to clipboard', {
    position: 'top-center',
    bodyClassName: 'fw-bold',
    autoClose: 1250,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: true,
    transition: Flip
  });
};

const LocalStorageTooltip = ({ className = '', identifier }) => {
  const tooltips = getItemFromStore('tooltips');

  const tooltip = tooltips.find(item => item.key == identifier);

  if (tooltip?.hidden) return <></>;

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {tooltip?.value ||
              `Add "${identifier}" in the tooltips table to replace this message. Click on the 'i' icon to copy the "key"`}
          </Tooltip>
        }
      >
        <span
          className={`${className} cursor-pointer`}
          onClick={() => copyToClipBoard(identifier)}
        >
          <FontAwesomeIcon icon="info-circle" />
        </span>
      </OverlayTrigger>
    </>
  );
};

export default LocalStorageTooltip;
