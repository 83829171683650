import React, { useEffect } from 'react';
import TableRenderers from 'react-pivottable/TableRenderers';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { useState } from 'react';
import Plotly from 'plotly.js-basic-dist';
import 'react-pivottable/pivottable.css';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import createPlotlyComponent from 'react-plotly.js/factory';
import { isArray } from 'lodash';
import { isObject } from 'lodash';
import { omit } from 'lodash';

const Plot = createPlotlyComponent(Plotly);

const PlotlyRenderers = createPlotlyRenderers(Plot);

const Pivot = ({ pivotData }) => {
  const [pivotTable, setPivotTable] = useState({});

  return (
    <PivotTableUI
      data={pivotData}
      onChange={s => {
        delete s.data;
        setPivotTable(s);
      }}
      {...pivotTable}
      renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
    />
  );
};

export default Pivot;
