import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import { DevTool } from '@hookform/devtools';
import useRegionSelectBox from 'components/aida/custom-form-hooks/useRegionSelectBox';
import useUserSelectBox from 'components/aida/custom-form-hooks/useUserSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const UpsertForm = ({ closeModal, reportId }) => {
  const [errors, setErrors] = useState({});
  const { handleSubmit, register, control, reset } = useForm();

  const manageFormResponse = json => {
    if (json.status == 422) {
      setErrors(json.errors);
      toast.error(json.message);
    }

    if (json.status == 200) {
      closeModal();
    }
  };

  const onSubmit = (data, form) => {
    if (reportId) {
      CallApi.put(
        '/report/' + reportId,
        new FormData(form.target),
        true,
        false
      ).then(json => manageFormResponse(json));
    } else {
      CallApi.post('/report', new FormData(form.target), true, false).then(
        json => manageFormResponse(json)
      );
    }
  };

  const editReport = report_id => {
    CallApi.get('/report/' + report_id).then(json => reset(json.data));
  };

  useEffect(() => {
    if (reportId) editReport(reportId);
  }, []);

  const ClientSelectBox = useClientSelectBox();
  const ProjectLeadSelectBox = useUserSelectBox();

  return (
    <>
      <Form noValidate id="report-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>
            Status
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_status'}
            />
          </Form.Label>
          <Form.Select {...register('status')} isInvalid={errors.status}>
            <option value="">Please select status</option>
            <option value={1}>Active</option>
            <option value={0}>In-Active</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.status && errors.status.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Show For Customer
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_show_for_customer'}
            />
          </Form.Label>
          <Form.Select
            {...register('show_for_customer')}
            isInvalid={errors.show_for_customer}
          >
            <option value="">Please select status</option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </Form.Select>{' '}
          <Form.Control.Feedback type="invalid">
            {errors.show_for_customer &&
              errors.show_for_customer.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Client
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_client'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="client_id"
            render={({ field }) => (
              <ClientSelectBox
                isSearchable
                required
                className={errors.client_id && 'is-invalid'}
                onChange={client => field.onChange(client.id)}
                value={field.value}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.client_id && errors.client_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Name Short
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_name_short'}
            />
          </Form.Label>
          <Form.Control
            {...register('name_short')}
            isInvalid={errors.name_short}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name_short && errors.name_short.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Name Long
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_name_long'}
            />
          </Form.Label>
          <Form.Control
            {...register('name_long')}
            isInvalid={errors.name_long}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name_long && errors.name_long.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Project Lead
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_project_lead_id'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="project_lead_id"
            render={({ field }) => (
              <ProjectLeadSelectBox
                isSearchable
                placeholder="Please select a project lead"
                className={errors.project_lead_id && 'is-invalid'}
                name="project_lead_id"
                onChange={user => field.onChange(user.id)}
                value={field.value}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.project_lead_id &&
              errors.project_lead_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Analyst
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_analyst_id'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="analyst_id"
            render={({ field }) => (
              <ProjectLeadSelectBox
                isSearchable
                placeholder="Please select an analyst"
                className={errors.analyst_id && 'is-invalid'}
                name="analyst_id"
                onChange={user => field.onChange(user.id)}
                value={field.value}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.analyst_id && errors.analyst_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Data Studio ID
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_data_studio_id'}
            />
          </Form.Label>
          <Form.Control
            {...register('data_studio_id', { required: false })}
            isInvalid={errors.data_studio_id}
          />
          <Form.Control.Feedback type="invalid">
            {errors.data_studio_id && errors.data_studio_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Description
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_description'}
            />
          </Form.Label>
          <Form.Control
            as={'textarea'}
            {...register('description')}
            isInvalid={errors.description}
          />
          <Form.Control.Feedback type="invalid">
            {errors.description && errors.description.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Customer E-mail
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_customer_email'}
            />
          </Form.Label>
          <Form.Control
            {...register('customer_email')}
            isInvalid={errors.customer_email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.customer_email && errors.customer_email.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            E-mail Suffix
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_email_suffix'}
            />
          </Form.Label>
          <Form.Control
            {...register('email_suffix')}
            isInvalid={errors.email_suffix}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email_suffix && errors.email_suffix.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            E-mail Prefix
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_report_field_email_prefix'}
            />
          </Form.Label>
          <Form.Control
            {...register('email_prefix')}
            isInvalid={errors.email_prefix}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email_prefix && errors.email_prefix.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

Form.propTypes = {};

export default UpsertForm;
