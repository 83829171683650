import Flex from 'components/common/Flex';
import { isAfter, isBefore } from 'date-fns';
import { getColor } from 'helpers/utils';
import { chunk, isUndefined } from 'lodash';
import { groupBy } from 'lodash';
import React, { useState } from 'react';
import { getColorName } from '../ClientOverview';
import ChartWidget from './ChartWidget';

const SecondRowCharts = ({
  interval,
  getFormattedChartLabels,
  checkboxesColumnColors,
  budgetOverviews,
  getIntervalBasedResult
}) => {
  const getUniquedData = columnName => {
    let data = [];
    budgetOverviews.map(budgetOverview => {
      // to use only data if primary🔵, success🟢 or danger🔴 column is checked
      if (budgetOverview[columnName]) {
        data = [
          ...data,
          ...budgetOverview.bqdata_data_ads_campaigns.map(dailyBudget => ({
            date: dailyBudget.data_date,
            clicks: dailyBudget.clicks,
            spend: dailyBudget.spend,
            conversions: dailyBudget.conversions,
            conversion_values: dailyBudget.conversion_values,
            impressions: dailyBudget.impressions
          }))
        ];
      }
    });

    data = [...new Set(data)].sort(
      (prevDate, nextDate) => new Date(prevDate.date) - new Date(nextDate.date)
    );

    return groupBy(data, values => values.date);
  };

  const getChartDatasets = callback =>
    checkboxesColumnColors.map(color => ({
      type: 'line',
      label: getColorName(color),
      borderWidth: 1,
      backgroundColor: getColor(`soft-${color}`),
      borderColor: getColor(color),
      pointRadius: 2,
      data: callback(`${color}_column`)
    }));

  return (
    <Flex className="d-grid gap-3 mt-3 g-3 flex-column flex-lg-row">
      <ChartWidget
        className="w-20"
        chartLabels={getFormattedChartLabels(interval)}
        chartDataCallback={() =>
          getChartDatasets(columnName => {
            const unique = getUniquedData(columnName);

            const calculateCTR = key => {
              // NOTE: returning 0 incase of not having that day's data it results in undefined or NaN
              const clicks =
                unique[key]?.reduce((prev, next) => prev + +next.clicks, 0) ||
                0;
              const impressions =
                unique[key]?.reduce(
                  (prev, next) => prev + +next.impressions,
                  0
                ) || 0;

              return impressions ? (clicks / impressions) * 100 : 0;
            };

            return getIntervalBasedResult(
              unique,
              interval,
              calculateCTR,
              columnName
            );
          })
        }
        cardTitle={`CTR`}
      />
      <ChartWidget
        className="w-20"
        chartLabels={getFormattedChartLabels(interval)}
        chartDataCallback={() =>
          getChartDatasets(columnName => {
            const unique = getUniquedData(columnName);
            const calculateCPC = key => {
              const spend =
                unique[key]?.reduce((prev, next) => prev + +next.spend, 0) || 0;

              const clicks =
                unique[key]?.reduce((prev, next) => prev + +next.clicks, 0) ||
                0;

              return clicks ? spend / clicks : 0;
            };
            return getIntervalBasedResult(
              unique,
              interval,
              calculateCPC,
              columnName
            );
          })
        }
        cardTitle={`CPC`}
      />
      <ChartWidget
        className="w-20"
        chartLabels={getFormattedChartLabels(interval)}
        chartDataCallback={() =>
          getChartDatasets(columnName => {
            const unique = getUniquedData(columnName);
            const calculateCPM = key => {
              const spend =
                unique[key]?.reduce((prev, next) => prev + next.spend, 0) || 0;
              const impressions =
                unique[key]?.reduce(
                  (prev, next) => prev + next.impressions,
                  0
                ) || 0;
              return impressions ? (spend / impressions) * 1000 : 0;
            };
            return getIntervalBasedResult(
              unique,
              interval,
              calculateCPM,
              columnName
            );
          })
        }
        cardTitle={`CPM`}
      />
      <ChartWidget
        className="w-20"
        chartLabels={getFormattedChartLabels(interval)}
        chartDataCallback={() =>
          getChartDatasets(columnName => {
            const unique = getUniquedData(columnName);
            const calculateCostConversion = key => {
              const spend =
                unique[key]?.reduce((prev, next) => prev + next.spend, 0) || 0;
              const conversions =
                unique[key]?.reduce(
                  (prev, next) => prev + next.conversions,
                  0
                ) || 0;
              return conversions ? spend / conversions : 0;
            };
            return getIntervalBasedResult(
              unique,
              interval,
              calculateCostConversion,
              columnName
            );
          })
        }
        cardTitle={`Cost/Conversion`}
      />
      <ChartWidget
        className="w-20"
        chartLabels={getFormattedChartLabels(interval)}
        chartDataCallback={() =>
          getChartDatasets(columnName => {
            const unique = getUniquedData(columnName);
            const calculateROI = key => {
              const conversion_values =
                unique[key]?.reduce(
                  (prev, next) => prev + +next.conversion_values,
                  0
                ) || 0;
              const spend =
                unique[key]?.reduce((prev, next) => prev + +next.spend, 0) || 0;

              const calculation = spend
                ? (conversion_values / spend - 1) * 1000
                : 0;

              return calculation;
            };
            return getIntervalBasedResult(
              unique,
              interval,
              calculateROI,
              columnName
            );
          })
        }
        cardTitle={`ROI`}
      />
    </Flex>
  );
};

export default SecondRowCharts;
