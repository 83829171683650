import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import IconButton from 'components/common/IconButton';
import { replaceCommaWithLine } from 'helpers/utils';
import useUrl from 'hooks/useUrl';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { GlobalNegativeKeywordContext } from './GlobalNegativeKeywords';

const useGlobalNegativeForm = () => {
  const HiddenInput = useRef();
  const { context, setContext } = useContext(GlobalNegativeKeywordContext);
  const FormSubmitButton = () => (
    <IconButton
      icon="save"
      size="sm"
      type="submit"
      onClick={() => HiddenInput.current.click()}
    >
      Save
    </IconButton>
  );

  const FormBody = forwardRef(({ modalClose = () => {} }, ref) => {
    const [matchTypes, setMatchTypes] = useState([]);

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
      reset
    } = useForm();
    const query = useUrl();

    useEffect(() => {
      getCatchTypes();
      setValue('account_id', query.get('account_id'));
    }, [query.get('account_id')]);

    const getCatchTypes = () => {
      CallApi.get('/global-negatives/match-types', false).then(json => {
        if (json.status == 200) {
          setMatchTypes(json.data);
          setValue('match_type', json.data[0]);
        }
      });
    };

    const onSubmit = (data, form) => {
      CallApi.post('/global-negatives', new FormData(form.target)).then(
        json => {
          if (json.success_message) {
            toast.success(json.success_message);
          }

          if (json.error_message) {
            toast.error(json.error_message);
            setValue('keywords', json.rejected_keywords);
            return;
          }

          setContext({ ...context, refresh: !context.refresh });
          modalClose();
        }
      );
    };

    useImperativeHandle(ref, () => ({
      setCategoryAndKeywords: (category, keywords) => {
        setValue('match_type', 'PHRASE');
        setValue('category', category);
        setValue('keywords', replaceCommaWithLine(keywords));
      }
    }));

    return (
      <Form
        noValidate
        id="add-global-negative-entry"
        onSubmit={handleSubmit(onSubmit)}
      >
        <button type="submit" ref={HiddenInput} hidden />
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="4" className="pe-0">
            Match type{' '}
            <LocalStorageTooltip
              identifier={`form_global_negative_field_match_type`}
            />
          </Form.Label>
          <Col>
            <Form.Select
              size="sm"
              {...register('match_type', { required: true })}
              isInvalid={!!errors.match_type}
            >
              {matchTypes.map(matchType => (
                <option key={matchType} value={matchType}>
                  {matchType}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.match_type && errors.match_type.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="4">
            Category{' '}
            <LocalStorageTooltip
              identifier={`form_global_negative_field_category`}
            />
          </Form.Label>
          <Col>
            <Form.Control
              size="sm"
              {...register('category', { required: true })}
              isInvalid={!!errors.category}
            />
            <Form.Control.Feedback type="invalid">
              {errors.category && errors.category.message}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="4">
            Keywords{' '}
            <LocalStorageTooltip
              identifier={`form_global_negative_field_keywords`}
            />
          </Form.Label>
          <Col>
            <Form.Control
              as="textarea"
              {...register('keywords', { required: 'Keywords are required' })}
              isInvalid={!!errors.keywords}
              rows={12}
            />
            <Form.Control.Feedback type="invalid">
              {errors.keywords && errors.keywords.message}
            </Form.Control.Feedback>
            <Form.Text>*One keyword per line</Form.Text>
          </Col>
        </Form.Group>
        <Form.Control {...register('account_id')} hidden />
      </Form>
    );
  });

  return { FormBody, FormSubmitButton };
};

export default useGlobalNegativeForm;
