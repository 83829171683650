import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import routes from 'routes/routes';
import { flatRoutes } from 'helpers/utils';
import ComingSoon from 'components/pages/ComingSoon';
import Calendar from 'components/app/calendar/Calendar';
import BudgetOverviews from 'components/aida/budget-overviews/BudgetOverviews';
import Competitors from 'components/aida/competitors/Competitors';
import Metrics from 'components/aida/metrics/Metrics';
import Regions from 'components/aida/regions/Regions';
import Networks from 'components/aida/networks/Networks';
import MonthlyNotes from 'components/aida/monthly-notes/MonthlyNotes';
import Accounts from 'components/aida/accounts/Accounts';
import Clients from 'components/aida/clients/Clients';
import Tasks from 'components/aida/tasks/Tasks';
import AvazaAdsOverview from 'components/aida/avaza/AvazaAdsOverview';
import AvazaTimerows from 'components/aida/avaza/AvazaTimerows';
import AvazaClientOverview from 'components/aida/avaza/AvazaClientOverview';
import SearchQueryKeywords from 'components/aida/search-query-keywords/SearchQueryKeywords';
import FlexAbsence from 'components/aida/flex-absence/FlexAbsence';
import GlobalNegativeKeywords from 'components/aida/global-negative-keywords/GlobalNegativeKeywords';
import LambdaAutomations from 'components/aida/automation/LambdaAutomations';
import SeoProjects from 'components/aida/seo-projects/SeoProjects';
import CompetitorAnalysis from 'components/aida/competitor-analysis/CompetitorAnalysis';
import Reports from 'components/aida/reports/Reports';
import ClientOverview from 'components/aida/clients/overview/ClientOverview';
import ReportOverview from 'components/aida/reports/report-overview/ReportOverview';
import KeywordResearch from 'components/aida/keyword-research/KeywordResearch';
import StaticPage from 'components/common/custom-components/StaticPage';
import { AidaAppContext } from 'context/Context';
import Error403 from 'components/errors/Error403';

const inActiveRoutes = flatRoutes(routes).filter(route => !route.active);

const MainRoutes = () => {
  const { aidaAppContext } = useContext(AidaAppContext);
  return (
    <Switch>
      <Route path="/static-page" exact component={StaticPage} />
      <Route
        path="/aida/keywords-selection"
        exact
        component={SearchQueryKeywords}
      />
      <Route path="/unauthorized" component={Error403} />
      {aidaAppContext.user_permissions?.role !== 'Customer' && (
        <>
          {/*App*/}
          <Route path="/aida/competitors" exact component={Competitors} />
          <Route path="/aida/budget-overviews" component={BudgetOverviews} />
          <Route path="/aida/metrics" exact component={Metrics} />
          <Route path="/aida/regions" exact component={Regions} />
          <Route path="/aida/networks" exact component={Networks} />
          <Route path="/aida/monthlyNotes" exact component={MonthlyNotes} />
          <Route path="/aida/accounts" exact component={Accounts} />
          <Route path="/aida/clients" exact component={Clients} />
          <Route
            path="/aida/clients/overview"
            exact
            component={ClientOverview}
          />
          <Route
            path="/aida/avaza-ads-overviews"
            exact
            component={AvazaAdsOverview}
          />
          <Route
            path="/aida/avaza/client-overview"
            exact
            component={AvazaClientOverview}
          />
          <Route path="/aida/avaza-timerows" exact component={AvazaTimerows} />
          <Route path="/aida/tasks" exact component={Tasks} />
          <Route path="/aida/seo-projects" exact component={SeoProjects} />
          <Route path="/aida/flex-absence" exact component={FlexAbsence} />
          <Route path="/aida/reports" exact component={Reports} />
          <Route
            path="/aida/report-overview"
            exact
            component={ReportOverview}
          />
          <Route
            path="/aida/keyword-research"
            exact
            component={KeywordResearch}
          />
          <Route
            path="/competitor-analysis"
            exact
            component={CompetitorAnalysis}
          />
          <Route
            path="/aida/global-negatives"
            exact
            component={GlobalNegativeKeywords}
          />
          <Route
            path="/aida/lambda-automations"
            exact
            component={LambdaAutomations}
          />
        </>
      )}

      {/*Coming Soon*/}
      <Route
        path={inActiveRoutes.map(route => route.to)}
        exact
        component={ComingSoon}
      />
      {/*Redirect*/}
      <Redirect to="/errors/404" />
    </Switch>
  );
};
export default MainRoutes;
