import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext } from 'react';
import { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { LambdaAutomationsContext } from '../LambdaAutomations';

const SettingsButton = ({ current_settings, invoker_id, refetchScripts }) => {
  const ButtonGroups = [
    {
      value: 'script',
      label: 'Global',
      variant: 'outline-primary'
    },
    {
      value: 'client',
      label: 'Custom',
      variant: 'outline-primary'
    }
  ];

  const updateScriptType = (scriptId, newScriptType) => {
    let formData = new FormData();
    formData.append('invoker_id', scriptId);
    formData.append('settings', newScriptType);

    CallApi.put(`/lambda-script/script-setting`, formData, false).then(json => {
      if (json.status == 200) refetchScripts();
    });
  };

  return (
    <ButtonGroup>
      {ButtonGroups.map((button, index) => (
        <ToggleButton
          key={index}
          id={`invoker-${invoker_id}-radio-${index}`}
          type="radio"
          variant={button.variant}
          value={button.value}
          checked={current_settings === button.value}
          size="sm"
          onChange={e => updateScriptType(invoker_id, e.target.value)}
        >
          {button.label}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default SettingsButton;
