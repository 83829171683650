import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { Dropdown, Form, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { useEffect } from 'react';
import CallApi from 'components/common/custom-components/CallApi';

const TaskSubscribers = ({ taskId }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const getSubscribers = () => {
    if (taskId) {
      CallApi.get(`/task/${taskId}/subscriptions`, false).then(json => {
        setSubscriptions(json.data);
      });
    }
  };

  useEffect(() => {
    getSubscribers();
  }, [taskId]);

  return (
    <AvatarGroup>
      {subscriptions.map((subscription, index) => (
        <Dropdown key={index}>
          <Dropdown.Toggle
            as={Nav.Link}
            className={classNames('dropdown-caret-none p-0', {
              'ms-n1': index > 0
            })}
          >
            <Avatar
              src={subscription.user.avatar_url}
              size={'xl'}
              style={{ border: 'solid 3px green' }}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu
            align={isRTL ? 'end' : 'start'}
            className="dropdown-md px-0 py-3"
          >
            <Flex alignItems="center" className="px-3">
              <Avatar
                src={subscription.user.avatar_url}
                className="me-2"
                size="2xl"
              />
              <div className="flex-1">
                <h6 className="mb-0">{subscription.user.name}</h6>
              </div>
            </Flex>
            <Dropdown.Divider />
            <Dropdown.Item
              className={
                subscription.user?.last_seen ? 'text-primary' : 'text-dark'
              }
            >
              {subscription.user?.last_seen ? 'Seen at' : 'Never seen'}
            </Dropdown.Item>
            <Dropdown.Item>{subscription.user?.last_seen}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </AvatarGroup>
  );
};

export default TaskSubscribers;
