import CallApi from 'components/common/custom-components/CallApi';
import ReactSelectBoxIconLabel from 'components/common/custom-components/ReactSelectBoxIconLabel';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useNetworkSelectBox = () => {
  const getNetworks = () => CallApi.get('/networks', false);
  const { data } = useQuery('networks', getNetworks, {
    placeholderData: { data: [] }
  });

  const NetworkSelectBox = ({
    name = 'network_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a network',
    onChange = network => {},
    value,
    ...rest
  }) => {
    const [networkSelectBoxValue, setNetworkSelectBoxValue] = useState(null);

    useEffect(() => {
      if (data.data.length > 0) {
        setNetworkSelectBoxValue(
          data.data.find(network => network.id == value)
        );
      }
    }, [value]);

    return (
      <ReactSelect
        placeholder={placeholder}
        options={data.data}
        name={name}
        formatOptionLabel={network => (
          <ReactSelectBoxIconLabel
            icon={[network.icon_prefix, network.icon_name]}
            title={network.name}
          />
        )}
        onChange={network => onChange(network)}
        value={networkSelectBoxValue}
        getOptionValue={network => network.id}
        getOptionLabel={network => network.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        classNamePrefix="react-select"
      />
    );
  };

  return NetworkSelectBox;
};

export default useNetworkSelectBox;
