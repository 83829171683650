import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

const CategorizedKeywordsListing = ({ className, GlobalNegativeForm }) => {
  const [data, setData] = useState([]);
  const [apiCallInProgress, setApiCallInProgress] = useState(true);

  const columns = [
    {
      accessor: 'action',
      Header: 'Actions',
      headerProps: {
        className: 'text-center'
      },
      Cell: ({ row: { original } }) => (
        <IconButton
          icon={'plus'}
          variant="falcon-warning"
          size="sm"
          onClick={() =>
            GlobalNegativeForm.current?.setCategoryAndKeywords(
              original.category,
              original.keywords
            )
          }
        >
          Add
        </IconButton>
      )
    },
    {
      accessor: 'category',
      Header: 'Category'
    },
    {
      accessor: 'keywords',
      Header: 'Keywords'
    }
  ];

  useEffect(() => {
    CallApi.get('/categorized-keywords-lists', false).then(json => {
      if (json.status == 200) {
        setData(json.data);
        setApiCallInProgress(false);
      }
    });
  }, []);

  return (
    <Card className="mb-3 mt-3">
      <Card.Body>
        <AdvanceTableWrapper columns={columns} data={data} pagination>
          <AdvanceTable
            // @ts-ignore
            apiCallInProcess={apiCallInProgress}
            table
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              className: `fs--1 mb-0 overflow-hidden ${className}`
            }}
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};
export default CategorizedKeywordsListing;
