import { format, getMonth, getWeek, isAfter, isBefore } from 'date-fns';
import { getColor } from 'helpers/utils';
import { groupBy, sumBy } from 'lodash';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getColorName } from '../ClientOverview';
import ChartWidget from './ChartWidget';

const FirstRowCharts = ({
  interval,
  getIntervalBasedResult,
  checkboxesColumnColors,
  getFormattedChartLabels,
  budgetOverviews
}) => {
  const processData = budgetField => {
    const makeData = columnName => {
      let data = [];

      budgetOverviews.map(budgetOverview => {
        // to use only data if primary🔵, success🟢 or danger🔴 column is checked
        if (budgetOverview[columnName]) {
          data = [
            ...data,
            ...budgetOverview.bqdata_data_ads_campaigns.map(dailyBudget => ({
              date: dailyBudget.data_date,
              value: dailyBudget[budgetField]
            }))
          ];
        }
      });

      data = [...new Set(data)].sort(
        (prevDate, nextDate) =>
          new Date(prevDate.date) - new Date(nextDate.date)
      );

      const unique = groupBy(data, values => values.date);
      const calculateTotal = key => sumBy(unique[key], i => i.value);

      return getIntervalBasedResult(
        unique,
        interval,
        calculateTotal,
        columnName
      );
    };

    return checkboxesColumnColors.map(color => ({
      type: 'line',
      label: getColorName(color),
      borderWidth: 1,
      backgroundColor: getColor(`soft-${color}`),
      borderColor: getColor(color),
      pointRadius: 2,
      data: makeData(`${color}_column`)
    }));
  };

  return (
    <Row className="mt-1 g-3">
      <Col lg={3}>
        <ChartWidget
          chartLabels={getFormattedChartLabels(interval)}
          chartDataCallback={() => processData(`spend`)}
          cardTitle={`Spend`}
        />
      </Col>
      <Col lg={3}>
        <ChartWidget
          chartLabels={getFormattedChartLabels(interval)}
          chartDataCallback={() => processData(`conversions`)}
          cardTitle={`Conversions`}
        />
      </Col>
      <Col lg={3}>
        <ChartWidget
          chartLabels={getFormattedChartLabels(interval)}
          chartDataCallback={() => processData(`impressions`)}
          cardTitle={`Impressions`}
        />
      </Col>
      <Col lg={3}>
        <ChartWidget
          chartLabels={getFormattedChartLabels(interval)}
          chartDataCallback={() => processData(`clicks`)}
          cardTitle={`Clicks`}
        />
      </Col>
    </Row>
  );
};

export default FirstRowCharts;
