import React from 'react';
import IconButton from 'components/common/IconButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import UpsertForm from './UpsertForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import { useContext } from 'react';
import { ReportOverviewPageContext } from '../report-overview/ReportOverview';

const UpsertModal = ({
  reportNoteId = '',
  reportId = '',
  isEditing = false,
  iconOnly = false,
  iconClassName = ''
}) => {
  const { reportOverviewContext, setReportOverviewContext } = useContext(
    ReportOverviewPageContext
  );
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {iconOnly ? (
        <FontAwesomeIcon
          icon={isEditing ? 'edit' : 'plus'}
          className={iconClassName}
          onClick={() => setShow(true)}
        />
      ) : (
        <IconButton
          icon={isEditing ? 'pencil-alt' : 'plus'}
          size="sm"
          iconClassName=""
          onClick={() => setShow(true)}
        >
          {!isEditing && 'Add Note'}
        </IconButton>
      )}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Note' : 'Add Note'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <UpsertForm
            closeModal={() => {
              handleClose();
            }}
            reportNoteId={reportNoteId}
            reportId={reportId}
          />
        </Modal.Body>
        <Modal.Footer>
          {isEditing && (
            <IconButton
              className="me-auto"
              icon="trash-alt"
              size="sm"
              onClick={() => {
                confirm('Do you really want to delete this Note?') &&
                  CallApi.delete('/report-note/' + reportNoteId).then(json => {
                    if (json.status == 200)
                      setReportOverviewContext({
                        ...reportOverviewContext,
                        refreshNotes: !reportOverviewContext.refreshNotes
                      });
                  });
              }}
              variant="danger"
            >
              Remove
            </IconButton>
          )}
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="report-note-form"
            type="submit"
            icon="save"
            size="sm"
          >
            {isEditing ? 'Update' : 'Save'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

UpsertModal.propTypes = {};

export default UpsertModal;
