import React, { useContext, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext, { AidaAppContext } from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/routes';
import { capitalize } from 'helpers/utils';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavbarClientSelector from './NavbarClientSelector';
import CallApi from 'components/common/custom-components/CallApi';
import ClientColorBadge from 'components/aida/clients/ClientColorBadge';
import { useQuery } from 'react-query';
import {
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import useUrl from 'hooks/useUrl';
import AccountSelectionModal from './extra-components/AccountSelectionModal';

const NavbarVertical = () => {
  const { aidaAppContext } = useContext(AidaAppContext);
  const { data, isFetching } = useQuery(
    ['client_menu', aidaAppContext.client?.id],
    () => CallApi.get(`/clients/${aidaAppContext.client?.id}/menu`, false),
    {
      placeholderData: { data: [] },
      enabled: !!aidaAppContext.client?.id
    }
  );
  const location = useLocation();
  const url = useUrl();
  const history = useHistory();

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }

    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  const googleAccounts = data.data
    ?.find(navlinks => navlinks.label === 'Accounts')
    ?.children?.filter(link => link.network === 'google-ads');

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={40} />
      </Flex>
      <NavbarClientSelector className="d-md-none mb-3" />
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2)`
              : 'none'
        }}
      >
        <div className="navbar-vertical-content scrollbar">
          <NavbarClientSelector className="d-none d-md-block pe-1 mt-md-3 mt-xl-2" />
          {aidaAppContext.client &&
            aidaAppContext.user_permissions?.access_sidebar_links && (
              <Nav className="flex-column" as="ul">
                <Nav.Item as="li">
                  <div className="nav-link pb-0">
                    <Flex alignItems="center">
                      <span className="nav-link-text ps-1">
                        Level:{' '}
                        <ClientColorBadge
                          color={aidaAppContext.client?.color}
                        />{' '}
                        - {aidaAppContext.client?.ecom}
                      </span>
                    </Flex>
                  </div>
                </Nav.Item>
                <Nav.Item as="li">
                  <div className="nav-link py-0">
                    <Flex alignItems="center">
                      <span className="nav-link-text ps-1">
                        Analytics ID: {aidaAppContext.client?.type} -{' '}
                        {aidaAppContext.client?.property_id}
                      </span>
                    </Flex>
                  </div>
                </Nav.Item>
              </Nav>
            )}
          <Nav className="flex-column" as="ul">
            {isFetching ? (
              <Flex className="text-300 mt-3" alignItems={'center'}>
                <FontAwesomeIcon icon="spinner" className="fs-1" spin />
                <span className="ms-2">Loading...</span>
              </Flex>
            ) : (
              data.data.map(route => (
                <Fragment key={route.label}>
                  {location.pathname == '/aida/keywords-selection' && (
                    <>
                      {!googleAccounts
                        .map(account => account.account_id)
                        .includes(+url.get('account_id')) && (
                        <>
                          {googleAccounts.length === 1 ? (
                            <>
                              {history.push(
                                googleAccounts[0].children.find(
                                  pageLinks =>
                                    pageLinks.page == 'keywords-selection'
                                ).to
                              )}
                            </>
                          ) : (
                            <AccountSelectionModal
                              availableAccounts={googleAccounts}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenu routes={route.children} />
                </Fragment>
              ))
            )}
          </Nav>
          {aidaAppContext.user_permissions?.access_sidebar_links && (
            <Nav className="flex-column" as="ul">
              {routes.map(route => (
                <Fragment key={route.label}>
                  {route.icon && <FontAwesomeIcon icon={route.icon} />}
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenu routes={route.children} />
                </Fragment>
              ))}
            </Nav>
          )}
          <>
            {navbarPosition === 'combo' && (
              <div className={`d-${topNavbarBreakpoint}-none mb-3`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
