import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useContext, useState } from 'react';
import { Card, Accordion, Alert, Row, Col } from 'react-bootstrap';
import { ReportOverviewPageContext } from '../report-overview/ReportOverview';
import UpsertModal from './UpsertModal';

const Listing = ({ report }) => {
  const [data, setData] = useState([]);
  const { reportOverviewContext, setReportOverviewContext } = useContext(
    ReportOverviewPageContext
  );

  useEffect(() => {
    fetchReportNotes();
  }, [reportOverviewContext.refreshNotes, report]);

  const fetchReportNotes = () => {
    setReportOverviewContext({ ...reportOverviewContext, loadingNotes: true });
    CallApi.get(
      `/report-note?report_id=${report.id}`,
      reportOverviewContext.loadingNotes
    ).then(json => {
      if (json.status == 200) {
        setData(json.data);
        setReportOverviewContext({
          ...reportOverviewContext,
          currentOpenReportNote: json.data[0],
          loadingNotes: false
        });
      }
    });
  };
  const reportNoteIcon = reportNote => {
    if (reportNote.pdf_report) {
      return faFilePdf;
    }

    if (report.data_studio_iframe) {
      return faGoogle;
    }

    return '';
  };

  const ReportNoteItem = ({ index, reportNote, isFirst, isLast }) => (
    <Card
      className={classNames('shadow-none', {
        'rounded-bottom-0': isFirst,
        'border-bottom': !isLast,
        'rounded-0': !isLast && !isFirst
      })}
    >
      <Accordion.Item eventKey={index} className="border-0">
        <Card.Header
          className="p-0"
          id={`reportNoteAccordionHeading${reportNote.id}`}
        >
          <Row>
            <Col>
              <Accordion.Button
                onClick={() => {
                  setReportOverviewContext({
                    ...reportOverviewContext,
                    currentOpenReportNote: reportNote
                  });
                }}
                className="btn btn-link text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none"
              >
                <FontAwesomeIcon
                  icon="caret-right"
                  className="accordion-icon me-3 "
                  transform="shrink-2"
                />
                <span className="fw-medium font-sans-serif text-900">
                  {reportNote.visible_date} {reportNote.title}
                </span>
              </Accordion.Button>
            </Col>
            <Col xs="auto">
              <span className="float-end p-2">
                <FontAwesomeIcon
                  icon={reportNoteIcon(reportNote)}
                  className="mx-2 text-default"
                />
                <FontAwesomeIcon
                  icon={reportNote.show_customer ? 'user-check' : 'user-times'}
                  className={
                    reportNote.show_customer
                      ? 'mx-2 text-success'
                      : 'mx-2 text-default'
                  }
                />
                <UpsertModal
                  iconOnly
                  isEditing
                  reportId={report.id}
                  reportNoteId={reportNote.id}
                  iconClassName="mx-1 text-primary cursor-pointer"
                />
              </span>
            </Col>
          </Row>
        </Card.Header>
        <Accordion.Body className="p-0">
          <Card.Body className="pt-2">
            <p className="mb-1" style={{ textIndent: '2rem' }}>
              {reportNote.text}
            </p>
            {reportNote.digipartner_note && (
              <>
                <p className="text-black mb-0 fw-bolder">Digipartner Note:</p>
                <p className="mb-1" style={{ textIndent: '2rem' }}>
                  {' '}
                  {reportNote.digipartner_note}
                </p>
              </>
            )}
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  );

  return (
    <>
      {reportOverviewContext.loadingNotes ? (
        <Alert>
          <FontAwesomeIcon icon="info-circle" className="mr-2" />{' '}
          <span>Loading Notes...</span>
        </Alert>
      ) : data.length == 0 ? (
        <Alert>
          <FontAwesomeIcon icon="info-circle" className="mr-2" />{' '}
          <span>
            No notes added to this report yet! Use the Add Note option to add
            notes!
          </span>
        </Alert>
      ) : (
        <Accordion
          defaultActiveKey={0}
          id="notes-accordion"
          className="border rounded overflow-hidden"
        >
          {data.map((reportNote, index) => (
            <ReportNoteItem
              key={index}
              index={index}
              reportNote={reportNote}
              isFirst={index === 0}
              isLast={index === data.length - 1}
            />
          ))}
        </Accordion>
      )}
    </>
  );
};

export default Listing;
