import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper, {
  SelectBoxFilterMenu
} from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';
import {
  Card,
  Col,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {
  getYYYYMMDDFormatDate,
  getFirstDayOfCurrentMonth,
  getLastDayOfCurrentMonth
} from 'helpers/utils';
import { Link, useHistory } from 'react-router-dom';
import ClientColorBadge from '../clients/ClientColorBadge';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import WithRole from 'components/common/custom-components/WithRole';
import classNames from 'classnames';
import { faPause, faStop, faCheck } from '@fortawesome/free-solid-svg-icons';

const Listing = () => {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState([
    getFirstDayOfCurrentMonth(),
    getLastDayOfCurrentMonth()
  ]);
  const [startDate, endDate] = dateRange;
  const [apiCallInProcess, setApiCallInProcess] = useState(false);

  useEffect(() => {
    loadlisting();
  }, [endDate]);

  const loadlisting = () => {
    if (startDate && endDate) {
      setApiCallInProcess(true);
      CallApi.get(
        `/avaza/ads-overviews?start_date=${getYYYYMMDDFormatDate(
          startDate
        )}&end_date=${getYYYYMMDDFormatDate(endDate)}`
      ).then(json => {
        if (json.status == 200) {
          setData(json.data);
          setApiCallInProcess(false);
        }
      });
    }
  };

  const columns = [
    {
      accessor: 'client_status',
      Header: 'Active',
      Filter: SelectBoxFilterMenu,
      cellProps: {
        className: 'text-center'
      },
      Cell: ({ cell }) => {
        let icon;
        let iconClass;

        switch (cell.value) {
          case 'paused':
            icon = faPause;
            iconClass = 'text-warning';
            break;

          case 'removed':
            icon = faStop;
            iconClass = 'text-danger';
            break;

          default:
            icon = faCheck;
            iconClass = 'text-success';
            break;
        }

        return (
          <FontAwesomeIcon
            className={iconClass}
            title={cell.value}
            icon={icon}
            size={cell.value != 'active' ? 'sm' : 'lg'}
          />
        );
      }
    },
    {
      accessor: 'managers',
      Header: 'Managers',
      Cell: ({ cell }) => (
        <AvatarGroup>
          {cell.value.map(manager => (
            <OverlayTrigger
              key={manager.name}
              placement="top"
              overlay={<Tooltip>{manager.name}</Tooltip>}
            >
              <div>
                <Avatar size="l" src={manager.img} className="ms-n1" />
              </div>
            </OverlayTrigger>
          ))}
        </AvatarGroup>
      )
    },
    {
      accessor: 'project_lead.name',
      Header: 'Project Lead',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.project_lead.avatar_url}
        />
      )
    },
    {
      accessor: 'client_color',
      Header: 'Color',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => <ClientColorBadge color={cell.value} />
    },
    {
      accessor: 'client',
      Header: 'Client',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <Link
          to={`/aida/avaza/client-overview?client_id=${cell.row.original.client_id}`}
        >
          <NameWithIcon
            name={cell.value}
            icon={cell.row.original.client_favicon}
          />
        </Link>
      )
    },
    {
      accessor: 'invoice',
      Header: 'Invoice',
      cellProps: {
        className: 'text-end'
      },
      footerProps: {
        className: 'text-end'
      },
      Cell: ({ cell }) => cell.value + ' SEK',
      Footer: table => {
        const total = React.useMemo(
          () => table.page.reduce((sum, row) => row.values.invoice + sum, 0),
          [table.page]
        );
        return total + ' SEK';
      }
    },
    {
      accessor: 'hours',
      Header: 'Hours',
      sortType: 'basic',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      footerProps: {
        className: 'text-end'
      },
      Cell: ({ cell }) => cell.value + ' h',
      Footer: table => {
        const total = React.useMemo(
          () => table.page.reduce((sum, row) => row.values.hours + sum, 0),
          [table.page]
        );
        return Math.round(total) + ' h';
      }
    },
    {
      accessor: 'avaza_log',
      Header: 'Avaza Log',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      footerProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: table => {
        const total = React.useMemo(
          () => table.page.reduce((sum, row) => row.values.avaza_log + sum, 0),
          [table.page]
        );
        return Math.round(total) + ' h';
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'text-nowrap white-space-nowrap'
      },
      sortType: 'basic',
      Cell: ({ cell }) => (
        <ProgressBar>
          <ProgressBar
            now={cell.value}
            label={cell.value + ' %'}
            variant={cell.value <= 100 ? 'success' : 'danger'}
          />
        </ProgressBar>
      )
    },
    {
      accessor: 'diff',
      Header: 'Difference',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className={cell.value < 0 ? 'text-danger' : 'text-success'}>
          {cell.value} h
        </div>
      ),
      footerProps: {
        className: 'text-end'
      },
      Footer: table => {
        const total = React.useMemo(
          () => table.page.reduce((sum, row) => row.values.diff + sum, 0),
          [table.page]
        );
        return Math.round(total) + ' h';
      }
    },
    {
      accessor: 'avaza_lop',
      Header: 'Avaza Löp',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      footerProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: table => {
        const total = React.useMemo(
          () => table.page.reduce((sum, row) => row.values.avaza_lop + sum, 0),
          [table.rows]
        );
        return Math.round(total) + ' h';
      }
    },
    {
      accessor: 'avaza_extra',
      Header: 'Avaza Extra',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      footerProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: table => {
        const total = React.useMemo(
          () =>
            table.page.reduce((sum, row) => row.values.avaza_extra + sum, 0),
          [table.page]
        );
        return Math.round(total) + ' h';
      }
    }
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <AdvanceTableWrapper
              columns={columns}
              data={data}
              sortable
              pagination
              useTableFooter={true}
            >
              <Row className="align-items-end g-2 row mb-3">
                <Col className="col-auto">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={update => {
                      setDateRange(update);
                    }}
                    className="form-control pb-0 pt-1"
                    calendarStartDay={1}
                  />
                </Col>
                <Col className="col"></Col>
                <Col className="col-auto">
                  <AdvanceTableSearchBox table />
                </Col>
              </Row>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
                apiCallInProcess={apiCallInProcess}
                tableFooterClassName="table-secondary"
              />
              <AdvanceTableFooter
                table
                className="mt-3 mx-3"
                rowCount={data.length}
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

// ---------------------------------------------------------------------------------------------------------------------
// Higher Order Component to deal with Errors etc. IN-PROGRESS
// ---------------------------------------------------------------------------------------------------------------------
const AvazaAdsOverview = () => {
  // const WithRoleAdsOverview = WithRole(['Admin', 'User', 'Customer'])(Listing);

  return (
    <>
      <PageInfo slug="avaza_ads_overviews" />
      {/* <WithRoleAdsOverview /> */}
      <Listing />
    </>
  );
};

export default AvazaAdsOverview;
