import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Bar } from 'react-chartjs-2';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import CallApi from 'components/common/custom-components/CallApi';

const SpendChart = ({ budgetOverviewId }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        type: 'line',
        label: 'Projected',
        borderWidth: 1,
        borderColor: getColor('primary'),
        backgroundColor: getColor('soft-primary'),
        data: [],
        pointRadius: 0
      },
      {
        type: 'line',
        label: 'Current',
        backgroundColor: getColor('soft-danger'),
        borderColor: getColor('danger'),
        borderWidth: 1,
        data: [],
        pointRadius: 0
      },
      {
        type: 'bar',
        label: 'Spend',
        borderWidth: 1,
        fill: false,
        backgroundColor: getColor('soft-warning'),
        borderColor: getColor('warning'),
        data: []
      }
    ]
  });

  useEffect(() => {
    CallApi.get(`/dashboard/${budgetOverviewId}/spend-chart`, false).then(
      json => {
        return setData(makeData(json.data));
      }
    );
  }, []);

  const makeData = data => {
    const labels = data.map(budget => budget.currentDate);

    const dailyBudget = {
      type: 'line',
      label: 'Projected',
      borderWidth: 1,
      borderColor: getColor('primary'),
      backgroundColor: getColor('soft-primary'),
      data: data.map(budget => budget.total_projected_cost)
    };

    const cost = {
      type: 'line',
      label: 'Current',
      backgroundColor: getColor('soft-danger'),
      borderColor: getColor('danger'),
      borderWidth: 1,
      data: data.map(budget => budget.total_current_cost)
    };

    const dailyCost = {
      type: 'bar',
      label: 'Spend',
      borderWidth: 1,
      fill: false,
      backgroundColor: getColor('soft-warning'),
      borderColor: getColor('warning'),
      data: data.map(budget => budget.current_for_that_date)
    };

    return { labels: labels, datasets: [dailyBudget, cost, dailyCost] };
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: chartJsDefaultTooltip()
    },
    scales: {
      x: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      },
      y: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      }
    }
  };
  return (
    <Card className="h-100">
      <FalconCardHeader className="mt-1" title="Spend Chart" />
      <Card.Body className="pt-0">
        <Bar data={data} options={options} />
      </Card.Body>
    </Card>
  );
};

export default SpendChart;
