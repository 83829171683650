import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import CallApi from 'components/common/custom-components/CallApi';

class PageInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {
        name: '...',
        description: '...'
      }
    };
  }

  componentDidMount() {
    this.headerProps();
  }

  headerProps() {
    CallApi.get('/get-page-header/' + this.props.slug, false).then(json => {
      if (json.status == 200) {
        this.setState({
          item: json.data
        });
      }
    });
  }

  render() {
    return (
      <Card className="mb-3">
        <Card.Body>
          <h3 className="mb-0">{this.state.item.name}</h3>
          <p className="mt-2">{this.state.item.description}</p>
        </Card.Body>
      </Card>
    );
  }
}

export default PageInfo;
