/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useImperativeHandle, useState } from 'react';
import { forwardRef } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

const AdvanceTableSearchBox = forwardRef(
  (
    {
      globalFilter,
      excludeSearchTerm,
      showSearchToggle,
      setExcludeSearchTerm,
      setGlobalFilter,
      placeholder = 'Search...'
    },
    ref
  ) => {
    const [value, setValue] = useState(globalFilter);

    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined);
    }, 200);

    const clearInput = () => setValue('');

    useImperativeHandle(ref, () => ({
      clearInput() {
        clearInput();
      }
    }));

    return (
      <InputGroup className="position-relative">
        <FormControl
          value={value || ''}
          onChange={({ target: { value } }) => {
            setValue(value);
            onChange(value);
          }}
          size="sm"
          id="search"
          placeholder={placeholder}
          type="search"
          className="shadow-none"
        />
        <InputGroup.Text className="bg-transparent">
          <FontAwesomeIcon icon="search" className="fs--1 text-600" />
        </InputGroup.Text>
        {showSearchToggle ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {excludeSearchTerm
                  ? 'Excluding search term results'
                  : 'Exclude search term results?'}
              </Tooltip>
            }
          >
            <Form.Switch
              className="ms-2 mt-1"
              value={excludeSearchTerm}
              onChange={e => {
                setExcludeSearchTerm(!excludeSearchTerm);
              }}
            />
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </InputGroup>
    );
  }
);

export default AdvanceTableSearchBox;
