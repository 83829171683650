import { faPen } from '@fortawesome/free-solid-svg-icons';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import { addDays, addYears, subDays } from 'date-fns';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Card, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { FlexAbsenceContext } from './FlexAbsence';

const FlexAbsenceApprove = () => {
  const { register, reset, handleSubmit, control, setValue } = useForm();
  const { context, setContext } = useContext(FlexAbsenceContext);

  useEffect(() => {
    reset();
    setValue('currently_approved_until', context.approved_flex_absences_till);
  }, [context.approved_flex_absences_till]);

  const onSubmit = (data, form) => {
    const formData = new FormData();
    formData.append(
      'approved_flex_absences_till',
      getYYYYMMDDFormatDate(data.approved_flex_absences_till)
    );
    CallApi.post('/flex-absence/approve', formData, false).then(json =>
      json.status == 200
        ? setContext({ ...context, refresh: !context.refresh })
        : null
    );
  };

  return (
    <Card>
      <FalconCardHeader
        title="Approve Flex Absence Time Logs"
        className="border-bottom"
      />
      <Card.Body className="approve_flex_absence_form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>Currently Approved until</Form.Label>
            <FormControl {...register('currently_approved_until')} readOnly />
          </Form.Group>
          <FormGroup className="mb-3">
            <FormLabel>Update until(and including)</FormLabel>
            <Controller
              name={'approved_flex_absences_till'}
              control={control}
              render={({ field }) => (
                <ReactDatePicker
                  className="form-control"
                  dateFormat={'yyyy-MM-dd'}
                  onChange={date => field.onChange(date)}
                  minDate={addDays(
                    new Date(context.approved_flex_absences_till),
                    1
                  )}
                  selected={field.value}
                />
              )}
            />
          </FormGroup>
          <IconButton
            className="float-end"
            size="sm"
            type="submit"
            icon={'pencil-alt'}
          >
            Update
          </IconButton>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FlexAbsenceApprove;
