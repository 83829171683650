import PageInfo from 'components/common/custom-components/PageInfo';
import useUrl from 'hooks/useUrl';
import React, { createContext, useContext, useState } from 'react';
import CategoryChanger from './components/CategoryChanger';
import CategoryWiseScriptWrapper from './components/CategoryWiseScriptWrapper';
import { AidaAppContext } from 'context/Context';

export const LambdaAutomationsContext = createContext({});

const LambdaAutomations = () => {
  const [context, setContext] = useState({
    scriptCategoryId: null,
    active: true,
    scriptSettingType: '',
    refreshListing: true
  });

  return (
    <LambdaAutomationsContext.Provider value={{ context, setContext }}>
      <PageInfo slug="lambda_automations" />
      <CategoryChanger />
      <CategoryWiseScriptWrapper />
    </LambdaAutomationsContext.Provider>
  );
};

export default LambdaAutomations;
