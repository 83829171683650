import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Card, Table, Button, Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import BudgetOptimisationModal from './BudgetOptimisationModal';
import RefreshDataModal from './RefreshDataModal';

const getDiffTextColor = value => {
  if (value >= -5 && value <= 5) return 'text-success';
  if ((value > 5 && value < 10) || (value < -5 && value > -10)) {
    return 'text-warning';
  }
  if (value <= -10 || value >= 10) return 'text-danger';
};

const BudgetOverviewAdditionalDetails = ({
  budgetOverviewId,
  setCurrentBudgetOverviewId,
  setShowUpsertBudgetOverviewModal
}) => {
  const { data, refetch, isFetching, isFetched } = useQuery(
    ['budget-overviews-additional-details', budgetOverviewId],
    () =>
      CallApi.get(
        `/budget-overviews/${budgetOverviewId}/additional-details`,
        false
      ),
    { placeholderData: { data: {} } }
  );

  return (
    <Card border="primary">
      <FalconCardHeader
        title="Additional Details"
        titleTag="h5"
        className="mb-0 mt-1"
        endEl={
          <Flex>
            <a
              className={classNames('btn btn-falcon-primary me-2 btn-sm', {
                disabled: !data.data?.quick_link_to_account
              })}
              href={`${data.data?.quick_link_to_account}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={'external-link-alt'} /> Ads Platform
            </a>
            <RefreshDataModal
              accountId={data.data?.account_id}
              startDate={data.data?.start_date}
              endDate={data.data?.end_date}
              hasInProgressQueUpdateCampaigns={
                data.data?.has_in_progress_que_update_campaigns
              }
              refetchAdditionalDetails={refetch}
            />
          </Flex>
        }
      />
      <Card.Body className="pt-0">
        <Table striped size="sm" variant="secondary" className="mb-0">
          <tbody>
            <tr>
              <td>Budget Optimisation: </td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  <BudgetOptimisationModal
                    optimizationId={data.data?.optimization?.id}
                    budgetOverviewId={budgetOverviewId}
                    accountId={data.data?.account_id}
                    refetchAdditionalDetails={refetch}
                  >
                    {data.data?.optimization
                      ? data.data?.optimization?.active
                        ? 'AIDA'
                        : 'Manual'
                      : 'Not optimized yet.'}
                  </BudgetOptimisationModal>
                )}
              </td>
            </tr>
            <tr>
              <td>Budget Type:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  <Button
                    variant="link"
                    className="p-0"
                    size="sm"
                    onClick={() => {
                      setCurrentBudgetOverviewId(budgetOverviewId);
                      setShowUpsertBudgetOverviewModal(true);
                    }}
                  >
                    {data.data?.budget_type}
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td>Short Code:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.short_code
                )}
              </td>
            </tr>
            <tr>
              <td>PPC Specialist 1:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.ppc_specialist_1
                )}
              </td>
            </tr>
            <tr>
              <td>Project Lead:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.user_project_lead
                )}
              </td>
            </tr>
            <tr>
              <td>Invoice ID:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.invoice_id
                )}
              </td>
            </tr>
            <tr>
              <td>Invoice Fee:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.invoice_fee
                )}
              </td>
            </tr>
            <tr>
              <td>Invoice Media:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.invoice_media
                )}
              </td>
            </tr>
            <tr>
              <td>Budget:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.budget
                )}
              </td>
            </tr>
            <tr>
              <td>Note:</td>
              <td>
                {isFetching ? (
                  <FontAwesomeIcon icon={'spinner'} spin />
                ) : (
                  data.data?.budget_note
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <Row className="align-items-center">
          <Col>
            <h6 className="text-muted">Average Budget</h6>
            <span>
              {data.data?.budget_averages ? (
                data.data?.budget_averages.average_budget
              ) : (
                <FontAwesomeIcon icon={'spinner'} spin />
              )}{' '}
              sek/day
            </span>
          </Col>
          <Col>
            <h6 className="text-muted">Platform Setting</h6>
            <span>
              {data.data?.budget_averages ? (
                data.data?.budget_averages.platform_setting
              ) : (
                <FontAwesomeIcon icon={'spinner'} spin />
              )}{' '}
              sek/day
            </span>
          </Col>
          <Col>
            <h4
              className={`text-center ${getDiffTextColor(
                data.data?.budget_averages ? data.data?.budget_averages.diff : 0
              )}`}
            >
              {data.data?.budget_averages ? (
                data.data?.budget_averages.diff
              ) : (
                <FontAwesomeIcon icon={'spinner'} spin />
              )}
              %
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BudgetOverviewAdditionalDetails;
