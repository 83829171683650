import PageInfo from 'components/common/custom-components/PageInfo';
import React, { createContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Create from './Create';
import Listing from './Listing';

export const GlobalNegativeKeywordContext = createContext({});

const GlobalNegativeKeywords = () => {
  const [context, setContext] = useState({ refresh: false });

  return (
    <GlobalNegativeKeywordContext.Provider value={{ context, setContext }}>
      <PageInfo slug="global_negatives" />
      <Row>
        <Col xs={12} className="d-block d-lg-none pb-3 align-items-end">
          <Create variant={'modal'} className="float-end" />
        </Col>
        <Col xs={12} lg={8}>
          <Listing />
        </Col>
        <Col className="d-none d-lg-block" lg={4}>
          <Create variant={'card'} />
        </Col>
      </Row>
    </GlobalNegativeKeywordContext.Provider>
  );
};

export default GlobalNegativeKeywords;
