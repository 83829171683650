import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import { getYYYYMMDDFormatDate, roundToTwoDecimals } from 'helpers/utils';
import useUrl from 'hooks/useUrl';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const SplitByUser = ({ startDate, endDate, clientId }) => {
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);

  useEffect(() => {
    loadlisting();
  }, [endDate, clientId]);

  const loadlisting = () => {
    if (startDate && endDate && clientId) {
      setApiCallInProcess(true);
      CallApi.get(
        `/avaza/client-overview/split-by-user?start_date=${getYYYYMMDDFormatDate(
          startDate
        )}&end_date=${getYYYYMMDDFormatDate(endDate)}&client_id=${clientId}`,
        false
      ).then(json => {
        if (json.status == 200) {
          setData(json.data);
          setApiCallInProcess(false);
        }
      });
    }
  };

  const columns = [
    {
      accessor: 'first_name',
      Header: 'User',
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.user ? cell.row.original.user.avatar_url : ''}
          rounded="circle"
        />
      )
    },
    {
      accessor: 'avtalat',
      Header: 'Ads',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: info => {
        const total = React.useMemo(
          () =>
            roundToTwoDecimals(
              info.page.reduce((sum, row) => row.values.avtalat + sum, 0)
            ),
          [info.page]
        );
        return <div className="text-end">{total} h</div>;
      }
    },
    {
      accessor: 'uppstart',
      Header: 'Uppstart',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: info => {
        const total = React.useMemo(
          () =>
            roundToTwoDecimals(
              info.page.reduce((sum, row) => row.values.uppstart + sum, 0)
            ),
          [info.page]
        );
        return <div className="text-end">{total} h</div>;
      }
    },
    {
      accessor: 'seo',
      Header: 'SEO',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: info => {
        const total = React.useMemo(
          () =>
            roundToTwoDecimals(
              info.page.reduce((sum, row) => row.values.seo + sum, 0)
            ),
          [info.page]
        );
        return <div className="text-end">{total} h</div>;
      }
    },
    {
      accessor: 'lopande',
      Header: 'Löpande',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: info => {
        const total = React.useMemo(
          () =>
            roundToTwoDecimals(
              info.page.reduce((sum, row) => row.values.lopande + sum, 0)
            ),
          [info.page]
        );
        return <div className="text-end">{total} h</div>;
      }
    },
    {
      accessor: 'extra',
      Header: 'Extra',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: info => {
        const total = React.useMemo(
          () =>
            roundToTwoDecimals(
              info.page.reduce((sum, row) => row.values.extra + sum, 0)
            ),
          [info.page]
        );
        return <div className="text-end">{total.toFixed(2)} h</div>;
      }
    }
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <AdvanceTableWrapper
              columns={columns}
              data={data}
              sortable
              pagination
              useTableFooter={true}
            >
              <Row className="align-items-end g-2 row mb-3">
                <Col className="col-auto">
                  <h5>Split By User</h5>
                </Col>

                <Col className="col"></Col>
                <Col className="col-auto">
                  <AdvanceTableSearchBox table />
                </Col>
              </Row>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
                apiCallInProcess={apiCallInProcess}
                tableFooterClassName="table-secondary"
              />
              <AdvanceTableFooter
                table
                className="mt-3 mx-3"
                rowCount={data.length}
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SplitByUser;
