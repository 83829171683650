import PageInfo from 'components/common/custom-components/PageInfo';
import React, { createContext, useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import CompetitorsTable from '../competitor-analysis/components/CompetitorsTable';
import CompetitorSummary from './CompetitorSummary';
import ResultsTable from './ResultsTable';
import ScriptInputs from './ScriptInputs';

export const KeywordResearchPageContext = createContext({
  setKeywordResearchContext: contextValue => {},
  refetchCompetitorSummary: true,
  myDomain: ``,
  location: ``,
  keywordResults: []
});

const KeywordResearch = () => {
  const [keywordResearchContext, setKeywordResearchContext] = useState(
    useContext(KeywordResearchPageContext)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentSelectedCompetitors, setCurrentSelectedCompetitors] = useState(
    []
  );
  return (
    <KeywordResearchPageContext.Provider
      value={{ keywordResearchContext, setKeywordResearchContext }}
    >
      <PageInfo slug="keyword_research" />
      <Row className="g-3">
        <Col xs={12} lg={6}>
          <ScriptInputs isLoading={isLoading} setIsLoading={setIsLoading} />
        </Col>
        <Col xs={12} lg={6}>
          <CompetitorSummary
            setCurrentSelectedCompetitors={setCurrentSelectedCompetitors}
          />
        </Col>
        <Col xs={12}>
          <ResultsTable
            isLoading={isLoading}
            myDomain={keywordResearchContext.myDomain}
            currentSelectedCompetitors={currentSelectedCompetitors.map(
              competitor => competitor.original.domain
            )}
            data={keywordResearchContext.keywordResults}
          />
        </Col>
      </Row>
    </KeywordResearchPageContext.Provider>
  );
};

export default KeywordResearch;
