import React, { useContext, useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Modal, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import useAccountSelectBox from 'components/aida/custom-form-hooks/useAccountSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import ErrorBoundary from 'components/common/custom-components/ErrorBoundry';

const RefreshDataModal = ({
  accountId,
  startDate,
  endDate,
  hasInProgressQueUpdateCampaigns,
  refetchAdditionalDetails,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitButtonIcon, setSubmitButtonIcon] = useState('save');
  const [submitButtonVariant, setSubmitButtonVariant] = useState('primary');
  const handleClose = () => {
    setShow(false);
    setSubmitting(false);
    setSubmitButtonIcon('save');
    setSubmitButtonVariant('primary');
  };

  const RefreshDataForm = () => {
    const {
      handleSubmit,
      control,
      formState: { errors },
      reset
    } = useForm();

    useEffect(() => {
      reset({
        account_id: accountId,
        start_date: new Date(startDate),
        end_date: new Date(endDate)
      });
    }, []);

    const AccountSelectBox = useAccountSelectBox();
    const onSubmit = (data, form) => {
      setSubmitting(true);
      setSubmitButtonIcon('spinner');
      let formData = new FormData();
      for (const field in data) {
        formData.append(
          field,
          typeof data[field] == 'object'
            ? getYYYYMMDDFormatDate(data[field])
            : data[field]
        );
      }
      CallApi.post('/que-update-campaign', formData).then(json => {
        setSubmitting(false);
        if (json.inserted) {
          refetchAdditionalDetails();
          handleClose();
        } else {
          setSubmitButtonIcon('exclamation-triangle');
          setSubmitButtonVariant('danger');
        }
      });
    };

    return (
      <>
        <Form
          id="refresh-campaign-data-modal-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group className="mb-3">
            <Form.Label>Account ID</Form.Label>
            <Controller
              control={control}
              name="account_id"
              render={({ field }) => (
                <AccountSelectBox
                  isSearchable
                  value={field.value}
                  canShowErrorFeedback
                  onChange={account => field.onChange(account.account_id)}
                />
              )}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Controller
              control={control}
              name="start_date"
              render={({ field }) => (
                <ReactDatePicker
                  className="form-control"
                  dateFormat={'yyyy-MM-dd'}
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Controller
              control={control}
              name="end_date"
              render={({ field }) => (
                <ReactDatePicker
                  className="form-control"
                  dateFormat={'yyyy-MM-dd'}
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
          </Form.Group>
        </Form>
        {/* <DevTool control={control} /> */}
      </>
    );
  };

  return (
    <>
      <IconButton
        icon={hasInProgressQueUpdateCampaigns ? 'spinner' : 'retweet'}
        variant={
          hasInProgressQueUpdateCampaigns ? 'falcon-warning' : 'falcon-primary'
        }
        size="sm"
        onClick={() => setShow(true)}
        {...rest}
      >
        {hasInProgressQueUpdateCampaigns ? 'In-Progress' : 'Refresh'}
      </IconButton>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Refresh Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <ErrorBoundary>
            <RefreshDataForm />
          </ErrorBoundary>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="refresh-campaign-data-modal-form"
            type="submit"
            icon={submitButtonIcon}
            spin={submitting}
            variant={submitButtonVariant}
            size="sm"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

RefreshDataModal.propTypes = {};

export default RefreshDataModal;
