import PageInfo from 'components/common/custom-components/PageInfo';
import StaticPage from 'components/common/custom-components/StaticPage';
import { AidaAppContext } from 'context/Context';
import React, { useContext } from 'react';
import BudgetOverviews from './BudgetOverviews';

const Dashboard = () => {
  const { aidaAppContext } = useContext(AidaAppContext);

  return (
    <>
      {aidaAppContext.user_permissions?.role == 'User' || aidaAppContext.user_permissions?.role == 'Admin' ? (
          <>
            <PageInfo slug="dashboard" />
            <BudgetOverviews />
          </>
        ) : (
          <StaticPage identifier="end-customer-dashboard" />
        )}
    </>
  );
};

export default Dashboard;
