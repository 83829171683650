import React, { useContext, useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Modal, Form, Row, Col, Button, Badge } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import useAccountSelectBox from 'components/aida/custom-form-hooks/useAccountSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import { camelize, getYYYYMMDDFormatDate } from 'helpers/utils';
import ErrorBoundary from 'components/common/custom-components/ErrorBoundry';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import { DevTool } from '@hookform/devtools';
import classNames from 'classnames';
import { snakeCase } from 'lodash';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import AccountSettingsModal from './AccountSettingsModal';

const BudgetOptimizationModal = ({
  optimizationId,
  budgetOverviewId,
  children,
  refetchAdditionalDetails,
  accountId
}) => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const defaultValues = {
    active: null,
    budget_overview_id: budgetOverviewId,
    even_split_part: 0,
    max_extra_margin: 25,
    clicks: 1,
    conversion_values: 1,
    conversions: 1,
    impressions: 1,
    last_run: new Date(),
    update_frequency_days: null,
    note: ''
  };

  const { handleSubmit, control, register, watch, setValue, reset } = useForm({
    defaultValues: defaultValues
  });

  const handleClose = () => {
    reset(defaultValues);
    setShow(false);
  };

  const MutationActions = {
    onSuccess: response => {
      if (response.status == 200) {
        refetchAdditionalDetails();
        handleClose();
      } else {
        setErrors(response.errors);
      }
    }
  };

  const CreateOptimization = useMutation(
    form =>
      CallApi.post(
        `/budget-overview/${budgetOverviewId}/optimization`,
        new FormData(form.target),
        true,
        false
      ),
    { ...MutationActions }
  );

  const UpdateOptimization = useMutation(
    form =>
      CallApi.put(
        `/budget-overview/${budgetOverviewId}/optimization/${optimizationId}`,
        new FormData(form.target),
        true,
        false
      ),
    { ...MutationActions }
  );

  const submitButtonIcon =
    CreateOptimization.isLoading || UpdateOptimization.isLoading
      ? 'spinner'
      : 'save';

  const submitButtonVariant =
    CreateOptimization.isError || UpdateOptimization.isError
      ? 'warning'
      : 'primary';

  const onSubmit = (data, form) => {
    if (optimizationId) {
      UpdateOptimization.mutate(form);
    } else {
      CreateOptimization.mutate(form);
    }
  };

  const editBudgetOverviewOptimization = id => {
    if (show) {
      setIsFetching(true);
      CallApi.get(
        `/budget-overview/${budgetOverviewId}/optimization/${id}`
      ).then(json => {
        Object.keys(defaultValues).forEach(field => {
          setIsFetching(false);
          setValue(field, json.data[field]);
        });
      });
    }
  };

  useEffect(() => {
    if (optimizationId) editBudgetOverviewOptimization(optimizationId);
  }, [show]);

  const optimizationAgainstFields = [
    'Conversion Values',
    'Conversions',
    'Clicks',
    'Impressions'
  ];

  return (
    <>
      <Button
        variant="link"
        className="p-0"
        size="sm"
        onClick={() => setShow(true)}
      >
        {children}
      </Button>

      <Modal show={show} centered onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={'robot'} /> Automatic Budget Optimization{' '}
            {isFetching ? (
              <FontAwesomeIcon icon={'spinner'} pulse size="sm" />
            ) : (
              ''
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form
            id="refresh-campaign-data-modal-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="number"
              value={budgetOverviewId}
              disabled={isFetching}
              {...register('budget_overview_id')}
              hidden
            />
            <Form.Group className="mb-3">
              <Form.Label htmlFor="active">
                Active
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_budget_overview_optimization_field_active'}
                />
              </Form.Label>
              <Form.Select
                disabled={isFetching}
                {...register('active', { required: true })}
                isInvalid={errors?.active}
                id="active"
              >
                <option value="">Please select status</option>
                <option value={1}>Active</option>
                <option value={0}>In-Active</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="update_frequency_days">
                Update frequency (days)
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={
                    'form_budget_overview_optimization_field_update_frequency_days'
                  }
                />
              </Form.Label>
              <Form.Control
                disabled={isFetching}
                type="number"
                {...register('update_frequency_days', { required: true })}
                id="update_frequency_days"
                isInvalid={errors?.update_frequency_days}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.update_frequency_days &&
                  errors?.update_frequency_days.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="last_run">
                Latest run date
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={
                    'form_budget_overview_optimization_field_last_run'
                  }
                />
              </Form.Label>
              <Controller
                control={control}
                name="last_run"
                render={({ field }) => (
                  <ReactDatePicker
                    id="last_run"
                    className={classNames([
                      'form-control',
                      errors?.last_run && 'is-invalid'
                    ])}
                    name="last_run"
                    dateFormat={'yyyy-MM-dd'}
                    onChange={date => {
                      field.onChange(date);
                    }}
                    selected={new Date(field.value) || new Date()}
                    autoComplete={`false`}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.last_run && errors?.last_run.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="field_max_extra_margin">
                Max extra margin
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={
                    'form_budget_overview_optimization_field_max_extra_margin'
                  }
                />
              </Form.Label>
              <Flex>
                <Form.Range
                  id={`field_max_extra_margin`}
                  disabled={isFetching}
                  {...register('max_extra_margin', {
                    min: 25,
                    max: 200
                  })}
                  min="25"
                  max="200"
                  className="me-3"
                  step="1"
                />
                <h5 style={{ minWidth: `50px` }}>
                  {watch('max_extra_margin')}%
                </h5>
              </Flex>
              <Form.Control.Feedback type="invalid">
                {errors?.max_extra_margin &&
                  errors?.max_extra_margin.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Latest Notification
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_budget_overview_optimization_field_note'}
                />
              </Form.Label>
              <Form.Control
                as="textarea"
                disabled={isFetching}
                {...register('note')}
                readOnly
                isInvalid={errors?.note}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.note && errors?.note.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="field_even_split_part">
                Even split part
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={
                    'form_budget_overview_optimization_field_even_split_part'
                  }
                />
              </Form.Label>
              <Flex>
                <Form.Range
                  id={`field_even_split_part`}
                  disabled={isFetching}
                  {...register('even_split_part', {
                    min: 0,
                    max: 100
                  })}
                  min="0"
                  max="100"
                  className="me-3"
                  step="1"
                />
                <h5 style={{ minWidth: `50px` }}>
                  {watch('even_split_part')}%
                </h5>
              </Flex>
              <Form.Control.Feedback type="invalid">
                {errors?.even_split_part &&
                  errors?.even_split_part.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <h5 className="fw-semi-bold text-800 mb-2">
                Optimisation against:
              </h5>
              {optimizationAgainstFields.map(field => {
                const snakeCasedField = snakeCase(field);
                return (
                  <Row key={snakeCasedField}>
                    <Col xs={5}>
                      <Form.Label
                        htmlFor={`field_${snakeCasedField}`}
                        className={
                          errors?.[snakeCasedField] ? 'is-invalid' : ''
                        }
                      >
                        {field}
                      </Form.Label>
                      <Form.Control.Feedback className="mb-2" type="invalid">
                        {errors?.[snakeCasedField] &&
                          errors?.[snakeCasedField].map(error => error)}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={7}>
                      <Flex>
                        <Form.Range
                          id={`field_${snakeCasedField}`}
                          {...register(snakeCasedField)}
                          min="1"
                          defaultValue={1}
                          max="6"
                          disabled={isFetching}
                          className="me-3"
                          step="1"
                        />
                        <h5>
                          <Badge pill style={{ minWidth: `30px` }}>
                            {watch(snakeCasedField) || 0}
                          </Badge>
                        </h5>
                      </Flex>
                    </Col>
                  </Row>
                );
              })}
            </Form.Group>
          </Form>
          {/* <DevTool control={control} /> */}
        </Modal.Body>
        <Modal.Footer>
          <AccountSettingsModal accountId={accountId} className="me-auto">
            <IconButton icon="user" size="sm">
              Account Settings
            </IconButton>
          </AccountSettingsModal>

          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="refresh-campaign-data-modal-form"
            type="submit"
            icon={submitButtonIcon}
            spin={CreateOptimization.isLoading || UpdateOptimization.isLoading}
            variant={submitButtonVariant}
            disabled={
              CreateOptimization.isLoading || UpdateOptimization.isLoading
            }
            size="sm"
          >
            {optimizationId ? 'Update' : 'Save'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BudgetOptimizationModal.propTypes = {};

export default BudgetOptimizationModal;
