import React from 'react';
import { FormControl } from 'react-bootstrap';

// Create an editable cell renderer
const EditableCell = ({ cell: cell, updateDataFunction: updateMyData }) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(cell.value);

  const onChange = e => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(cell.row.original, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  return (
    <FormControl size="sm" value={value} onChange={onChange} onBlur={onBlur} />
  );
};

export default EditableCell;
