import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import SoftBadge from 'components/common/SoftBadge';
import {
  capitalize,
  getYYYYMMDDFormatDate,
  roundToTwoDecimals
} from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Form, Row } from 'react-bootstrap';

const ClientOverviewMainTable = ({ startDate, endDate, clientId }) => {
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const [type, setType] = useState('');

  useEffect(() => {
    loadlisting();
  }, [endDate, type, clientId]);

  const loadlisting = () => {
    if (startDate && endDate && clientId) {
      setApiCallInProcess(true);
      CallApi.get(
        `/avaza/client-overview?start_date=${getYYYYMMDDFormatDate(
          startDate
        )}&end_date=${getYYYYMMDDFormatDate(
          endDate
        )}&type=${type}&client_id=${clientId}`
      ).then(json => {
        if (json.status == 200) {
          setData(json.data);
          setApiCallInProcess(false);
        }
      });
    }
  };
  const types = ['Ads', 'Ö - Extra', 'Löpande', 'uppstart', 'seo'];

  const columns = [
    {
      accessor: 'user.first_name',
      Header: 'User',
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={
            cell.row.original.user.our_user
              ? cell.row.original.user.our_user.avatar_url
              : ''
          }
          rounded="circle "
        />
      )
    },
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'time',
      Header: 'Time',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: info => {
        const total = React.useMemo(
          () =>
            roundToTwoDecimals(
              info.page.reduce((sum, row) => row.values.time + sum, 0)
            ),
          [info.page]
        );
        return <div className="text-end">{total} h</div>;
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      Cell: ({ cell }) => {
        let bgColor = '';
        switch (cell.value) {
          case 'Debiterbart':
            bgColor = 'primary';
            break;

          case 'Ö - Extra':
            bgColor = 'warning';
            break;

          case 'Löpande':
            return <SoftBadge bg="warning">{cell.value}</SoftBadge>;
            break;

          case 'uppstart':
            return <SoftBadge bg="info">{capitalize(cell.value)}</SoftBadge>;
            break;

          default:
            bgColor = 'secondary';
            break;
        }
        return <Badge bg={bgColor}>{capitalize(cell.value)}</Badge>;
      }
    },
    {
      accessor: 'task',
      Header: 'Task'
    },
    {
      accessor: 'note',
      Header: 'Note'
    }
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <AdvanceTableWrapper
              columns={columns}
              data={data}
              sortable
              pagination
              useTableFooter={true}
            >
              <Row className="align-items-end g-2 row mb-3">
                <Col xs="auto">
                  <h5>Time Logs</h5>
                </Col>
                <Col />
                <Col xs="auto">
                  <Form.Select
                    size="sm"
                    onChange={e => setType(e.target.value)}
                  >
                    <option value="">All Types</option>
                    {types.map((type, i) => (
                      <option key={i} value={type}>
                        {capitalize(type)}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col xs="auto">
                  <AdvanceTableSearchBox table />
                </Col>
              </Row>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
                apiCallInProcess={apiCallInProcess}
                tableFooterClassName="table-secondary"
              />
              <AdvanceTableFooter
                table
                className="mt-3 mx-3"
                rowCount={data.length}
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ClientOverviewMainTable;
