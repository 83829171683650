/* eslint-disable react/prop-types */
import { roundToTwoDecimals } from 'helpers/utils';
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import { Row, Col, Button, Table, Form } from 'react-bootstrap';
import { MdOutlineHorizontalSplit } from 'react-icons/md';
const DayByDaySplit = forwardRef(
  ({ submitForm, budgetOverview, budgetDaily, setBudgetDaily }, ref) => {
    const [evenSplitSaving, setEvenSplitSaving] = useState(false);

    useEffect(() => {
      if (evenSplitSaving) {
        submitForm();
      }
    }, [budgetDaily]);

    const totals = {
      days: 0,
      spend: 0,
      invoice_media: 0,
      invoice_fee: 0,
      conversions: 0,
      conversion_values: 0,
      clicks: 0,
      impressions: 0
    };
    function calculateTotals(dailySplits) {
      let totalsUntilSecondLast = {};
      totalsUntilSecondLast.spend = 0;
      totalsUntilSecondLast.invoice_fee = 0;
      totalsUntilSecondLast.invoice_media = 0;
      totalsUntilSecondLast.conversions = 0;
      totalsUntilSecondLast.conversion_values = 0;
      totalsUntilSecondLast.clicks = 0;
      totalsUntilSecondLast.impressions = 0;

      totals.spend = 0;
      totals.invoice_fee = 0;
      totals.invoice_media = 0;
      totals.conversions = 0;
      totals.conversion_values = 0;
      totals.clicks = 0;
      totals.impressions = 0;

      dailySplits.forEach((budget, index, array) => {
        if (index < array.length - 1) {
          totalsUntilSecondLast.spend += +budget.spend;
          totalsUntilSecondLast.invoice_fee += +budget.invoice_fee;
          totalsUntilSecondLast.invoice_media += +budget.invoice_media;
          totalsUntilSecondLast.conversions += +budget.conversions;
          totalsUntilSecondLast.conversion_values += +budget.conversion_values;
          totalsUntilSecondLast.clicks += +budget.clicks;
          totalsUntilSecondLast.impressions += +budget.impressions;
        }

        totals.spend += +budget.spend;
        totals.invoice_fee += +budget.invoice_fee;
        totals.invoice_media += +budget.invoice_media;
        totals.conversions += +budget.conversions;
        totals.conversion_values += +budget.conversion_values;
        totals.clicks += +budget.clicks;
        totals.impressions += +budget.impressions;
      });

      return totalsUntilSecondLast;
    }

    function adjustLastDaySplit(dailySplits, totalsUntilSecondLast) {
      let lastDaySplit = dailySplits[dailySplits.length - 1];

      lastDaySplit.spend = budgetOverview.spend - totalsUntilSecondLast.spend;
      lastDaySplit.invoice_fee =
        budgetOverview.invoice_fee - totalsUntilSecondLast.invoice_fee;
      lastDaySplit.invoice_media =
        budgetOverview.invoice_media - totalsUntilSecondLast.invoice_media;
      lastDaySplit.conversions = roundToTwoDecimals(
        budgetOverview.goal_conversions - totalsUntilSecondLast.conversions
      );
      lastDaySplit.conversion_values = roundToTwoDecimals(
        budgetOverview.goal_conversion_values -
          totalsUntilSecondLast.conversion_values
      );
      lastDaySplit.clicks = roundToTwoDecimals(
        budgetOverview.goal_clicks - totalsUntilSecondLast.clicks
      );
      lastDaySplit.impressions = roundToTwoDecimals(
        budgetOverview.goal_impressions - totalsUntilSecondLast.impressions
      );

      dailySplits[dailySplits.length - 1] = lastDaySplit;

      return dailySplits;
    }

    function splitEven() {
      let dailySplits = [];

      //get days count
      const start_date = new Date(budgetOverview.start_date);
      const end_date = new Date(budgetOverview.end_date);

      Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };

      Date.prototype.ddmmyyyy = function () {
        return `${this.getFullYear()}-${('0' + (this.getMonth() + 1)).slice(
          -2
        )}-${('0' + this.getDate()).slice(-2)}`;
      };

      const days = (end_date - start_date) / (1000 * 3600 * 24) + 1;
      const perDay = Math.round(budgetOverview.spend / days);

      let perDayFields = {
        date: start_date,
        spend: perDay,
        invoice_media: Math.round(
          (perDay * budgetOverview.invoice_media) / budgetOverview.spend
        ),
        invoice_fee: Math.round(
          (perDay * budgetOverview.invoice_fee) / budgetOverview.spend
        ),
        conversions: roundToTwoDecimals(
          (perDay * budgetOverview.goal_conversions) / budgetOverview.spend
        ),
        conversion_values: roundToTwoDecimals(
          (perDay * budgetOverview.goal_conversion_values) /
            budgetOverview.spend
        ),
        clicks: roundToTwoDecimals(
          (perDay * budgetOverview.goal_clicks) / budgetOverview.spend
        ),
        impressions: roundToTwoDecimals(
          (perDay * budgetOverview.goal_impressions) / budgetOverview.spend
        )
      };

      for (let index = 0; index < days; index++) {
        perDayFields.date = start_date.addDays(index).ddmmyyyy();
        const fields = JSON.parse(JSON.stringify(perDayFields));
        dailySplits.push(fields);
      }

      totals.days = days;
      setBudgetDaily(
        adjustLastDaySplit(dailySplits, calculateTotals(dailySplits))
      );
      return true;
    }

    function updateCalculation(event) {
      let dailySplits = JSON.parse(JSON.stringify(budgetDaily));
      const budgetChanged = JSON.parse(
        event.target.getAttribute('data-budget')
      );
      const indexOfBudgetChanged = event.target.getAttribute('data-index');

      // take the value from the input and update the state
      // Also parseInt because by default form returns string 🥲
      budgetChanged.spend = Math.round(parseInt(event.target.value));

      budgetChanged.invoice_media = Math.round(
        (budgetChanged.spend * budgetOverview.invoice_media) /
          budgetOverview.spend
      );
      budgetChanged.invoice_fee = Math.round(
        (budgetChanged.spend * budgetOverview.invoice_fee) /
          budgetOverview.spend
      );
      budgetChanged.conversions = roundToTwoDecimals(
        (budgetChanged.spend * budgetOverview.goal_conversions) /
          budgetOverview.spend
      );
      budgetChanged.conversion_values = roundToTwoDecimals(
        (budgetChanged.spend * budgetOverview.goal_conversion_values) /
          budgetOverview.spend
      );
      budgetChanged.clicks = roundToTwoDecimals(
        (budgetChanged.spend * budgetOverview.goal_clicks) /
          budgetOverview.spend
      );
      budgetChanged.impressions = roundToTwoDecimals(
        (budgetChanged.spend * budgetOverview.goal_impressions) /
          budgetOverview.spend
      );

      dailySplits[indexOfBudgetChanged] = budgetChanged;

      setBudgetDaily(
        adjustLastDaySplit(dailySplits, calculateTotals(dailySplits))
      );
    }

    useImperativeHandle(ref, () => ({
      splitAndSave: () => {
        setEvenSplitSaving(true);
        splitEven();
      },
      evenSplitSaving: evenSplitSaving,
      turnOffEvenSplitSaving: () => setEvenSplitSaving(false)
    }));

    return (
      <>
        <Row className="mb-3">
          <Col>
            <h5>Day by Day Split</h5>
          </Col>
          <Col className="col-auto">
            <div className="ms-auto">
              <Button size="sm" onClick={() => splitEven()}>
                <MdOutlineHorizontalSplit className="pe-1 fs-1" />
                Even Split
              </Button>
            </div>
          </Col>
        </Row>
        <Table striped bordered responsive size="sm">
          <thead className="bg-200 text-900 white-space-nowrap">
            <tr className="text-end">
              <th scope="col" className="text-center">
                Date
              </th>
              <th className="text-left px-4">Spend</th>
              <th scope="col">Inv. M.</th>
              <th scope="col">Inv. Fee</th>
              <th scope="col">Conversions</th>
              <th scope="col">Conversion Values</th>
              <th scope="col">Clicks</th>
              <th scope="col">Impressions</th>
            </tr>
          </thead>
          <tbody>
            {budgetDaily.map((budget, index) => (
              <tr className="text-end white-space-nowrap" key={index}>
                <td className="text-center">{budget.date}</td>
                <td className="text-nowrap">
                  <Form.Control
                    onChange={updateCalculation}
                    value={budget.spend}
                    name="invoice_id"
                    min={0}
                    type="number"
                    data-budget={JSON.stringify(budget)}
                    data-index={index}
                    disabled={index == budgetDaily.length - 1 ? true : false}
                    className="py-0"
                  />
                </td>
                <td>{budget.invoice_media}</td>
                <td>{budget.invoice_fee}</td>
                <td>{Number(budget.conversions).toLocaleString('es-ES')}</td>
                <td>
                  {Number(budget.conversion_values).toLocaleString('es-ES')}
                </td>
                <td>{Number(budget.clicks).toLocaleString('es-ES')}</td>
                <td>{Number(budget.impressions).toLocaleString('es-ES')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
);

export default DayByDaySplit;
