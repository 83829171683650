import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Button, Row, Col, Modal, Form, Card } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Create from './Create';
import { AppSettings } from '../../../settings';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';
import React from 'react';

const Metrics = () => {
  const [metrics, setMetrics] = useState([]);
  const [show, setShow] = useState(false);
  const [metricId, setMetricsId] = useState('');
  const [apiCallInProcess, setApiCallInProcess] = useState(false);

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => {
        return (
          <>
            <IconButton
              icon="edit"
              className="me-2"
              size="sm"
              onClick={() => updateMetric(cell.row.values.id)}
            ></IconButton>
            <IconButton
              variant="danger"
              icon="trash-alt"
              size="sm"
              onClick={() => deleteMetric(cell.row.values.id)}
            ></IconButton>
          </>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Name'
    },
    {
      accessor: 'description',
      Header: 'Description'
    }
  ];

  function deleteMetric(id) {
    if (confirm("Attention! Are you sure you'd like to delete?")) {
      CallApi.delete(`/metrics/${id}`).then(json => {
        if (json.status == 200) loadListing();
      });
    }
  }

  function updateMetric(id) {
    setMetricsId(id);
    setShow(true);
  }

  function loadListing() {
    setApiCallInProcess(true);
    CallApi.get(`/metrics`).then(data => {
      setApiCallInProcess(false);
      if (data.status == 200) {
        setMetrics(data.data);
      }
    });
  }

  useEffect(() => {
    loadListing();
  }, []);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setMetricsId('');
    setShow(false);
  };

  return (
    <>
      <PageInfo slug="metrics" />
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={metrics}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col className="col-auto" xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
              <Col className="col">
                <IconButton
                  icon="plus"
                  size="sm"
                  className="float-end"
                  onClick={handleShow}
                >
                  Add New
                </IconButton>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProcess}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={metrics.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add a new Metric
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Create
            loadListing={loadListing}
            setShow={setShow}
            metricId={metricId}
            setMetricsId={setMetricsId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Metrics;
