import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FlexAbsenceContext } from './FlexAbsence';

function FlexAbsenceTotal({ className = '' }) {
  const [total, setTotal] = useState(null);
  const { context } = useContext(FlexAbsenceContext);

  useEffect(() => {
    CallApi.get('/flex-absence/total-hours', false).then(json => {
      json.status == 200 ? setTotal(json.data) : null;
    });
  }, [context.refresh]);

  return (
    <Alert variant="info" className={`text-center ${className}`}>
      Current Flex Account = {total}
    </Alert>
  );
}

export default FlexAbsenceTotal;
