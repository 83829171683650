import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import CategorizedKeywordsListing from './CategorizedKeywordsListing';
import useGlobalNegativeForm from './useGlobalNegativeForm';

const Create = ({ variant, ...rest }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const { FormBody, FormSubmitButton } = useGlobalNegativeForm();
  const GlobalNegativeForm = useRef();

  const CardForm = () => (
    <>
      <Card {...rest}>
        <Card.Header className="border-bottom">
          <h5>Add Global Negative Keywords</h5>
        </Card.Header>
        <Card.Body>
          <FormBody ref={GlobalNegativeForm} />
        </Card.Body>
        <Card.Footer className="border-top text-end">
          <FormSubmitButton />
        </Card.Footer>
      </Card>
      <CategorizedKeywordsListing
        GlobalNegativeForm={GlobalNegativeForm}
        className="mt-0"
      />
    </>
  );

  const ModalForm = () => (
    <>
      <IconButton
        {...rest}
        icon={'plus'}
        variant={'primary'}
        size="sm"
        onClick={() => setShow(true)}
      >
        Add
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Refresh Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <FormBody ref={GlobalNegativeForm} modalClose={handleClose} />
          <CategorizedKeywordsListing
            GlobalNegativeForm={GlobalNegativeForm}
            className="mt-4"
          />
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <FormSubmitButton />
        </Modal.Footer>
      </Modal>
    </>
  );

  return (
    <>
      {variant == 'modal' && <ModalForm />}
      {variant == 'card' && <CardForm />}
    </>
  );
};

export default Create;
