import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import useClientSelectBox from 'components/aida/custom-form-hooks/useClientSelectBox';
import { DevTool } from '@hookform/devtools';
import useRegionSelectBox from 'components/aida/custom-form-hooks/useRegionSelectBox';
import useUserSelectBox from 'components/aida/custom-form-hooks/useUserSelectBox';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import { useEffect } from 'react';

const UpsertForm = ({ closeModal, projectId }) => {
  const [errors, setErrors] = useState({});
  const { handleSubmit, register, control, reset } = useForm();

  const onSubmit = (data, form) => {
    if (projectId) {
      CallApi.put(
        '/seo-project/' + projectId,
        new FormData(form.target),
        true,
        false
      ).then(json => {
        if (json.status == 422) {
          setErrors(json.errors);
        } else {
          closeModal();
        }
      });
    } else {
      CallApi.post('/seo-project', new FormData(form.target), true, false).then(
        json => {
          if (json.status == 422) {
            setErrors(json.errors);
          } else {
            closeModal();
          }
        }
      );
    }
  };

  const editProject = project_id => {
    CallApi.get('/seo-project/' + project_id).then(json => reset(json.data));
  };

  useEffect(() => {
    if (projectId) editProject(projectId);
  }, []);

  const ClientSelectBox = useClientSelectBox();
  const RegionSelectBox = useRegionSelectBox();
  const PrimaryUserSelectBox = useUserSelectBox();
  const SecondaryUserSelectBox = useUserSelectBox();

  return (
    <>
      <Form noValidate id="seo-project-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Label>
            Status
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_active'}
            />
          </Form.Label>
          <Form.Select
            {...register('active', { required: true })}
            isInvalid={errors.active}
          >
            <option value="">Please select status</option>
            <option value={1}>Active</option>
            <option value={0}>In-Active</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.active && errors.active.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Name
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_name'}
            />
          </Form.Label>
          <Form.Control
            {...register('name', { required: true })}
            isInvalid={errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name && errors.name.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Client
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_client'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="client_id"
            render={({ field }) => (
              <ClientSelectBox
                isSearchable
                required
                className={errors.client_id && 'is-invalid'}
                onChange={client => field.onChange(client.id)}
                value={field.value}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.client_id && errors.client_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Domain
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_domain'}
            />
          </Form.Label>
          <Form.Control
            {...register('domain', { required: true })}
            isInvalid={errors.domain}
          />
          <Form.Control.Feedback type="invalid">
            {errors.domain && errors.domain.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Region
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_region'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="region_id"
            render={({ field }) => (
              <RegionSelectBox
                className={errors.region_id && 'is-invalid'}
                isSearchable
                onChange={region => field.onChange(region.id)}
                value={field.value}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.region_id && errors.region_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Keyword Download Frequency
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_keyword_download_frequency'}
            />
          </Form.Label>
          <Form.Select
            {...register('keyword_download_frequency', { required: true })}
            isInvalid={errors.keyword_download_frequency}
          >
            <option>Please select a frequency</option>
            <option value={'daily'}>Daily</option>
            <option value={'weekly'}>Weekly</option>
            <option value={'monthly'}>Monthly</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.keyword_download_frequency &&
              errors.keyword_download_frequency.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Page Check Frequency
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_page_check_frequency'}
            />
          </Form.Label>
          <Form.Select
            {...register('page_check_frequency', { required: true })}
            isInvalid={errors.page_check_frequency}
          >
            <option>Please select a frequency</option>
            <option value={'daily'}>Daily</option>
            <option value={'weekly'}>Weekly</option>
            <option value={'monthly'}>Monthly</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.page_check_frequency &&
              errors.page_check_frequency.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Page Check Device
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_page_check_device'}
            />
          </Form.Label>
          <Form.Select
            {...register('page_check_device', { required: true })}
            isInvalid={errors.page_check_device}
          >
            <option>Please select a device</option>
            <option value={'desktop'}>Desktop</option>
            <option value={'mobile'}>Mobile</option>
            <option value={'both'}>Both</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.page_check_device &&
              errors.page_check_device.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Primary User
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_primary_user'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="primary_user_id"
            render={({ field }) => (
              <PrimaryUserSelectBox
                className={errors.primary_user_id && 'is-invalid'}
                isSearchable
                name="primary_user_id"
                onChange={user => field.onChange(user.id)}
                value={field.value}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.primary_user_id &&
              errors.primary_user_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Secondary User
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_secondary_user'}
            />
          </Form.Label>
          <Controller
            control={control}
            name="secondary_user_id"
            render={({ field }) => (
              <SecondaryUserSelectBox
                isSearchable
                className={errors.secondary_user_id && 'is-invalid'}
                name="secondary_user_id"
                onChange={user => field.onChange(user.id)}
                value={field.value}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.secondary_user_id &&
              errors.secondary_user_id.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Note
            <LocalStorageTooltip
              className="ms-1"
              identifier={'form_seo_project_note'}
            />
          </Form.Label>
          <Form.Control
            as="textarea"
            {...register('note')}
            isInvalid={errors.note}
          />
          <Form.Control.Feedback type="invalid">
            {errors.note && errors.note.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

Form.propTypes = {};

export default UpsertForm;
