import PageInfo from 'components/common/custom-components/PageInfo';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { TasksListing } from './components/Listing';

const Tasks = () => {
  return (
    <>
      <PageInfo slug="tasks" />
      <Row>
        <Col xxl="12">
          <TasksListing />
        </Col>
      </Row>
    </>
  );
};

export default Tasks;
