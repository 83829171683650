import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UpsertModal from './UpsertModal';

const Listing = () => {
  const [data, setData] = useState([]);
  const [apiCallInProgress, setApiCallInProgress] = useState(true);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = () =>
    CallApi.get('/report', apiCallInProgress).then(json => {
      setApiCallInProgress(false);
      if (json.status == 200) {
        setData(json.data);
      }
    });

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <>
          <UpsertModal
            reportId={cell.value}
            isEditing={true}
            refreshListing={fetchReports}
          />
          <IconButton
            icon="trash-alt"
            className="ms-1"
            variant="danger"
            size="sm"
            onClick={() =>
              confirm('Do you really want to delete this report?') &&
              CallApi.delete('/report/' + cell.value).then(json => {
                if (json.status == 200) fetchReports();
              })
            }
          />
        </>
      )
    },
    {
      accessor: 'client.name',
      Header: 'Client',
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.client?.favicon}
        />
      )
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ cell }) =>
        cell.value ? (
          <div className="text-success text-center">
            <FontAwesomeIcon icon={['fas', 'check']} size="lg" title="Active" />
          </div>
        ) : (
          <div className="text-danger text-center">
            <FontAwesomeIcon
              icon={['fas', 'times']}
              size="lg"
              title="In-Active"
            />
          </div>
        )
    },
    {
      accessor: 'name_short',
      Header: 'Name Short'
    },
    {
      accessor: 'name_long',
      Header: 'Name Long',
      Cell: ({ cell, row: { original } }) => (
        <Link to={`/aida/report-overview?id=${original.id}`}>
          <strong>{cell.value}</strong>
        </Link>
      )
    },
    {
      accessor: 'project_lead.name',
      Header: 'Project Lead',
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.project_lead.avatar_url}
        />
      )
    },
    {
      accessor: 'analyst.name',
      Header: 'Analyst',
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.analyst.avatar_url}
        />
      )
    },
    {
      accessor: 'show_for_customer',
      Header: 'Show for customer',
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <FontAwesomeIcon
          className={`text-${cell.value ? 'success' : 'danger'}`}
          icon={['fas', cell.value ? 'check' : 'times']}
          size="lg"
        />
      )
    },
    {
      accessor: 'description',
      Header: 'Description'
    },
    {
      accessor: 'data_studio_link',
      Header: 'Datastudio LInk'
    },
    {
      accessor: 'data_studio_iframe',
      Header: 'Datastudio iFrame'
    }
  ];
  return (
    <Card>
      <Card.Body>
        <AdvanceTableWrapper columns={columns} data={data} sortable pagination>
          <Row className="align-items-end g-2 row mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col />
            <Col xs="auto">
              <UpsertModal refreshListing={fetchReports} />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={apiCallInProgress}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default Listing;
