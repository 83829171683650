/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import CallApi from 'components/common/custom-components/CallApi';

export function ImportModal({
  showImportModal,
  setshowImportModal,
  loadlisting
}) {
  const [showResponse, setShowResponse] = useState({
    show: false,
    response: []
  });

  const importBudgetOverview = event => {
    event.preventDefault();
    CallApi.post('/budget-overviews/import', new FormData(event.target)).then(
      json => {
        if (json.status == 200)
          setShowResponse({ show: true, response: json.data });
      }
    );
  };

  if (!showImportModal) return null;

  return (
    <Modal
      show={showImportModal}
      onHide={() => {
        setShowResponse({ show: false, response: [] });
        loadlisting();
        setshowImportModal(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Import Budgets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            {!showResponse.show && (
              <Form
                id="import-budget-overview"
                encType="multipart/form-data"
                onSubmit={importBudgetOverview}
              >
                <Form.Group className="my-3">
                  <Form.Control
                    size="sm"
                    name="excel-file"
                    type="file"
                    required
                  />
                </Form.Group>
              </Form>
            )}
            {showResponse.show &&
              showResponse.response.map((response, index) => {
                return (
                  <ul className="list-unstyled" key={index}>
                    <li
                      className={
                        response.error ? 'text-danger' : 'text-success'
                      }
                    >
                      {response.message + ' ' + response.row}
                    </li>
                    {response.errors && response.error && (
                      <ul>
                        {response.errors.map((error, index) => {
                          return <li key={index}>{error}</li>;
                        })}
                      </ul>
                    )}
                  </ul>
                );
              })}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <IconButton
          icon={['far', 'file-excel']}
          href="/files/demo_sheet_budget_overview_import_aida.xlsx"
          className="me-auto"
          size="sm"
          download="Import Format For Budget Import"
        >
          Download Sample File
        </IconButton>
        {!showResponse.show && (
          <IconButton
            icon="file-import"
            form="import-budget-overview"
            size="sm"
            type="submit"
          >
            Import
          </IconButton>
        )}
      </Modal.Footer>
    </Modal>
  );
}
