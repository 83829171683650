import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import { ExtractHostname, getYYYYMMDDFormatDate } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import ExportXLSX from '../budget-overviews/components/ExportXLSX';

const ResultsTable = ({
  myDomain,
  isLoading,
  currentSelectedCompetitors,
  data = []
}) => {
  const [tableData, setTableData] = useState(data);
  const [showUrl, setShowUrl] = useState(false);
  const getColumns = () =>
    currentSelectedCompetitors.map(competitorDomain => {
      const urlColumn = {
        id: `${competitorDomain}_url`,
        Header: `${competitorDomain} URL`,
        accessor: v =>
          v.find(item => ExtractHostname(item.url) == competitorDomain)?.url,
        Cell: ({ cell }) => (
          <>
            {cell.value && (
              <a
                className="cursor-pointer me-2"
                href={`${
                  cell.row.original.find(
                    item => ExtractHostname(item.url) == competitorDomain
                  )?.url
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={'external-link-alt'} className="me-1" />
                {cell.value}
              </a>
            )}
          </>
        )
      };

      const positionColumn = {
        id: competitorDomain,
        Header: `${competitorDomain} Position`,
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end' },
        accessor: v =>
          v.find(item => ExtractHostname(item.url) == competitorDomain)
            ?.position
      };

      return showUrl ? [positionColumn, urlColumn] : [positionColumn];
    });

  useEffect(() => {
    setTableData(
      data.filter(keywords =>
        keywords.find(
          item =>
            ExtractHostname(item.url) == myDomain ||
            currentSelectedCompetitors.includes(ExtractHostname(item.url))
        )
      )
    );
  }, [currentSelectedCompetitors]);

  const columns = useMemo(() => {
    const staticColumns = [
      {
        id: 'keyword',
        accessor: v => v[0].keyword,
        Header: 'Keyword'
      },
      {
        id: 'frequency',
        accessor: v => v.length,
        Header: 'Frequency',
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end' }
      },
      {
        id: 'cpc',
        accessor: v => v[0].cpc,
        Header: 'CPC',
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end' }
      },
      {
        id: 'volume',
        accessor: v => v[0].volume,
        Header: 'Volume',
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end' }
      },
      {
        id: 'competition',
        accessor: v => v[0].competition,
        Header: 'Competition',
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end' }
      },
      {
        accessor: 'kei',
        Header: 'KEI',
        cellProps: { className: 'text-end' }
      },
      {
        id: 'my_position',
        Header: 'My Position',
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end' },
        accessor: v =>
          v.find(item => ExtractHostname(item.url) == myDomain)?.position
      }
    ];
    const myUrlColumn = showUrl
      ? [
          {
            id: 'my_url',
            accessor: v =>
              v.find(item => ExtractHostname(item.url) == myDomain)?.url,
            Header: 'My URL',
            Cell: ({ cell }) => (
              <span>
                {cell.value && (
                  <a
                    className="cursor-pointer me-2"
                    href={`${
                      cell.row.original.find(
                        item => ExtractHostname(item.url) == myDomain
                      )?.url
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      className="me-1"
                      icon={'external-link-alt'}
                    />
                    {cell.value}
                  </a>
                )}
              </span>
            )
          }
        ]
      : [];

    const newColumns = getColumns();
    const showingColumns = [];

    newColumns.map(newColumnArray =>
      newColumnArray.forEach(newColumn => showingColumns.push(newColumn))
    );

    const finalNewColumns = newColumns.length > 0 ? showingColumns : [];

    return [...staticColumns, ...myUrlColumn, ...finalNewColumns];
  }, [data, currentSelectedCompetitors, showUrl]);
  return (
    <Card>
      <Card.Body className="px-0">
        <AdvanceTableWrapper
          columns={columns}
          data={tableData}
          selection
          sortable
          pagination
          sortByOptions={useMemo(
            () => [
              {
                id: 'frequency',
                desc: true
              }
            ],
            []
          )}
        >
          <Row className="align-items-center mb-3 px-3">
            <Col>
              <h5>Results</h5>
            </Col>
            <Col xs="auto" className="text-right">
              <Flex>
                Show URLs
                <Form.Check
                  checked={showUrl}
                  onChange={e => setShowUrl(e.target.checked)}
                  className="ms-2"
                  type="switch"
                  title="Toggle URLs"
                />
              </Flex>
            </Col>
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col xs="auto" className="ps-0">
              <ExportXLSX
                table
                sheetHeaderRow={columns.map(column => column.Header)}
                ignoreableColumnIndexes={[0]}
                filename={`${getYYYYMMDDFormatDate(
                  new Date()
                )} - Keyword Research - ${myDomain.replace('.', '_')}`}
              />
            </Col>
          </Row>
          <AdvanceTable
            // @ts-ignore
            apiCallInProcess={isLoading}
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <AdvanceTableFooter
            // @ts-ignore
            table
            className="mt-3 mx-3"
            rowCount={tableData.length}
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default ResultsTable;
