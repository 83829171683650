import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ExportXLSX from '../budget-overviews/components/ExportXLSX';
import { KeywordResearchPageContext } from './KeywordResearch';

const CompetitorSummary = ({ setCurrentSelectedCompetitors }) => {
  const { keywordResearchContext, setKeywordResearchContext } = useContext(
    KeywordResearchPageContext
  );
  const source = keywordResearchContext.location;
  const domain = keywordResearchContext.myDomain;
  const CompetitorSummaryTable = useRef();
  const columns = [
    {
      accessor: 'domain',
      Header: 'Domain',
      Cell: ({ cell }) => (
        <a
          href={`https://${cell.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'common_keywords',
      Header: 'Common Keyword',
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'total_keywords',
      Header: 'Total Keyword',
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'relevance',
      Header: 'Relevance',
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'points',
      Header: 'Points',
      cellProps: { className: 'text-end' }
    }
  ];

  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const loadListing = () => {
    if (source && domain) {
      setApiCallInProcess(true);
      CallApi.get(
        `/competitors/se-ranking-reports/competitors?source=${source}&domain=${domain}`,
        apiCallInProcess
      ).then(json => {
        if (json.status == 200) {
          setApiCallInProcess(false);
          setData(
            json.data.filter(item =>
              keywordResearchContext.currentCompetitorDomains.includes(
                item.domain
              )
            )
          );
          CompetitorSummaryTable.current?.selectAllRows();
        }
      });
    }
  };

  useEffect(() => {
    loadListing();
  }, [keywordResearchContext.refetchCompetitorSummary]);

  return (
    <Card>
      <Card.Body className="px-0">
        <AdvanceTableWrapper
          ref={CompetitorSummaryTable}
          selection
          columns={useMemo(() => columns, [])}
          data={data}
          sortable
          onRowSelect={selectedRows =>
            setCurrentSelectedCompetitors(selectedRows)
          }
          pagination
        >
          <Row className="align-items-center mb-3 px-3">
            <Col>
              <h5>Competitor Summary</h5>
            </Col>
            <Col xs="auto" className="text-right">
              <Row>
                <Col xs="auto" className="pe-0">
                  <AdvanceTableSearchBox table />
                </Col>
                <Col xs="auto" className="ps-0">
                  <ExportXLSX
                    table
                    sheetHeaderRow={[
                      'Domain',
                      'Common Keyword',
                      'Total Keyword',
                      'Relevance',
                      'Points'
                    ]}
                    ignoreableColumnIndexes={[0]}
                    filename={`${domain} - ${source} - Competitor Analysis`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              className: 'fs--1 mb-0 overflow-hidden border'
            }}
            apiCallInProcess={apiCallInProcess}
          />
          <AdvanceTableFooter
            table
            className="mt-3 mx-3"
            rowCount={data.length}
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default CompetitorSummary;
