import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { SelectBoxFilterMenu } from 'components/common/advance-table/AdvanceTableWrapper';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import Flex from 'components/common/Flex';
import { capitalize } from 'helpers/utils';
import React from 'react';

const getDiffBackgroundColor = value => {
  if (value >= -2 && value <= 2) return 'bg-success text-white';
  if ((value > 2 && value < 5) || (value < -2 && value > -5)) {
    return 'bg-warning text-white';
  }
  if (value <= -5 || value >= 5) return 'bg-danger text-white';
};

const getBgColorForGoals = value => {
  if (value == 0) return 'bg-warning text-white';
  if (value > 0) return 'bg-success text-white';
  if (value < 0) return 'bg-danger text-white';
};
const expanderColumn = [
  {
    Header: () => null,
    id: 'expander',
    headerProps: {
      className: 'bg-200',
      style: {
        position: 'sticky',
        marginRight: '-100px',
        left: '50px',
        zIndex: 2
      }
    },
    cellProps: {
      style: {
        position: 'sticky',
        background: 'var(--falcon-card-bg)',
        marginRight: '-100px',
        left: '50px'
      }
    },
    footerProps: {
      style: {
        position: 'sticky',
        marginRight: '-100px',
        left: '50px',
        zIndex: 2
      }
    },
    canResize: false,
    canSort: true,
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <FontAwesomeIcon icon="minus-circle" color="red" />
        ) : (
          <FontAwesomeIcon icon="plus-circle" color="green" />
        )}
      </span>
    )
  }
];

const budgetNameColumn = [
  {
    accessor: 'budget_name',
    Header: 'Budget Name',
    cellProps: {
      className: 'text-nowrap'
    },
    Cell: ({ cell }) => {
      const optimization = cell.row.original.optimization;
      return (
        <>
          {optimization?.active ? (
            <FontAwesomeIcon
              icon="robot"
              className={classNames([
                'me-1',
                optimization?.note == 'ok' ? 'text-success' : 'text-warning'
              ])}
            />
          ) : (
            ''
          )}
          {cell.row.original.temporary ? (
            <FontAwesomeIcon
              icon={faStopwatch}
              title="Temporary"
              className={classNames(['me-1'])}
            />
          ) : (
            <FontAwesomeIcon
              title="Recurring"
              icon="redo"
              className={classNames(['me-1'])}
            />
          )}
          {cell.value}
        </>
      );
    }
  }
];

const accountNameColumn = [
  {
    accessor: 'account_name',
    Header: 'Account Name',
    Filter: SelectBoxFilterMenu,
    cellProps: {
      className: 'text-nowrap'
    }
  }
];

const networkIconColumn = [
  {
    accessor: 'network.name',
    Header: <FontAwesomeIcon icon="globe" />,
    headerProps: {
      className: 'bg-200',
      style: {
        position: 'sticky',
        marginRight: '-100px',
        left: '100px',
        zIndex: 2
      }
    },
    cellProps: {
      style: {
        position: 'sticky',
        background: 'var(--falcon-card-bg)',
        marginRight: '-100px',
        left: '100px'
      }
    },
    footerProps: {
      style: {
        position: 'sticky',
        marginRight: '-100px',
        left: '100px',
        zIndex: 2
      }
    },
    canResize: false,
    canSort: true,
    Cell: ({ cell }) => (
      <FontAwesomeIcon
        icon={[
          cell.row.original.network?.icon_prefix,
          cell.row.original.network?.icon_name
        ]}
      />
    )
  }
];

const accountIdColumn = [
  {
    accessor: 'account_id',
    Header: 'Account ID',
    Filter: SelectBoxFilterMenu,
    Cell: ({ cell }) => (
      <Flex alignItems={'center'}>
        <FontAwesomeIcon
          icon={[
            cell.row.original.network.icon_prefix,
            cell.row.original.network.icon_name
          ]}
        />
        &nbsp;
        {cell.value}
      </Flex>
    )
  }
];

const managerColumn = [
  {
    accessor: 'manager.name',
    Header: 'Manager',
    Filter: SelectBoxFilterMenu,
    Cell: ({ cell }) => (
      <NameWithIcon
        name={cell.value}
        icon={cell.row.original.manager?.avatar_url}
      />
    )
  }
];

const projectLeadColumn = [
  {
    accessor: 'project_lead.name',
    Header: 'Project Lead',
    Filter: SelectBoxFilterMenu,
    Cell: ({ cell }) => (
      <NameWithIcon
        name={cell.value}
        icon={cell.row.original.project_lead?.avatar_url}
      />
    )
  }
];

const clientColumn = [
  {
    accessor: 'client.name',
    Header: 'Client',
    Filter: SelectBoxFilterMenu,
    headerProps: {
      className: 'bg-200',
      style: { position: 'sticky', left: '150px', zIndex: 2 }
    },
    cellProps: {
      className: 'overflow-hidden',
      style: {
        position: 'sticky',
        background: 'var(--falcon-card-bg)',
        left: '150px'
      }
    },
    footerProps: {
      style: { position: 'sticky', left: '150px', zIndex: 2 }
    },
    Cell: ({ cell }) => (
      <NameWithIcon
        name={cell.value}
        icon={cell.row.original.client?.favicon}
      />
    )
  }
];

export const activeColumns = [
  ...expanderColumn,
  ...networkIconColumn,
  ...clientColumn,
  ...accountNameColumn,
  ...budgetNameColumn,
  {
    accessor: 'start_date',
    Header: 'Start Date'
  },
  {
    accessor: 'end_date',
    Header: 'End Date',
    headerProps: {
      className: 'border-end-secondary-3'
    },
    cellProps: {
      className: 'border-end-secondary-3'
    },
    footerProps: {
      className: 'border-end-secondary-3'
    }
  },
  {
    accessor: 'cost_till_now',
    Header: 'Spend',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: (/** @type {{ page: any[]; }} */ table) =>
      React.useMemo(
        () =>
          table.page.reduce((sum, row) => +row.values.cost_till_now + sum, 0),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'expected_cost_till_now',
    Header: 'Projected',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.expected_cost_till_now + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'cost_diff',
    Header: 'Diff',
    headerProps: {
      className: 'text-end px-4'
    },
    sortType: 'basic',
    Cell: ({ cell }) => (
      <div className={`text-end pe-1 ${getDiffBackgroundColor(cell.value)}`}>
        {cell.value} %
      </div>
    )
  },
  {
    accessor: 'budget',
    Header: 'Budget',
    headerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    cellProps: {
      className: 'border-end-secondary-3 text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    Footer: table =>
      React.useMemo(
        () => table.page.reduce((sum, row) => +row.values.budget + sum, 0),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_primary',
    Header: 'Goal Primary',
    Cell: ({ cell }) => capitalize(cell.value)
  },
  {
    accessor: 'goal_primary_current_cost',
    Header: 'Current',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell, row }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_primary_current_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_primary_projected_cost',
    Header: 'Projected',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_primary_projected_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_primary_cost_diff',
    Header: 'Diff',
    headerProps: {
      className: 'text-end px-4'
    },
    sortType: 'basic',
    Cell: ({ cell }) => (
      <div className={`text-end pe-1 ${getBgColorForGoals(cell.value)}`}>
        {cell.value} %
      </div>
    )
  },
  {
    accessor: 'goal_primary_total_target',
    Header: 'Total Target',
    headerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    cellProps: {
      className: 'border-end-secondary-3 text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_primary_total_target + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_secondary',
    Header: 'Goal Secondary',
    Cell: ({ cell }) => capitalize(cell.value)
  },
  {
    accessor: 'goal_secondary_current_cost',
    Header: 'Current',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_secondary_current_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_secondary_projected_cost',
    Header: 'Projected',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_secondary_projected_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_secondary_cost_diff',
    Header: 'Diff',
    headerProps: {
      className: 'text-end px-4'
    },
    sortType: 'basic',
    Cell: ({ cell }) => (
      <div className={`text-end pe-1 ${getBgColorForGoals(cell.value)}`}>
        {cell.value} %
      </div>
    )
  },
  {
    accessor: 'goal_secondary_total_target',
    Header: 'Total Target',
    headerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    cellProps: {
      className: 'border-end-secondary-3 text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_secondary_total_target + sum,
            0
          ),
        [table.page]
      )
  },
  ...accountIdColumn,
  ...managerColumn,
  ...projectLeadColumn
];

export const endedColumns = [
  ...expanderColumn,
  ...networkIconColumn,
  ...clientColumn,
  ...accountNameColumn,
  ...budgetNameColumn,
  {
    accessor: 'start_date',
    Header: 'Start Date'
  },
  {
    accessor: 'end_date',
    Header: 'End Date',
    headerProps: {
      className: 'border-end-secondary-3'
    },
    cellProps: {
      className: 'border-end-secondary-3'
    },
    footerProps: {
      className: 'border-end-secondary-3'
    }
  },
  {
    accessor: 'cost_till_now',
    Header: 'Spend',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: (/** @type {{ page: any[]; }} */ table) =>
      React.useMemo(
        () =>
          table.page.reduce((sum, row) => +row.values.cost_till_now + sum, 0),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'expected_cost_till_now',
    Header: 'Projected',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.expected_cost_till_now + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'cost_diff',
    Header: 'Diff',
    headerProps: {
      className: 'px-4 border-end-secondary-3 text-end'
    },
    cellProps: {
      className: 'border-end-secondary-3'
    },
    footerProps: {
      className: 'border-end-secondary-3'
    },
    sortType: 'basic',
    Cell: ({ cell }) => (
      <div className={`text-end pe-1 ${getDiffBackgroundColor(cell.value)}`}>
        {cell.value} %
      </div>
    )
  },
  {
    accessor: 'goal_primary',
    Header: 'Goal Primary',
    Cell: ({ cell }) => capitalize(cell.value)
  },
  {
    accessor: 'goal_primary_current_cost',
    Header: 'Current',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_primary_current_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_primary_projected_cost',
    Header: 'Projected',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_primary_projected_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_primary_cost_diff',
    Header: 'Diff',
    headerProps: {
      className: 'px-4 border-end-secondary-3 text-end'
    },
    cellProps: {
      className: 'border-end-secondary-3'
    },
    footerProps: {
      className: 'border-end-secondary-3'
    },
    sortType: 'basic',
    Cell: ({ cell }) => (
      <div className={`text-end pe-1 ${getBgColorForGoals(cell.value)}`}>
        {cell.value} %
      </div>
    )
  },
  {
    accessor: 'goal_secondary',
    Header: 'Goal Secondary',
    Cell: ({ cell }) => capitalize(cell.value)
  },
  {
    accessor: 'goal_secondary_current_cost',
    Header: 'Current',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_secondary_current_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_secondary_projected_cost',
    Header: 'Projected',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: ({ cell }) => cell.value.toLocaleString('en-GB'),
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce(
            (sum, row) => +row.values.goal_secondary_projected_cost + sum,
            0
          ),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_secondary_cost_diff',
    Header: 'Diff',
    headerProps: {
      className: 'text-end px-4'
    },
    sortType: 'basic',
    Cell: ({ cell }) => (
      <div className={`text-end pe-1 ${getBgColorForGoals(cell.value)}`}>
        {cell.value} %
      </div>
    )
  },
  ...accountIdColumn,
  ...managerColumn,
  ...projectLeadColumn
];

export const upcomingColumns = [
  ...expanderColumn,
  ...networkIconColumn,
  ...clientColumn,
  ...accountNameColumn,
  ...budgetNameColumn,
  {
    accessor: 'start_date',
    Header: 'Start Date'
  },
  {
    accessor: 'end_date',
    Header: 'End Date',
    headerProps: {
      className: 'border-end-secondary-3'
    },
    cellProps: {
      className: 'border-end-secondary-3'
    },
    footerProps: {
      className: 'border-end-secondary-3'
    }
  },
  {
    accessor: 'days_left',
    Header: 'Days Left',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'length',
    Header: 'Length',
    headerProps: {
      className: 'text-center border-end-secondary-3'
    },
    cellProps: {
      className: 'border-end-secondary-3'
    },
    footerProps: {
      className: 'border-end-secondary-3'
    },
    Cell: ({ cell }) => (
      <div className={cell.value ? 'text-end' : 'bg-danger'}>{cell.value}</div>
    )
  },
  {
    accessor: 'invoice_id',
    Header: 'Invoice ID',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'invoice_media',
    Header: 'Inv. M',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () =>
          table.page.reduce((sum, row) => +row.values.invoice_media + sum, 0),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'invoice_fee',
    headerProps: {
      className: 'text-end'
    },
    Header: 'Inv. Fee',
    cellProps: {
      className: 'text-end'
    },
    footerProps: {
      className: 'text-end'
    },
    Footer: table =>
      React.useMemo(
        () => table.page.reduce((sum, row) => +row.values.invoice_fee + sum, 0),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'spend',
    Header: 'Spend',
    headerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    cellProps: {
      className: 'border-end-secondary-3 text-end'
    },
    footerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    Footer: table =>
      React.useMemo(
        () => table.page.reduce((sum, row) => +row.values.spend + sum, 0),
        [table.page]
      ).toLocaleString('en-GB')
  },
  {
    accessor: 'goal_primary',
    Header: 'Goal Primary',
    Cell: ({ cell }) => capitalize(cell.value)
  },
  {
    accessor: 'goal_primary_target',
    Header: 'Goal Primary Target',
    headerProps: {
      className: 'border-end-secondary-3 text-end'
    },
    cellProps: {
      className: 'border-end-secondary-3 text-end'
    },
    footerProps: {
      className: 'border-end-secondary-3'
    }
  },
  {
    accessor: 'goal_secondary',
    Header: 'Goal Secondary',
    Cell: ({ cell }) => capitalize(cell.value)
  },
  {
    accessor: 'goal_secondary_target',
    Header: 'Goal Secondary Target',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    }
  },
  {
    accessor: 'note',
    Header: 'Note'
  },
  ...accountIdColumn,
  ...managerColumn,
  ...projectLeadColumn
];
