import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
  ToggleButton,
  useAccordionButton
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { LambdaAutomationsContext } from '../LambdaAutomations';
import ScriptSettings from './ScriptSettings';
import SettingsButton from './SettingsButton';

const ScriptCard = ({ script, refetchScripts }) => {
  const { register, handleSubmit } = useForm();

  const updateParameters = (data, event) => {
    CallApi.put('/lambda_script_settings', new FormData(event.target)).then(
      json => {
        if (json.status == 200) refetchScripts();
      }
    );
  };

  const GetButton = ({ title, current_settings, status }) => {
    const decoratedClick = useAccordionButton('script-card-0');
    return (
      <Button variant="link" className="pb-0" onClick={decoratedClick}>
        <h5 className="mb-0">
          <FontAwesomeIcon
            icon={['fas', status ? 'check' : 'times']}
            className={`mx-2 text-${status ? 'success' : 'danger'}`}
            size="lg"
            title={status ? 'Active' : 'In-Active'}
          />
          <FontAwesomeIcon
            icon={['fas', current_settings == 'script' ? 'globe' : 'user-cog']}
            className={`mx-2 text-${
              current_settings == 'script' ? 'primary' : 'primary'
            }`}
            size="lg"
            title={current_settings == 'script' ? 'Global' : 'Custom'}
          />
          <span className="ms-2">{title}</span>
        </h5>
      </Button>
    );
  };

  return (
    <Accordion>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col xs={12} lg={6}>
              <Card.Title as={'h6'} className="mb-0 text-muted">
                <GetButton
                  title={script.script_title}
                  current_settings={script.settings}
                  status={script.status}
                />
              </Card.Title>
            </Col>
            {/* <Col xs={12} lg={4}>
              <SettingsButton
                current_settings={script.current_settings}
                invoker_id={script.invoker_id}
              />
            </Col> */}
            <Col lg={6} className="text-center pt-1">
              Current Interval: <b>{script.current_interval}</b>
            </Col>
          </Row>
        </Card.Header>
        <Accordion.Collapse eventKey="script-card-0">
          <Card.Body className="border-top">
            <Form
              id={`lambda-script-form-card`}
              onSubmit={handleSubmit(updateParameters)}
            >
              <Row>
                <Col xs={12} lg={5}>
                  <input
                    hidden
                    readOnly
                    name={`invoker_id`}
                    value={script.invoker_id}
                  />
                  <SettingsButton
                    refetchScripts={refetchScripts}
                    current_settings={script.settings}
                    invoker_id={script.invoker_id}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: script.script_description
                    }}
                  ></div>
                </Col>
                <Col xs={12} lg={7}>
                  <ScriptSettings
                    invokerId={script.invoker_id}
                    register={register}
                    scriptData={script}
                  />
                  <div className="text-end">
                    <IconButton
                      type="submit"
                      icon="save"
                      variant="success"
                      className="rounded-pill mt-3"
                    >
                      Update Parameters
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ScriptCard;
