export const KEYWORD_UPDATE_ACTIONS = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  LATER: 'later'
};

export const actionButtons = [
  {
    label: 'Discard',
    variant: 'outline-danger',
    activeVariant: 'danger',
    action: KEYWORD_UPDATE_ACTIONS.POSITIVE,
    value: `1`
  },
  {
    label: 'Add',
    variant: 'outline-success',
    activeVariant: 'success',
    action: KEYWORD_UPDATE_ACTIONS.NEGATIVE,
    value: `2`
  },
  {
    label: 'Skip',
    variant: 'outline-info',
    activeVariant: 'info',
    action: KEYWORD_UPDATE_ACTIONS.LATER,
    value: `0`
  }
];

export const STATUS = {
  ENABLED: 'text-success',
  DELETED: 'text-danger',
  PAUSED: 'text-warning'
};
