import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Bar } from 'react-chartjs-2';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { capitalize, getColor, rgbaColor } from 'helpers/utils';
import CallApi from 'components/common/custom-components/CallApi';

const GoalChart = ({ budgetOverviewId }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        type: 'line',
        label: 'Projected',
        borderWidth: 1,
        borderColor: getColor('primary'),
        backgroundColor: getColor('soft-primary'),
        data: [],
        pointRadius: 0
      },
      {
        type: 'line',
        label: 'Current',
        backgroundColor: getColor('soft-danger'),
        borderColor: getColor('danger'),
        borderWidth: 1,
        data: [],
        pointRadius: 0
      },
      {
        type: 'bar',
        label: 'Spend',
        borderWidth: 1,
        fill: false,
        backgroundColor: getColor('soft-warning'),
        borderColor: getColor('warning'),
        data: []
      }
    ]
  });
  const [currentGoal, setCurrentGoal] = useState('primary');

  useEffect(() => {
    CallApi.get(
      `/dashboard/${budgetOverviewId}/goal-chart?type=${currentGoal}`,
      false
    ).then(json => {
      return setData(makeData(json));
    });
  }, [currentGoal]);

  const makeData = json => {
    const labels = json.data.map(budget => budget.currentDate);

    const dailyBudget = {
      type: 'line',
      label: 'Projected',
      borderWidth: 1,
      borderColor: getColor('primary'),
      backgroundColor: getColor('soft-primary'),
      data: json.data.map(budget => budget.total_projected_cost)
    };

    const cost = {
      type: 'line',
      label: 'Current',
      backgroundColor: getColor('soft-danger'),
      borderColor: getColor('danger'),
      borderWidth: 1,
      data: json.data.map(budget => budget.total_current_cost)
    };

    const dailyCost = {
      type: 'bar',
      label: capitalize(json.column),
      borderWidth: 1,
      fill: false,
      backgroundColor: getColor('soft-warning'),
      borderColor: getColor('warning'),
      data: json.data.map(budget => budget.current_for_that_date)
    };

    return { labels: labels, datasets: [dailyBudget, cost, dailyCost] };
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: chartJsDefaultTooltip()
    },
    scales: {
      x: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      },
      y: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      }
    }
  };

  return (
    <Card className="h-100">
      <Card.Header>
        <Row>
          <Col>
            <h5 className="mt-1">Goal Chart</h5>
          </Col>
          <Col xs="auto">
            <Nav
              variant="pills"
              defaultActiveKey={currentGoal}
              className="nav-pills-falcon m-0"
            >
              <Nav.Item>
                <Nav.Link
                  as={Button}
                  onClick={() => setCurrentGoal('primary')}
                  size="sm"
                  eventKey="primary"
                >
                  Primary
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Button}
                  onClick={() => setCurrentGoal('secondary')}
                  size="sm"
                  eventKey="secondary"
                >
                  Secondary
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="pt-0">
        <Bar data={data} options={options} />
      </Card.Body>
    </Card>
  );
};

export default GoalChart;
