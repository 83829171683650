import React from 'react';
import IconButton from 'components/common/IconButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import UpsertForm from './UpsertForm';

const UpsertModal = ({ projectId = '', isEditing = false, refreshListing }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <IconButton
        icon={isEditing ? 'edit' : 'plus'}
        size="sm"
        onClick={() => setShow(true)}
      >
        {!isEditing && 'Add Project'}
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? 'Edit Project' : 'Add Project'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <UpsertForm
            closeModal={() => {
              refreshListing();
              handleClose();
            }}
            projectId={projectId}
          />
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="seo-project-form"
            type="submit"
            icon="save"
            size="sm"
          >
            {isEditing ? 'Update' : 'Save'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

UpsertModal.propTypes = {};

export default UpsertModal;
