import React, { useContext } from 'react';
import {
  Accordion,
  Card,
  Col,
  Row,
  Spinner,
  useAccordionButton
} from 'react-bootstrap';
import ScriptCard from './ScriptCard';
import { useQuery } from 'react-query';
import CallApi from 'components/common/custom-components/CallApi';
import Avatar from 'components/common/Avatar';
import { LambdaAutomationsContext } from '../LambdaAutomations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUrl from 'hooks/useUrl';
import { AidaAppContext } from 'context/Context';

const CategoryWiseScriptWrapper = () => {
  const { context } = useContext(LambdaAutomationsContext);
  const { aidaAppContext } = useContext(AidaAppContext);
  const { data, status, isFetching, isSuccess, refetch } = useQuery(
    [
      'lambdaScripts',
      aidaAppContext.client?.id,
      context.scriptCategoryId,
      context.active,
      context.scriptSettingType,
      context.refreshListing
    ],
    () =>
      CallApi.get(
        `/lambda_script_invoker/${aidaAppContext.client?.id}/scripts?status=${
          context.active ? 1 : 0
        }${
          context.scriptCategoryId
            ? `&category_id=${context.scriptCategoryId}`
            : ''
        }&settings=${context.scriptSettingType}`,
        false
      ),
    {
      placeholderData: { data: [] },
      enabled: !!aidaAppContext.client?.id
    }
  );

  let scripts = Object.keys(data?.data || {});

  return (
    <Accordion defaultActiveKey={`0`}>
      {scripts?.length > 0 && isSuccess ? (
        scripts?.map((scriptCategory, index) => (
          <Accordion.Item eventKey={`${index}`} className="mb-3" key={index}>
            <Accordion.Header>
              <Row className="align-items-center">
                <Col xs="auto">
                  <Avatar
                    className="ms-2"
                    src={
                      data?.data[`${scriptCategory}`][0].category_image_url ||
                      ''
                    }
                  />
                </Col>
                <Col xs="auto">
                  <Card.Title>{scriptCategory}</Card.Title>
                  <Card.Text>
                    {data?.data[`${scriptCategory}`][0].category_description ||
                      ''}
                  </Card.Text>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body className="bg-soft-primary">
              {data?.data[`${scriptCategory}`]?.map((script, index) => (
                <ScriptCard
                  script={script}
                  key={index}
                  refetchScripts={refetch}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))
      ) : (
        <h5 className="mt-5 text-center">
          <FontAwesomeIcon icon="cog" />{' '}
          {isFetching ? 'Loading...' : 'No Scripts Found!'}
        </h5>
      )}
    </Accordion>
  );
};

export default CategoryWiseScriptWrapper;
