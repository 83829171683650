import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import useClientSelectBox from '../custom-form-hooks/useClientSelectBox';
import useNetworkSelectBox from '../custom-form-hooks/useNetworkSelectBox';
import useRegionSelectBox from '../custom-form-hooks/useRegionSelectBox';
import useUserSelectBox from '../custom-form-hooks/useUserSelectBox';

const Create = ({
  loadListing,
  setShow,
  accountId,
  setAccountId,
  metrics,
  users,
  show,
  onHide
}) => {
  const [account, setAccount] = useState({});
  const [validated, setValidated] = useState(false);
  const [subscriptionValue, setSubscriptionValue] = useState(null);
  const NetworkSelectBox = useNetworkSelectBox();
  const PrimaryAnalystSelectBox = useUserSelectBox();
  const SecondaryAnalystSelectBox = useUserSelectBox();
  const ClientSelectBox = useClientSelectBox();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    if (accountId) {
      setIsLoadingData(true);
      CallApi.get(`/accounts/${accountId}`, false).then(json => {
        if (json.status == 200) {
          setIsLoadingData(false);
          setAccount(json.data);
          if (json.data.subscriptions) {
            setSubscriptionValue(
              json.data.subscriptions.map(subscription =>
                users.find(user => user.id == subscription.user.id)
              )
            );
          }
        }
      });
    }
  }, [accountId]);

  const onInputChange = e => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    let formData = new FormData(form);

    setIsSubmitting(true);
    if (accountId) {
      CallApi.put(`/accounts/${accountId}`, formData).then(json => {
        setIsSubmitting(false);
        if (json.status == 200) clearAndCloseModal();
      });
    } else {
      CallApi.post(`/accounts`, formData).then(json => {
        setIsSubmitting(false);
        if (json.status == 200) clearAndCloseModal();
      });
    }
  };

  const clearAndCloseModal = () => {
    loadListing();
    setAccountId('');
    setAccount({});
    setSubscriptionValue(null);
    onHide();
    setValidated(false);
    setShow(false);
  };

  const getUsersName = user => {
    if (!user.first_name) {
      return user.username;
    } else {
      return user.first_name && user.last_name
        ? user.first_name + ' ' + user.last_name
        : user.first_name;
    }
  };

  const RegionSelectBox = useRegionSelectBox();

  return (
    <Modal show={show} onHide={clearAndCloseModal}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {accountId ? (
              <>
                Edit Account
                {isLoadingData && (
                  <FontAwesomeIcon
                    icon={'spinner'}
                    className="ms-2 text-secondary"
                    spin
                  />
                )}
              </>
            ) : (
              'Add a new Account'
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>
                  Status{' '}
                  <LocalStorageTooltip identifier="form_account_field_status" />
                </Form.Label>
                <Form.Select
                  name="status"
                  value={account.status || ''}
                  onChange={e => onInputChange(e)}
                  required
                >
                  <option value="">Please select a status</option>
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                  <option value="removed">Removed</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please choose a status.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formClientId">
                <Form.Label>
                  Client{' '}
                  <LocalStorageTooltip identifier="form_account_field_client" />
                </Form.Label>
                <ClientSelectBox
                  isSearchable
                  onChange={client =>
                    setAccount({ ...account, client_id: client.id })
                  }
                  value={account.client_id}
                />
                <Form.Control.Feedback type="invalid">
                  Please select a client.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupNetwork">
                <Form.Label>
                  Network{' '}
                  <LocalStorageTooltip identifier="form_account_field_network" />
                </Form.Label>
                <NetworkSelectBox
                  isSearchable
                  onChange={network =>
                    setAccount({ ...account, network_id: network.id })
                  }
                  value={account.network_id}
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a network.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>
                  Account ID{' '}
                  <LocalStorageTooltip identifier="form_account_field_account_id" />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={`${account?.account_id || ''}`}
                  name="account_id"
                  onChange={e => onInputChange(e)}
                  placeholder="Enter Account ID Eg. 2130123483"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>
                  Account Name{' '}
                  <LocalStorageTooltip identifier="form_account_field_account_name" />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={account.account_name || ''}
                  name="account_name"
                  onChange={e => onInputChange(e)}
                  placeholder="Enter Account Name"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>
                  Quick link to account{' '}
                  <LocalStorageTooltip identifier="form_account_field_quick_link_to_account" />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={account.quick_link_to_account || ''}
                  name="quick_link_to_account"
                  onChange={e => onInputChange(e)}
                  placeholder="Enter link"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="website-url">
                <Form.Label>
                  Website Url{' '}
                  <LocalStorageTooltip identifier="form_account_field_website_url" />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={account.website_url || ''}
                  name="website_url"
                  onChange={e => onInputChange(e)}
                  placeholder="Enter website url for this account."
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="currency">
                <Form.Label>
                  Currency
                  <LocalStorageTooltip identifier="form_account_field_currency" />
                </Form.Label>
                <Form.Control
                  type="text"
                  max="3"
                  value={account?.currency || ''}
                  name="currency"
                  onChange={e => onInputChange(e)}
                  placeholder="Enter currency"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="primary-analyst">
                <Form.Label>
                  Primary Analyst{' '}
                  <LocalStorageTooltip identifier="form_account_field_primary_analyst" />
                </Form.Label>
                <PrimaryAnalystSelectBox
                  name="primary_user_id"
                  value={account.primary_user_id}
                  onChange={user =>
                    setAccount({ ...account, primary_user_id: user.id })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a primary Analyst.
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>
                  Subscriber(s){' '}
                  <LocalStorageTooltip identifier="form_account_field_subscribers" />
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={users}
                  placeholder="Select Subscribers"
                  isMulti
                  value={subscriptionValue}
                  getOptionValue={user => user.id}
                  getOptionLabel={user => getUsersName(user)}
                  onChange={value => setSubscriptionValue(value)}
                  name="subscribers[]"
                  classNamePrefix="react-select"
                />
                <Form.Control.Feedback type="invalid">Please choose a secondary metric.</Form.Control.Feedback>
              </Form.Group> */}
            </Col>
            {/* <Col xs={12} lg={6}>
              <Row className="align-items-center">
                <Col>
                  <h4>
                    Competitors{' '}
                    <LocalStorageTooltip
                      className="fs-1"
                      identifier="form_account_field_competitors"
                    />
                  </h4>
                </Col>
                <Col xs="auto">
                  <Link
                    to={`/competitor-analysis?domain=${ExtractHostname(
                      account?.website_url || ''
                    )}`}
                    target="_blank"
                  >
                    <IconButton
                      size="sm"
                      variant="link"
                      icon={'link'}
                      className="pb-2"
                    >
                      Find Competitors
                    </IconButton>
                  </Link>
                </Col>
              </Row>
              <hr />
              {[1, 2, 3, 4, 5].map(i => (
                <Row className="mb-2" key={i}>
                  <Col lg={12}>
                    <Form.Label>Competitor {i}</Form.Label>
                  </Col>
                  <Col lg={6}>
                    <Form.Control
                      type="text"
                      name={`account_competitors[${i}][name]`}
                      value={account?.accountCompetitors?.[i]?.name || ''}
                      onChange={e =>
                        setAccount({
                          ...account,
                          accountCompetitors: {
                            ...account?.accountCompetitors,
                            [i]: {
                              ...account?.accountCompetitors?.[i],
                              name: e.target.value
                            }
                          }
                        })
                      }
                      placeholder="Name"
                      required={i == 1 || i == 2}
                    />
                  </Col>
                  <Col lg={6}>
                    <Form.Control
                      type="text"
                      name={`account_competitors[${i}][url]`}
                      value={account?.accountCompetitors?.[i]?.url || ''}
                      onChange={e =>
                        setAccount({
                          ...account,
                          accountCompetitors: {
                            ...account?.accountCompetitors,
                            [i]: {
                              ...account?.accountCompetitors?.[i],
                              url: e.target.value
                            }
                          }
                        })
                      }
                      placeholder="Url"
                      required={i == 1 || i == 2}
                    />
                  </Col>
                </Row>
              ))}
            </Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            variant="muted"
            onClick={() => setShow(false)}
          >
            Close
          </IconButton>
          <IconButton
            icon={isSubmitting ? 'spinner' : 'save'}
            pulse={isSubmitting}
            variant="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </IconButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Create;
