import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { AidaAppContext } from 'context/Context';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import CallApi from 'components/common/custom-components/CallApi';
import { getItemFromStore, setItemToStore } from 'helpers/utils';

/**
 * Pre-Adding fa icons in the app
 * Check for more info: https://fontawesome.com/v5/docs/web/use-with/react#using-icons
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserCheck,
  faUserTimes,
  faFile
} from '@fortawesome/free-solid-svg-icons';
library.add(faUserCheck, faUserTimes, faFile);

const App = () => {
  const [aidaAppContext, setAidaAppContext] = useState({
    client: {},
    refreshClientSelectBox: true,
    user_permissions: {},
    refetchPermissions: true
  });

  useEffect(() => {
    if (getItemFromStore('aidaToken')) {
      CallApi.get('/tooltip', false).then(json => {
        setItemToStore('tooltips', json.data);
      });
      CallApi.get('/app-access-permissions', false).then(json => {
        setAidaAppContext({ ...aidaAppContext, user_permissions: json.data });
      });
    }
  }, [aidaAppContext.refetchPermissions]);

  return (
    <AidaAppContext.Provider value={{ aidaAppContext, setAidaAppContext }}>
      <Router basename={process.env.PUBLIC_URL}>
        <QueryClientProvider
          client={
            new QueryClient({
              defaultOptions: {
                queries: {
                  refetchOnWindowFocus: false
                }
              }
            })
          }
        >
          <Layout />
          {process.env.REACT_APP_API_URL.includes('.test') && (
            <ReactQueryDevtools
              toggleButtonProps={{ className: 'ms-4 mb-4' }}
            />
          )}
        </QueryClientProvider>
      </Router>
    </AidaAppContext.Provider>
  );
};

export default App;
