import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';
import UpsertModal from './UpsertModal';

const Listing = () => {
  const [data, setData] = useState([]);
  const [apiCallInProgress, setApiCallInProgress] = useState(true);
  const [pagePermissions, setPagePermissions] = useState({ can: {} });

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = () =>
    CallApi.get('/seo-project', apiCallInProgress).then(json => {
      if (json.status == 200) {
        setData(json.data);
        setApiCallInProgress(false);
        setPagePermissions(json.pagePermissions);
      }
    });

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: { className: 'text-center' },
      Cell: ({ cell }) => (
        <>
          {pagePermissions.can.update && (
            <UpsertModal
              projectId={cell.value}
              isEditing={true}
              refreshListing={fetchProjects}
            />
          )}
          {pagePermissions.can.delete && (
            <IconButton
              icon="trash-alt"
              className="ms-1"
              variant="danger"
              size="sm"
              onClick={() =>
                confirm('Do you really want to delete this project?') &&
                CallApi.delete('/seo-project/' + cell.value).then(json => {
                  if (json.status == 200) fetchProjects();
                })
              }
            />
          )}
        </>
      )
    },
    { accessor: 'name', Header: 'Name' },
    {
      accessor: 'client.name',
      Header: 'Client',
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.client?.favicon}
        />
      )
    },
    {
      accessor: 'active',
      Header: 'Status',
      Cell: ({ cell }) => {
        return cell.value ? (
          <div className="text-success text-center">
            <FontAwesomeIcon icon={['fas', 'check']} size="lg" title="Active" />
          </div>
        ) : (
          <div className="text-danger text-center">
            <FontAwesomeIcon
              icon={['fas', 'times']}
              size="lg"
              title="In-Active"
            />
          </div>
        );
      }
    },
    { accessor: 'domain', Header: 'Domain' },
    { accessor: 'region.name', Header: 'Region' },
    {
      accessor: 'keyword_download_frequency',
      Header: 'Keyword Download Frequency'
    },
    {
      accessor: 'page_check_frequency',
      Header: 'Page Check Frequency'
    },
    {
      accessor: 'page_check_device',
      Header: 'Page Check Device'
    },
    {
      accessor: 'primary_user.name',
      Header: 'Primary User',
      Cell: ({ cell }) => (
        <NameWithIcon
          icon={cell.row.original.primary_user.avatar_url}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'secondary_user.name',
      Header: 'Secondary User',
      Cell: ({ cell }) => (
        <NameWithIcon
          icon={cell.row.original.secondary_user.avatar_url}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'note',
      Header: 'Note'
    }
  ];

  return (
    <Card>
      <Card.Body>
        <AdvanceTableWrapper columns={columns} data={data} sortable pagination>
          <Row className="align-items-end g-2 row mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col />
            <Col xs="auto">
              {pagePermissions.can.create && (
                <UpsertModal refreshListing={fetchProjects} />
              )}
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={apiCallInProgress}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default Listing;
