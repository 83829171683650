import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card } from 'react-bootstrap';
import FlexAbsenceForm from './FlexAbsenceForm';

function FlexAbsenceCreate() {
  return (
    <Card>
      <Card.Header className="border-bottom">
        <h5>Add Entry</h5>
      </Card.Header>
      <Card.Body>
        <FlexAbsenceForm />
      </Card.Body>
      <Card.Footer className="border-top text-end">
        <IconButton
          icon="check"
          size="sm"
          form="add-flex-absence-entry"
          type="submit"
        >
          Submit
        </IconButton>
      </Card.Footer>
    </Card>
  );
}

export default FlexAbsenceCreate;
