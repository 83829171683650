import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listing } from 'components/aida/avaza/AvazaTimerows';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { capitalize, roundToTwoDecimals } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AvazaWidget = ({ clientId }) => {
  const [data, setData] = useState([]);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);

  useEffect(() => {
    loadListing();
  }, []);

  const loadListing = () =>
    CallApi.get(
      '/avaza/dashboard/client-overview-logs?client_id=' + clientId,
      false
    ).then(json => {
      if (json.status == 200) {
        setData(json.data);
        setApiCallInProcess(false);
      }
    });

  const columns = [
    {
      accessor: 'user.first_name',
      Header: 'User',
      Cell: ({ cell }) => (
        <Avatar
          name={cell.value}
          src={
            cell.row.original.user.our_user
              ? cell.row.original.user.our_user.avatar_url
              : ''
          }
          rounded="circle"
          size="l"
        />
      )
    },
    {
      accessor: 'date',
      Header: 'Date',
      headerProps: { className: 'text-center' },
      Cell: ({ cell: { value }, row }) => (
        <Link
          to={`//digipartner.avaza.com/timesheet/day/${row.original.entry_date}?userID=${row.original.avaza_user_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </Link>
      )
    },
    {
      accessor: 'time',
      Header: 'Time',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' h',
      Footer: info => {
        const total = React.useMemo(
          () =>
            roundToTwoDecimals(
              info.page.reduce((sum, row) => row.values.time + sum, 0)
            ),
          [info.page]
        );
        return <div className="text-end">{total} h</div>;
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      Cell: ({ cell }) => {
        let bgColor = '';
        switch (cell.value) {
          case 'Debiterbart':
            bgColor = 'primary';
            break;

          case 'Ö - Extra':
            bgColor = 'warning';
            break;

          case 'Löpande':
            return <SoftBadge bg="warning">{cell.value}</SoftBadge>;
            break;

          case 'uppstart':
            return <SoftBadge bg="info">{capitalize(cell.value)}</SoftBadge>;
            break;

          default:
            bgColor = 'secondary';
            break;
        }
        return <Badge bg={bgColor}>{capitalize(cell.value)}</Badge>;
      }
    },
    {
      accessor: 'task',
      Header: 'Task'
    },
    {
      accessor: 'note',
      Header: 'Note'
    }
  ];

  return (
    <Card className="h-100">
      <FalconCardHeader
        className="mt-1"
        title="Avaza Info"
        endEl={
          <Link to={'/aida/avaza/client-overview?client_id=' + clientId}>
            <IconButton size="sm" variant="link" icon={'link'} className="pb-2">
              Show All
            </IconButton>
          </Link>
        }
      />
      <Card.Body className="pt-0">
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          pagination
          perPage={5}
        >
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden',
              size: 'sm'
            }}
            apiCallInProcess={apiCallInProcess}
            tableFooterClassName="table-secondary"
          />
          <AdvanceTableFooter
            table
            className="mt-3 mx-3"
            rowCount={data.length}
            rowInfo
            navButtons
          />
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default AvazaWidget;
