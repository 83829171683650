import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Button, Row, Col, Modal, Form, Card } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Create from './Create';
import { AppSettings } from '../../../settings';
import { Link } from 'react-router-dom';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';

const Competitors = () => {
  const [competitors, setCompetitors] = useState([]);
  const [show, setShow] = useState(false);
  const [competitorId, setCompetitorId] = useState('');
  const [apiCallInProcess, setApiCallInProcess] = useState(false);

  const columns = [
    {
      accessor: 'name',
      Header: 'Name'
    },
    {
      accessor: 'url',
      Header: 'Url',
      Cell: ({ cell }) => {
        let url = cell.row.values.url;
        return (
          <>
            <Link
              to={{ pathname: url }}
              className="d-block link-primary"
              key="primary"
              target="_blank"
            >
              {url}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'description',
      Header: 'Description'
    },
    {
      accessor: 'id',
      Header: 'Action',
      Cell: ({ cell }) => {
        return (
          <>
            <IconButton
              icon="trash"
              size="sm"
              className="me-2"
              onClick={() => deleteCompetitor(cell.row.values.id)}
            ></IconButton>
            <IconButton
              icon="edit"
              size="sm"
              onClick={() => updateCompetitor(cell.row.values.id)}
            ></IconButton>
          </>
        );
      }
    }
  ];

  function deleteCompetitor(id) {
    var result = confirm("Attention! Are you sure you'd like to delete?");
    if (!result) {
      return;
    }
    CallApi.delete(`/competitors/${id}`).then(record => {
      if (record) loadListing();
    });
  }

  function updateCompetitor(id) {
    setCompetitorId(id);
    setShow(true);
  }

  function loadListing() {
    setApiCallInProcess(true);
    CallApi.get(`/competitors`).then(data => {
      setApiCallInProcess(false);
      if (data.status == 200) {
        setCompetitors(data.data);
      }
    });
  }

  useEffect(() => {
    loadListing();
  }, []);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setCompetitorId('');
    setShow(false);
  };

  return (
    <>
      <PageInfo slug="competitors" />
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={competitors}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col className="col-auto" xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
              <Col className="col">
                <IconButton
                  icon="plus"
                  size="sm"
                  className="float-end"
                  onClick={handleShow}
                >
                  Add New
                </IconButton>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProcess}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={competitors.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add a new Competitor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Create
            loadListing={loadListing}
            setShow={setShow}
            competitorId={competitorId}
            setCompetitorId={setCompetitorId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Competitors;
