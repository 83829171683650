import React, { useContext, useEffect, useState } from 'react';
import { Badge, Table, Toast, ToastContainer } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Row, Col, Modal, Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Create from './Create';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import ClientColorBadge from './ClientColorBadge';
import { AidaAppContext } from 'context/Context';
import { Link } from 'react-router-dom';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [show, setShow] = useState(false);
  const [clientId, setClientId] = useState('');
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const { aidaAppContext, setAidaAppContext } = useContext(AidaAppContext);
  const [pagePermissions, setPagePermissions] = useState({ can: {} });

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => {
        return (
          <>
            <IconButton
              icon="edit"
              size="sm"
              onClick={() => updateClient(cell.row.values.id)}
              className="me-2"
            ></IconButton>
            <IconButton
              icon="trash-alt"
              variant="danger"
              size="sm"
              onClick={() => {
                deleteClient(cell.row.values.id);
                setAidaAppContext({
                  ...aidaAppContext,
                  refreshClientSelectBox: !aidaAppContext.refreshClientSelectBox
                });
              }}
            ></IconButton>
          </>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ cell }) => (
        <Link to={`clients/overview?client_id=${cell.row.original.id}`}>
          <NameWithIcon name={cell.value} icon={cell.row.original.favicon} />
        </Link>
      )
    },
    {
      accessor: 'color',
      Header: 'Color',
      Cell: ({ cell }) => <ClientColorBadge color={cell.value} />
    },
    {
      accessor: 'status',
      Header: 'Status'
    },
    {
      accessor: 'type',
      Header: 'Analytics'
    },
    {
      accessor: 'property_id',
      Header: 'Property ID'
    },
    {
      accessor: 'view_id',
      Header: 'View ID'
    },
    {
      accessor: 'client_note',
      Header: 'Client Note'
    },
    {
      accessor: 'ecom',
      Header: 'Client Type'
    },
    {
      accessor: 'avaza_id',
      Header: 'Avaza Id'
    }
  ];

  function deleteClient(id) {
    var result = confirm("Attention! Are you sure you'd like to delete?");
    if (!result) {
      return;
    }
    CallApi.delete(`/clients/${id}`).then(json => {
      if (json.status == 200) loadListing();
    });
  }

  function updateClient(id) {
    setClientId(id);
    setShow(true);
  }

  function loadListing() {
    setApiCallInProcess(true);
    CallApi.get(`/clients`).then(data => {
      setApiCallInProcess(false);
      if (data.status == 200) {
        setClients(data.data);
        setPagePermissions(data.pagePermissions);
      }
    });
  }

  const getUsersName = user => {
    if (!user.first_name) {
      return user.username;
    } else {
      return user.first_name && user.last_name
        ? user.first_name + ' ' + user.last_name
        : user.first_name;
    }
  };

  useEffect(() => {
    loadListing();
  }, []);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setClientId('');
    setShow(false);
  };

  return (
    <>
      <PageInfo slug="clients" />
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={clients}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col className="col-auto" xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
              <Col className="col">
                {pagePermissions.can.create && (
                  <IconButton
                    icon="plus"
                    size="sm"
                    className="float-end"
                    onClick={handleShow}
                  >
                    Add New
                  </IconButton>
                )}
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProcess}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={clients.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>

      <Create
        loadListing={loadListing}
        setShow={setShow}
        clientId={clientId}
        setClientId={setClientId}
        show={show}
        onHide={handleHide}
      />
    </>
  );
};

export default Clients;
