import IconButton from 'components/common/IconButton';
import React from 'react';
import UpsertModal from '../notes-components/UpsertModal';
import { useHistory } from 'react-router-dom';

const OverviewHero = ({ report }) => {
  const history = useHistory();
  return (
    <div className="d-flex align-items-center justify-content-between">
      <IconButton
        icon="arrow-left"
        variant="falcon-link"
        size="sm"
        onClick={() => history.push('/aida/reports')}
      >
        Back
      </IconButton>
      <span>
        <strong>Client: </strong>
        <img
          className="mx-1"
          src={report?.client?.favicon}
          width="30px"
          height="30px"
        ></img>
        {report?.client?.name}
      </span>
      <span>
        <strong>Project Lead: </strong>
        <img
          className="mx-1 rounded-circle"
          src={report?.project_lead?.avatar_url}
          width="30px"
          height="30px"
        ></img>
        {report?.project_lead?.name}
      </span>
      <span>
        <strong>Analyst: </strong>
        <img
          className="mx-1 rounded-circle"
          src={report?.analyst?.avatar_url}
          width="30px"
          height="30px"
        ></img>
        {report?.analyst?.name}
      </span>
      <strong>
        <a href={report?.data_studio_link} target="_blank" rel="noreferrer">
          Show in Datastudio
        </a>
      </strong>
      <UpsertModal reportId={report?.id} />
    </div>
  );
};

export default OverviewHero;
