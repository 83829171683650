import CallApi from 'components/common/custom-components/CallApi';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { AidaAppContext } from 'context/Context';
import React, { useContext, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { LambdaAutomationsContext } from '../LambdaAutomations';

const CategoryChanger = () => {
  const [category, setCategory] = useState(null);
  const getCategories = () => CallApi.get('/lambda_script_categories');
  const { data } = useQuery('lambda_script_categories', getCategories, {
    placeholderData: { data: [] }
  });
  const [type, setType] = useState('');

  const { context, setContext } = useContext(LambdaAutomationsContext);
  const { aidaAppContext } = useContext(AidaAppContext);

  return (
    <Row>
      <Col xs={12} md={6}>
        <Flex alignItems={'center'} className="ps-3">
          <img src={aidaAppContext.client?.favicon} width="30" />
          <h4 className="ms-1 mb-0">{aidaAppContext.client?.name || ''}</h4>
        </Flex>
      </Col>
      <Col xs={6}>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column xs="3" sm="4" lg="4">
                Category
              </Form.Label>
              <Col>
                <Select
                  name="account_id"
                  options={data?.data?.map((category, index) => {
                    return { value: category.id, label: category.title };
                  })}
                  onChange={category => {
                    setCategory(category);
                    setContext({
                      ...context,
                      scriptCategoryId: category ? category.value : category
                    });
                  }}
                  value={category}
                  isClearable
                  classNamePrefix="react-select"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column xs="3" sm="4" lg="4">
                Setting
              </Form.Label>
              <Col>
                <Select
                  name="account_id"
                  options={[
                    { value: '', label: 'All' },
                    { value: 'script', label: 'Global' },
                    { value: 'client', label: 'Custom' }
                  ]}
                  value={type}
                  onChange={type => {
                    setType(type);
                    setContext({
                      ...context,
                      scriptSettingType: type ? type.value : type
                    });
                  }}
                  classNamePrefix="react-select"
                />
              </Col>
            </Form.Group>
          </Col>
          {/* <Col xs={6} lg={2}>
        <Form.Group as={Row} className="mb-3">
        <Form.Label column xs="4">
            Active
            </Form.Label>
            <Col xs="8">
            <Form.Check
            type="switch"
            name="Active"
            className="pt-1"
              onChange={e => {
                setContext({ ...context, active: !active });
                setActive(!active);
              }}
              checked={active}
              />
              </Col>
              </Form.Group>
            </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

export default CategoryChanger;
