import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import UpsertBudgetOverview from './components/UpsertBudgetOverview';
import { ImportModal } from './components/ImportModal';
import BudgetOverviewListing from './components/Listing';
import PageInfo from 'components/common/custom-components/PageInfo';
import useUrl from 'hooks/useUrl';

function BudgetOverviews() {
  const [showAddModal, setshowAddModal] = useState(false);
  const [showImportModal, setshowImportModal] = useState(false);
  const [currentBudgetOverviewId, setCurrentBudgetOverviewId] = useState(null);
  const [refreshListing, setRefreshListing] = useState(true);
  const query = useUrl();

  useEffect(() => {
    if (query.get('edit')) {
      editBudgetOverview(query.get('edit'));
    }
  }, []);

  const editBudgetOverview = id => {
    setCurrentBudgetOverviewId(id);
    setshowAddModal(true);
  };

  return (
    <>
      <PageInfo slug="budget_overviews" />
      <Card>
        <Card.Body>
          <BudgetOverviewListing
            setshowImportModal={setshowImportModal}
            setshowAddModal={setshowAddModal}
            editBudgetOverview={editBudgetOverview}
            refreshListing={refreshListing}
          />
        </Card.Body>
      </Card>
      <UpsertBudgetOverview
        showModal={showAddModal}
        setShowModal={setshowAddModal}
        currentBudgetOverviewId={currentBudgetOverviewId}
        setCurrentBudgetOverviewId={setCurrentBudgetOverviewId}
        loadlisting={() => setRefreshListing(!refreshListing)}
      />
      <ImportModal
        showImportModal={showImportModal}
        setshowImportModal={setshowImportModal}
        loadlisting={() => setRefreshListing(!refreshListing)}
      />
    </>
  );
}

export default BudgetOverviews;
