import FalconCardHeader from 'components/common/FalconCardHeader';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

const ChartWidget = ({
  className = '',
  chartLabels = [],
  cardTitle,
  chartDataCallback = () => [],
  ...rest
}) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        type: 'line',
        label: 'label',
        borderWidth: 1,
        backgroundColor: '#e6effc',
        borderColor: '#2c7be5',
        data: []
      },
      {
        type: 'line',
        label: 'label',
        borderWidth: 1,
        backgroundColor: '#e0faef',
        borderColor: '#00d27a',
        data: []
      },
      {
        type: 'line',
        label: 'label',
        borderWidth: 1,
        backgroundColor: '#fce7eb',
        borderColor: '#e63757',
        data: []
      }
    ]
  });
  const ChartRef = useRef();

  useEffect(() => {
    setData({
      labels: chartLabels,
      datasets: chartDataCallback()
    });
    ChartRef.current?.update();
  }, [chartLabels]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: chartJsDefaultTooltip()
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      },
      y: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      }
    }
  };
  return (
    <Card className={`h-100 flex-fill ${className}`} {...rest}>
      <FalconCardHeader className="mt-1" title={cardTitle} />
      <Card.Body className="pt-0" style={{ height: '300px' }}>
        <Line data={data} options={options} ref={ChartRef} />
      </Card.Body>
    </Card>
  );
};

export default ChartWidget;
