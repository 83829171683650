import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { snakeCase } from 'lodash';
import React, { useState } from 'react';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

const AccountSettingsModal = ({ accountId, children, ...rest }) => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const defaultValues = {
    budget_optimization_active: null,
    budget_optimization_fixed_part: 0,
    budget_optimization_clicks: 1,
    budget_optimization_conversion_values: 1,
    budget_optimization_conversions: 1,
    budget_optimization_impressions: 1,
    budget_optimization_frequency_days: null
  };

  const { handleSubmit, control, register, watch, setValue, reset } = useForm({
    defaultValues: defaultValues
  });

  const handleClose = () => {
    reset(defaultValues);
    setErrors({});
    setShow(false);
  };

  const UpdateOptimization = useMutation(
    form =>
      CallApi.post(
        `/account/${accountId}/budget-settings`,
        new FormData(form.target),
        true,
        false
      ),
    {
      onSuccess: response => {
        if (response.status == 200) {
          handleClose();
        } else {
          setErrors(response.errors);
        }
      }
    }
  );

  const submitButtonIcon = UpdateOptimization.isLoading ? 'spinner' : 'save';

  const submitButtonVariant = UpdateOptimization.isError
    ? 'warning'
    : 'primary';

  const onSubmit = (data, form) => {
    UpdateOptimization.mutate(form);
  };

  const AccountBudgetSettingsQuery = useQuery(
    ['account-budget-settings', accountId],
    () => CallApi.get(`/account/${accountId}/budget-settings`),
    {
      onSuccess: json => {
        reset(json);
        // Object.keys(defaultValues).forEach(field => {
        //   setValue(field, json.data[field]);
        // });
      },
      enabled: !!(show && accountId)
    }
  );

  const optimizationAgainstFields = [
    'Conversion Values',
    'Conversions',
    'Clicks',
    'Impressions'
  ];

  return (
    <>
      <Button
        variant="link"
        className="p-0"
        size="sm"
        {...rest}
        onClick={() => setShow(true)}
      >
        {children}
      </Button>

      <Modal show={show} centered onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={'cog'} /> Account Settings{' '}
            {isFetching ? (
              <FontAwesomeIcon icon={'spinner'} pulse size="sm" />
            ) : (
              ''
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form
            id="account-settings-modal-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label htmlFor="active">
                  Active
                  <LocalStorageTooltip
                    className="ms-1"
                    identifier={
                      'form_account_settings_field_budget_optimization_active'
                    }
                  />
                </Form.Label>
                <Form.Select
                  disabled={isFetching}
                  {...register('budget_optimization_active', {
                    required: true
                  })}
                  isInvalid={errors?.budget_optimization_active}
                  id="active"
                >
                  <option value="">Please select status</option>
                  <option value={1}>Active</option>
                  <option value={0}>In-Active</option>
                </Form.Select>
              </Form.Group>
              {/* <h5 className="fw-semi-bold text-800 mb-2">Budget Settings:</h5> */}
              {optimizationAgainstFields.map(field => {
                const snakeCasedField =
                  'budget_optimization_' + snakeCase(field);
                return (
                  <Row key={snakeCasedField}>
                    <Col xs={5}>
                      <Form.Label
                        htmlFor={`field_${snakeCasedField}`}
                        className={
                          errors?.[snakeCasedField] ? 'is-invalid' : ''
                        }
                      >
                        {field}
                      </Form.Label>
                      <Form.Control.Feedback className="mb-2" type="invalid">
                        {errors?.[snakeCasedField] &&
                          errors?.[snakeCasedField].map(error => error)}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={7}>
                      <Flex>
                        <Form.Range
                          id={`field_${snakeCasedField}`}
                          {...register(snakeCasedField)}
                          min="1"
                          defaultValue={1}
                          max="6"
                          disabled={isFetching}
                          className="me-3"
                          step="1"
                        />
                        <h5>
                          <Badge pill style={{ minWidth: `30px` }}>
                            {watch(snakeCasedField) || 0}
                          </Badge>
                        </h5>
                      </Flex>
                    </Col>
                  </Row>
                );
              })}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="field_fixed_part">
                Fixed part
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={'form_account_settings_field_fixed_part'}
                />
              </Form.Label>
              <Flex>
                <Form.Range
                  id={`field_fixed_part`}
                  disabled={isFetching}
                  {...register('budget_optimization_fixed_part', {
                    min: 0,
                    max: 100
                  })}
                  min="0"
                  max="100"
                  className="me-3"
                  step="1"
                />
                <h5 style={{ minWidth: `50px` }}>
                  {watch('budget_optimization_fixed_part')}%
                </h5>
              </Flex>
              <Form.Control.Feedback type="invalid">
                {errors?.budget_optimization_fixed_part &&
                  errors?.budget_optimization_fixed_part.map(error => error)}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="budget_optimization_frequency_days">
                frequency (days)
                <LocalStorageTooltip
                  className="ms-1"
                  identifier={
                    'form_account_settings_field_budget_optimization_frequency_days'
                  }
                />
              </Form.Label>
              <Form.Control
                disabled={isFetching}
                type="number"
                {...register('budget_optimization_frequency_days', {
                  required: true
                })}
                id="budget_optimization_frequency_days"
                isInvalid={errors?.budget_optimization_frequency_days}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.budget_optimization_frequency_days &&
                  errors?.budget_optimization_frequency_days.map(
                    error => error
                  )}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
          {/* <DevTool control={control} /> */}
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="account-settings-modal-form"
            type="submit"
            icon={submitButtonIcon}
            spin={UpdateOptimization.isLoading}
            variant={submitButtonVariant}
            disabled={UpdateOptimization.isLoading}
            size="sm"
          >
            Update
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AccountSettingsModal.propTypes = {};

export default AccountSettingsModal;
