import React from 'react';
import IconButton from 'components/common/IconButton';
import { Form, Modal, Nav } from 'react-bootstrap';
import { useState } from 'react';
import { map } from 'lodash';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import ReactSelect from 'react-select';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const AccountSelectionModal = ({ availableAccounts }) => {
  const history = useHistory();
  const accountLinks = availableAccounts
    .filter(account => account.network == 'google-ads')
    .map(account =>
      account.children.filter(pageLink => pageLink.page == 'keywords-selection')
    )
    .map(link => link[0]);

  return (
    <Modal show centered>
      <Modal.Header className="flex-column">
        <Modal.Title>
          <FontAwesomeIcon icon={faGoogle} /> Choose account
        </Modal.Title>
        <Form.Text muted>
          Multiple google ads accounts found for this client, please choose one
        </Form.Text>
      </Modal.Header>
      <Modal.Body className="px-3">
        <Nav className="flex-column my-3 list-group" as="ul">
          <ReactSelect
            options={accountLinks}
            onChange={link => history.push(link.to)}
            getOptionLabel={link =>
              `${link.name} - ${link.account_id} ${
                link.account_name ? `- ${link.account_name}` : ''
              }`
            }
            isSearchable
          />
        </Nav>
      </Modal.Body>
    </Modal>
  );
};

export default AccountSelectionModal;
