import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import FalconCardHeader from 'components/common/FalconCardHeader';
import emoji from 'emoji-mart/dist-es/components/emoji/emoji';
import React, { useEffect, useState } from 'react';
import { Dropdown, Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RecentTasksDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (isOpen) {
      CallApi.get('/task/user?limit=3', false).then(json =>
        json.status == 200 ? setTasks(json.data) : ''
      );
    }
  }, [isOpen]);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown navbar={true} as="li" onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link')}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header border-bottom"
            title="Recent Tasks"
            titleTag="h6"
            light={false}
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '25rem' }}
          >
            {tasks.map(task => (
              <ListGroup.Item key={task.id} onClick={handleToggle}>
                <Link
                  className={classNames('notification', 'notification-flush')}
                  to="#!"
                >
                  <div className="notification-avatar">
                    <Avatar
                      src={
                        task.level == 'User'
                          ? task.user.avatar_url
                          : task.client.favicon
                      }
                      className="me-3"
                    />
                  </div>
                  <div className="notification-body">
                    <p className="mb-1">{task.name}</p>
                    <span className="notification-time">
                      {emoji && (
                        <span className="me-2" role="img" aria-label="Emoji">
                          {emoji}
                        </span>
                      )}
                      {task.created_at}
                    </span>
                  </div>
                </Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/aida/tasks">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RecentTasksDropdown;
