import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useContext, useEffect, useState } from 'react';
import { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ReportOverviewPageContext } from '../report-overview/ReportOverview';

const UpsertForm = ({ closeModal, reportId, reportNoteId }) => {
  const { reportOverviewContext, setReportOverviewContext } = useContext(
    ReportOverviewPageContext
  );
  const [errors, setErrors] = useState({});
  const { handleSubmit, register, setValue, control, reset } = useForm();
  const [date, setDate] = useState(new Date());

  const onSubmit = (data, form) => {
    if (reportNoteId) {
      CallApi.put(
        '/report-note/' + reportNoteId,
        new FormData(form.target),
        true,
        false
      ).then(json => {
        if (json.status == 422) {
          toast.warning(json.message);
          setErrors(json.errors);
        } else {
          refreshAndClose();
        }
      });
    } else {
      CallApi.post('/report-note', new FormData(form.target), true, false).then(
        json => {
          if (json.status == 422) {
            toast.warning(json.message);
            setErrors(json.errors);
          } else {
            refreshAndClose();
          }
        }
      );
    }
  };

  const refreshAndClose = () => {
    setReportOverviewContext({
      ...reportOverviewContext,
      refreshNotes: !reportOverviewContext.refreshNotes
    });
    closeModal();
  };

  const editReportNote = report_note_id => {
    CallApi.get('/report-note/' + report_note_id).then(json => {
      setValue('title', json.data.title);
      setValue('text', json.data.text);
      setValue('digipartner_note', json.data.digipartner_note);
      setValue('show_customer', json.data.show_customer ? '1' : '0');
      setValue('approved', json.data.approved ? '1' : '0');
      setDate(new Date(json.data.visible_date));
    });
  };

  useEffect(() => {
    if (reportNoteId) editReportNote(reportNoteId);
  }, []);

  return (
    <>
      <Form noValidate id="report-note-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Control type="hidden" name="report_id" value={reportId} />
        <Form.Group className="mb-3">
          <Form.Label>
            Visible Date{' '}
            <LocalStorageTooltip
              identifier={`form_report_note_field_visible_date`}
            />
          </Form.Label>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={date}
            onChange={date => setDate(date)}
            name={'visible_date'}
            className="form-control pb-0 pt-1"
            placeholderText="Select Date"
            calendarStartDay={1}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please select a visible date.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Approved?{' '}
            <LocalStorageTooltip
              identifier={`form_report_note_field_approved`}
            />
          </Form.Label>
          <Form.Select {...register('approved')} isInvalid={errors.approved}>
            <option value="0">Unapproved</option>
            <option value="1">Approved</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.approved && errors.approved.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Show Customer?{' '}
            <LocalStorageTooltip
              identifier={`form_report_note_field_show_customer`}
            />
          </Form.Label>
          <Form.Select
            {...register('show_customer')}
            isInvalid={errors.show_customer}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.show_customer && errors.show_customer.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBas icDescription">
          <Form.Label>
            Title{' '}
            <LocalStorageTooltip identifier={`form_report_note_field_title`} />
          </Form.Label>
          <Form.Control {...register('title')} isInvalid={errors.title} />
          <Form.Control.Feedback type="invalid">
            {errors.title && errors.title.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Text{' '}
            <LocalStorageTooltip identifier={`form_report_note_field_text`} />
          </Form.Label>
          <Form.Control
            as="textarea"
            {...register('text')}
            isInvalid={errors.text}
          />
          <Form.Control.Feedback type="invalid">
            {errors.text && errors.text.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Digipartner Note{' '}
            <LocalStorageTooltip
              identifier={`form_report_note_field_digipartner_note`}
            />
          </Form.Label>
          <Form.Control
            as="textarea"
            {...register('digipartner_note')}
            isInvalid={errors.digipartner_note}
          />
          <Form.Control.Feedback type="invalid">
            {errors.digipartner_note &&
              errors.digipartner_note.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>{' '}
        <Form.Group className="my-3">
          <Form.Label>
            PDF Report{' '}
            <LocalStorageTooltip
              identifier={`form_report_note_field_pdf_report`}
            />
          </Form.Label>
          <Form.Control
            size="sm"
            {...register('pdf_report')}
            type="file"
            required
            isInvalid={errors.pdf_report}
          />
          <Form.Control.Feedback type="invalid">
            {errors.pdf_report && errors.pdf_report.map(error => error)}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

Form.propTypes = {};

export default UpsertForm;
