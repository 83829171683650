import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Button, Row, Col, Modal, Form, Card } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Create from './Create';
import { AppSettings } from '../../../settings';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';
import React from 'react';

const Regions = () => {
  const [regions, setRegions] = useState([]);
  const [show, setShow] = useState(false);
  const [regionId, setRegionId] = useState('');
  const [apiCallInProcess, setApiCallInProcess] = useState(false);

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => (
        <>
          <IconButton
            icon="edit"
            className="me-2"
            size="sm"
            onClick={() => updateRegion(cell.row.values.id)}
          ></IconButton>
          <IconButton
            icon="trash-alt"
            variant="danger"
            size="sm"
            onClick={() => deleteRegion(cell.row.values.id)}
          ></IconButton>
        </>
      )
    },
    {
      accessor: 'name',
      Header: 'Name'
    },
    {
      accessor: 'slug',
      Header: 'Slug'
    },
    {
      accessor: 'location_code',
      Header: 'Location Code'
    },
    {
      accessor: 'location_name',
      Header: 'Location Name'
    },
    {
      accessor: 'country_iso_code',
      Header: 'Country ISO code'
    },
    {
      accessor: 'location_type',
      Header: 'Location Type'
    },
    {
      accessor: 'google_domain',
      Header: 'Google Domain'
    },
    {
      accessor: 'google_country',
      Header: 'Google Country'
    },
    {
      accessor: 'google_lang',
      Header: 'Google Lang'
    },
    {
      accessor: 'description',
      Header: 'Description'
    }
  ];

  function deleteRegion(id) {
    if (confirm("Attention! Are you sure you'd like to delete?")) {
      CallApi.delete(`/regions/${id}`).then(json => {
        if (json.status == 200) loadListing();
      });
    }
  }

  function updateRegion(id) {
    setRegionId(id);
    setShow(true);
  }

  function loadListing() {
    setApiCallInProcess(true);
    CallApi.get(`/regions`, true).then(data => {
      setApiCallInProcess(false);
      if (data.status == 200) {
        setRegions(data.data);
      }
    });
  }

  useEffect(() => {
    loadListing();
  }, []);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setRegionId('');
    setShow(false);
  };

  return (
    <>
      <PageInfo slug="regions" />
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={regions}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col className="col-auto" xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
              <Col className="col">
                <IconButton
                  icon="plus"
                  size="sm"
                  className="float-end"
                  onClick={handleShow}
                >
                  Add New
                </IconButton>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProcess}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={regions.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add a new Region
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Create
            loadListing={loadListing}
            setShow={setShow}
            regionId={regionId}
            setRegionId={setRegionId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Regions;
