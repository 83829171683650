import classNames from 'classnames';
import UpsertBudgetOverview from 'components/aida/budget-overviews/components/UpsertBudgetOverview';
import { activeColumns } from 'components/aida/home/BudgetOverviewListingColumnTypes';
import AvazaWidget from 'components/aida/home/components/AvazaWidget';
import BudgetOverviewAdditionalDetails from 'components/aida/home/components/BudgetOverviewAdditionalDetails';
import GoalChart from 'components/aida/home/components/GoalChart';
import SpendChart from 'components/aida/home/components/SpendChart';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import { AidaAppContext } from 'context/Context';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import useUrl from 'hooks/useUrl';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useContext } from 'react';
import { Card, Row, Col, FormCheck, Form, Badge } from 'react-bootstrap';

const useClientScopedBudgetOverviews = (
  startDate,
  endDate,
  checkboxesColumnColors,
  TableRef
) => {
  const [data, setData] = useState([]);
  const [apiCallInProgress, setApiCallInProgress] = useState(true);
  const [currentBudgetOverviewId, setCurrentBudgetOverviewId] = useState(null);
  const [showUpsertBudgetOverviewModal, setShowUpsertBudgetOverviewModal] =
    useState(false);
  const { aidaAppContext } = useContext(AidaAppContext);
  const url = useUrl();
  const CurrentClientId = useMemo(() => {
    // because until new client Id gets set in context, page opens & it shows the data with previous client.
    // so in query parameter type pages, first we should refernece query parameter & if not found then in these type of pages we should use context client.
    return url.get('client_id') ?? aidaAppContext.client?.id;
  }, [aidaAppContext.client?.id]);

  const renderRowSubComponent = ({ row }) => (
    <Row className="bg-light py-2">
      <Col xs={2} md={3}>
        <BudgetOverviewAdditionalDetails
          budgetOverviewId={row.original.id}
          setCurrentBudgetOverviewId={setCurrentBudgetOverviewId}
          setShowUpsertBudgetOverviewModal={setShowUpsertBudgetOverviewModal}
        />
      </Col>
      <Col xs={2} md={3}>
        <SpendChart budgetOverviewId={row.original.id} />
      </Col>
      <Col xs={2} md={3}>
        <GoalChart budgetOverviewId={row.original.id} />
      </Col>
      <Col xs={2} md={3}>
        <AvazaWidget clientId={row.original.client_id} />
      </Col>
    </Row>
  );

  const fetchBudgetOverviews = () => {
    if (CurrentClientId && startDate && endDate) {
      const queryParams = new URLSearchParams({
        startDate: getYYYYMMDDFormatDate(startDate),
        endDate: getYYYYMMDDFormatDate(endDate)
      });
      setApiCallInProgress(true);
      CallApi.get(
        `/clients/${CurrentClientId}/budget-overviews?${queryParams.toString()}`,
        true
      ).then(json => {
        setData(
          json.data.map(row => {
            checkboxesColumnColors.forEach(
              color => (row[`${color}_column`] = false)
            );
            return row;
          })
        );
        setApiCallInProgress(false);
      });
    }
  };

  useEffect(() => {
    fetchBudgetOverviews();
    return () => {};
  }, [endDate, CurrentClientId]);

  const getCheckboxColumn = color => ({
    accessor: `${color}_column`,
    Header: () => (
      <Badge className="p-2" bg={color} pill>
        {' '}
      </Badge>
    ),
    canSort: false,
    Cell: ({ cell, row: { index } }) => (
      <Form.Check
        type="checkbox"
        className="form-check fs-0 mb-0 d-flex align-items-center"
      >
        <Form.Check.Input
          className={classNames([cell.value && `bg-${color} border-${color}`])}
          checked={cell.value || false}
          onChange={e => {
            setData(old =>
              old.map((row, i) => {
                if (i == index) {
                  row[`${color}_column`] = cell?.value ? false : true;
                }
                return row;
              })
            );
          }}
        />
      </Form.Check>
    )
  });

  const RenderClientScopedBudgetOverviews = (
    <>
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            ref={TableRef}
            columns={useMemo(
              () => [
                ...checkboxesColumnColors.map(color =>
                  getCheckboxColumn(color)
                ),
                ...activeColumns
              ],
              []
            )}
            data={data}
            sortable
            renderRowSubComponent={renderRowSubComponent}
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProgress}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
      <UpsertBudgetOverview
        showModal={showUpsertBudgetOverviewModal}
        setShowModal={setShowUpsertBudgetOverviewModal}
        currentBudgetOverviewId={currentBudgetOverviewId}
        setCurrentBudgetOverviewId={setCurrentBudgetOverviewId}
        loadlisting={fetchBudgetOverviews}
      />
    </>
  );

  return {
    RenderClientScopedBudgetOverviews,
    data
  };
};

export default useClientScopedBudgetOverviews;
