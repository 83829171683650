import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';
import IconButton from 'components/common/IconButton';
import {
  getFirstDateOfCurrentYear,
  getFirstDayOfCurrentMonth,
  getLastDayOfCurrentMonth,
  getPreviousDateByMonthGap
} from 'helpers/utils';
import useUrl from 'hooks/useUrl';
import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import ClientOverviewMainTable from './ClientOverviewMain';
import SplitByMonth from './SplitByMonth';
import SplitByUser from './SplitByUser';
import { Link, useHistory } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { toolRoutes } from 'routes/routes';
import { AidaAppContext } from 'context/Context';
import { useContext } from 'react';

const AvazaClientOverview = () => {
  const [dateRange, setDateRange] = useState([
    getFirstDayOfCurrentMonth(),
    getLastDayOfCurrentMonth()
  ]);
  const { aidaAppContext } = useContext(AidaAppContext);
  const [startDate, endDate] = dateRange;

  return (
    <>
      <PageInfo slug="avaza_client_overview" />
      <Row className="g-3">
        <Col xs={12}>
          <Row className="g-3">
            <Col xs="auto">
              <Link
                to={
                  toolRoutes.children.find(
                    link => link.name == 'Avaza: Overview'
                  ).to
                }
              >
                <IconButton icon="arrow-left" variant="falcon-link" size="sm">
                  Back
                </IconButton>
              </Link>
            </Col>
            <Col xs="auto">
              <Flex alignItems={'center'} justifyContent={'center'}>
                <img src={aidaAppContext.client?.favicon} width="30" />
                <h4 className="ms-1 mb-0">
                  {aidaAppContext.client?.name || ''}
                </h4>
              </Flex>
            </Col>
            <Col />
            <Col xs="auto">
              <Form.Select
                placeholder="Select Interval"
                size="sm"
                onChange={e => {
                  switch (e.target.value) {
                    case 'YTD':
                      setDateRange([getFirstDateOfCurrentYear(), new Date()]);
                      break;

                    case 'last_6_months':
                      setDateRange([
                        getPreviousDateByMonthGap(5, 1),
                        new Date()
                      ]);
                      break;

                    case 'last_3_months':
                      setDateRange([
                        getPreviousDateByMonthGap(2, 1),
                        new Date()
                      ]);
                      break;

                    default:
                      break;
                  }
                }}
              >
                <option value="">Select Interval</option>
                <option value="YTD">YTD</option>
                <option value="last_6_months">Last 6 Months</option>
                <option value="last_3_months">Last 3 Months</option>
              </Form.Select>
            </Col>
            <Col xs="auto">
              <ReactDatePicker
                dateFormat="yyyy-MM-dd"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={update => setDateRange(update)}
                className="form-control pb-0 pt-1"
                calendarStartDay={1}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={6}>
          <SplitByMonth
            clientId={aidaAppContext.client?.id}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
        <Col xs={12} lg={6}>
          <SplitByUser
            clientId={aidaAppContext.client?.id}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
        <Col xs={12}>
          <ClientOverviewMainTable
            clientId={aidaAppContext.client?.id}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
    </>
  );
};

export default AvazaClientOverview;
