import CallApi from 'components/common/custom-components/CallApi';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useCustomTimeIntervalSelectbox = () => {
  const [interval, setInterval] = useState(null);

  const { data } = useQuery(
    'custom-time-intervals',
    () => CallApi.get('/custom-time-interval', false).then(json => json.data),
    {
      placeholderData: []
    }
  );

  const TimeIntervalSelectbox = ({
    name = 'custom_time_interval_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a time interval',
    onChange = interval => {},
    value,
    ...props
  }) => {
    useEffect(() => {
      if (data.length > 0) {
        setInterval(data.find(interval => interval.id == value));
      }
    }, [value]);

    return (
      <ReactSelect
        placeholder={placeholder}
        options={data}
        name={name}
        onChange={interval => onChange(interval)}
        value={interval}
        getOptionValue={interval => interval.id}
        getOptionLabel={interval => interval.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        {...props}
        isSearchable={isSearchable}
        classNamePrefix="react-select"
      />
    );
  };

  return TimeIntervalSelectbox;
};

export default useCustomTimeIntervalSelectbox;
