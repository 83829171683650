import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroupMember from 'components/app/kanban/GroupMember';
import ModalMediaContent from 'components/app/kanban/ModalMediaContent';
import Avatar from 'components/common/Avatar';
import CallApi from 'components/common/custom-components/CallApi';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Col,
  Form,
  Modal,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TaskSubscribers from './TaskSubscribers';

const SingleTask = ({ modal, setModal, taskId, setTaskId, refreshListing }) => {
  const [task, setTask] = useState({});
  const handleClose = () => {
    refreshListing();
    setTask({});
    setTaskId(null);
    setTask({});
    setModal(false);
  };

  useEffect(() => {
    if (taskId) {
      CallApi.get('/task/' + taskId, false).then(json => {
        if (json.status == 200) {
          setTask(json.data);
        }
      });
    }
  }, [taskId]);

  const closeTask = event => {
    event.preventDefault();
    CallApi.put('/task/close/' + taskId, new FormData(event.target)).then(
      json => {
        if (json.status == 200) {
          refreshListing();
          handleClose();
        }
      }
    );
  };
  const getClosedByName = closedByUser => {
    if (closedByUser.first_name) {
      if (closedByUser.last_name) {
        return closedByUser.first_name + ' ' + closedByUser.last_name;
      } else {
        return closedByUser.first_name;
      }
    } else {
      return closedByUser.username;
    }
  };

  return (
    <Modal
      show={modal}
      size="lg"
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="mt-6"
    >
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
        <CloseButton
          onClick={handleClose}
          className="btn btn-sm btn-circle d-flex flex-center transition-base"
        />
      </div>
      <Modal.Body className="p-0 pb-2">
        <div className="bg-light rounded-top-lg px-4 py-3">
          <h4 className="mb-1">{task.name || 'Loading...'}</h4>
          {taskId && (
            <p className="fs--2 mb-0">
              Added by{' '}
              <Link to="#!" className="text-600 fw-semi-bold text-capitalize">
                {task.created_by || '...'}
              </Link>
            </p>
          )}
        </div>
        <div className="p-4">
          <Row>
            <Col lg={9}>
              {/* Reviewers */}
              <ModalMediaContent title="Reviewers" icon="user">
                <TaskSubscribers taskId={taskId} />
              </ModalMediaContent>

              {/* Description */}
              <ModalMediaContent title="Description" icon="align-left">
                <div
                  dangerouslySetInnerHTML={{
                    __html: task.description || '...'
                  }}
                ></div>
              </ModalMediaContent>

              {/* Comments */}
              {task.status == 'open' && (
                <ModalMediaContent
                  title="Add your comment to close this task."
                  icon={['far', 'comment']}
                  headingClass="mb-3"
                  isHr={false}
                >
                  <Form onSubmit={closeTask}>
                    <Form.Control
                      as="textarea"
                      className="border-0 rounded-bottom-0 resize-none"
                      rows={5}
                      required
                      name="comment"
                    />
                    <Flex
                      justifyContent="end"
                      alignItems="center"
                      className="bg-light rounded-bottom p-2 mt-1"
                    >
                      <Button size="sm" color="primary" type="submit">
                        Close Task
                      </Button>
                    </Flex>
                  </Form>
                </ModalMediaContent>
              )}

              {/* Activity */}
              {task.status === 'closed' && (
                <ModalMediaContent
                  title="Activity"
                  icon="list-ul"
                  headingClass="mb-3"
                  isHr={false}
                >
                  <Flex className="mb-3">
                    <Link to="/user/profile">
                      <Avatar size="l" src={task.closed_by.avatar_url} />
                    </Link>
                    <div className="flex-1 ms-2 fs--1">
                      <p className="mb-0">
                        <Link
                          disabled
                          to="/user/profile"
                          className="fw-semi-bold"
                        >
                          {getClosedByName(task.closed_by)}
                        </Link>{' '}
                        closed this task with comment "{task.comment}"
                      </p>
                      <div className="fs--2">{task.closed_at}</div>
                    </div>
                  </Flex>
                </ModalMediaContent>
              )}
            </Col>
            <Col lg={3}>
              <h6>Task Status:</h6>
              <Nav className="flex-lg-column fs--1">
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Status</Tooltip>}
                >
                  <Nav.Item className="me-2 me-lg-0 rounded ">
                    <Nav.Link
                      to="#!"
                      className={'nav-link-card-details pb-1 text-capitalize'}
                    >
                      <FontAwesomeIcon
                        icon={task.status == 'open' ? 'check' : 'times-circle'}
                        className={'me-2'}
                      />
                      {task.status || '...'}
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              </Nav>
              <Nav className="flex-lg-column fs--1">
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Created At</Tooltip>}
                >
                  <Nav.Item className="me-2 me-lg-0">
                    <Nav.Link to="#!" className="nav-link-card-details">
                      <FontAwesomeIcon icon="clock" className="me-2" />
                      {task.created_at || '...'}
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              </Nav>
              <Nav className="flex-lg-column fs--1">
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Level</Tooltip>}
                >
                  <Nav.Item className="me-2 me-lg-0">
                    <Nav.Link to="#!" className="nav-link-card-details">
                      <FontAwesomeIcon icon="layer-group" className="me-2" />
                      {task.level || '...'}
                    </Nav.Link>
                  </Nav.Item>
                </OverlayTrigger>
              </Nav>
              {task.task_key && (
                <Nav className="flex-lg-column fs--1">
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Task Key</Tooltip>}
                  >
                    <Nav.Item className="me-2 me-lg-0">
                      <Nav.Link to="#!" className="nav-link-card-details">
                        <FontAwesomeIcon icon="book" className="me-2" />
                        {task.task_key || '...'}
                      </Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                </Nav>
              )}
              {task.status == 'closed' && (
                <>
                  <Nav className="flex-lg-column fs--1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Closed at</Tooltip>}
                    >
                      <Nav.Item className="me-2 me-lg-0">
                        <Nav.Link to="#!" className="nav-link-card-details">
                          <FontAwesomeIcon icon="clock" className="me-2" />
                          {task.closed_at || '...'}
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                  <Nav className="flex-lg-column fs--1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Closed by</Tooltip>}
                    >
                      <Nav.Item className="me-2 me-lg-0">
                        <Nav.Link
                          to="#!"
                          className="nav-link-card-details text-capitalize"
                        >
                          <FontAwesomeIcon icon="user" className="me-2" />
                          {getClosedByName(task.closed_by)}
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                </>
              )}

              <h6 className="mt-3">Relation Tree:</h6>
              {task.level == 'User' && (
                <Nav className="flex-lg-column fs--1">
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>User</Tooltip>}
                  >
                    <Nav.Item className="me-2 me-lg-0">
                      <Nav.Link to="#!" className="nav-link-card-details">
                        <img
                          src={task.taskable.avatar_url}
                          width="17"
                          className="me-1"
                        />{' '}
                        {task.taskable.name || '...'}
                      </Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                </Nav>
              )}
              {task.level == 'Client' && (
                <Nav className="flex-lg-column fs--1">
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Client</Tooltip>}
                  >
                    <Nav.Item className="me-2 me-lg-0">
                      <Nav.Link to="#!" className="nav-link-card-details">
                        <img
                          src={task.taskable.favicon}
                          width="17"
                          className="me-1"
                        />{' '}
                        {task.taskable.name || '...'}
                      </Nav.Link>
                    </Nav.Item>
                  </OverlayTrigger>
                </Nav>
              )}
              {task.level == 'Account' && (
                <>
                  <Nav className="flex-lg-column fs--1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Client</Tooltip>}
                    >
                      <Nav.Item className="me-2 me-lg-0">
                        <Nav.Link to="#!" className="nav-link-card-details">
                          <img
                            src={task.taskable.client.favicon}
                            width="17"
                            className="me-1"
                          />
                          {task.taskable.client.name || '...'}
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                  <Nav className="flex-lg-column fs--1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Account Network</Tooltip>}
                    >
                      <Nav.Item className="me-2 me-lg-0">
                        <Nav.Link to="#!" className="nav-link-card-details">
                          <FontAwesomeIcon
                            icon={[
                              task.taskable.network.icon_prefix,
                              task.taskable.network.icon_name
                            ]}
                            className="me-2"
                          />
                          {task.taskable.network.name || '...'}
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                </>
              )}
              {task.level == 'Budget Overview' && (
                <>
                  <Nav className="flex-lg-column fs--1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Client</Tooltip>}
                    >
                      <Nav.Item className="me-2 me-lg-0">
                        <Nav.Link to="#!" className="nav-link-card-details">
                          <img
                            src={task.taskable.account.client.favicon}
                            width="17"
                            className="me-1"
                          />
                          {task.taskable.account.client.name || '...'}
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                  <Nav className="flex-lg-column fs--1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Account Network</Tooltip>}
                    >
                      <Nav.Item className="me-2 me-lg-0">
                        <Nav.Link to="#!" className="nav-link-card-details">
                          <FontAwesomeIcon
                            icon={[
                              task.taskable.account.network.icon_prefix,
                              task.taskable.account.network.icon_name
                            ]}
                            className="me-2"
                          />
                          {task.taskable.account.network.name || '...'}
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                  <Nav className="flex-lg-column fs--1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Budget Overview Short Code</Tooltip>}
                    >
                      <Nav.Item className="me-2 me-lg-0">
                        <Nav.Link to="#!" className="nav-link-card-details">
                          <FontAwesomeIcon icon="code" className="me-2" />
                          {task.taskable.short_code || '...'}
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SingleTask;
