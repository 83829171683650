import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { ReportOverviewPageContext } from './ReportOverview';

const ReportPreview = ({ report_iframeUrl }) => {
  const { reportOverviewContext } = useContext(ReportOverviewPageContext);
  const pdf_report = reportOverviewContext.currentOpenReportNote?.pdf_report;

  return (
    <Card>
      <Card.Body>
        {reportOverviewContext.loadingNotes ? (
          <Alert className="text-center">
            <FontAwesomeIcon icon="info-circle" className="mr-2" />{' '}
            <span>Loading Preview...</span>
          </Alert>
        ) : !pdf_report &&
          !report_iframeUrl &&
          !reportOverviewContext.loadingNotes ? (
          <Alert className="text-center">
            <FontAwesomeIcon icon="info-circle" className="mr-2" />{' '}
            <span>No Report is connected to the text note!</span>
          </Alert>
        ) : (
          <iframe
            src={pdf_report || report_iframeUrl}
            frameBorder="0"
            height="1250px"
            width="100%"
          ></iframe>
        )}
      </Card.Body>
    </Card>
  );
};

export default ReportPreview;
