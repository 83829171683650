import CallApi from 'components/common/custom-components/CallApi';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { FlexAbsenceContext } from './FlexAbsence';
import ptBr from 'date-fns/locale/pt-BR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, addYears } from 'date-fns';
registerLocale('pt-BR', ptBr);

function FlexAbsenceForm() {
  const [date, setDate] = useState(null);
  const [endDate, setEndDate] = useState('');
  const [categories, setCategories] = useState([]);
  const [validated, setValidated] = useState(false);
  const { context, setContext } = useContext(FlexAbsenceContext);
  const [hours, setHours] = useState(new Date().setHours(0, 0, 0, 0));

  const [formData, setFormData] = useState({
    category: '',
    entire_day: 0,
    negative_hours: 1,
    hours: '',
    note: ''
  });

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    CallApi.get('/flex-absence/categories', false).then(json => {
      if (json.status == 200) setCategories(json.data);
    });
  };

  const onChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? +target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    const body = new FormData(form);

    if (formData.entire_day) {
      body.append('entire_day', '1');
    }

    if (formData.negative_hours) {
      body.append('negative_hours', '1');
    }

    CallApi.post('/flex-absence', body).then(json => {
      if (json.status == 200) {
        setFormData({
          category: '',
          entire_day: 0,
          negative_hours: 0,
          hours: '',
          note: ''
        });
        setDate(null);
        setEndDate(null);
        setHours(new Date().setHours(0, 0, 0, 0));
        setContext({ ...context, refresh: !context.refresh });
      }
    });
  };

  return (
    <Form id="add-flex-absence-entry" onSubmit={onSubmit}>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm="4">
          Start Date
        </Form.Label>
        <Col>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control form-control-sm"
            selected={date}
            onChange={value => {
              setDate(value);
              setFormData({ ...formData, date: value });
            }}
            autoComplete="off"
            minDate={addDays(new Date(context.approved_flex_absences_till), 1)}
            placeholderText="Please select date"
            name="date"
            calendarStartDay={1}
            required
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm="4">
          End Date
        </Form.Label>
        <Col>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            autoComplete="off"
            className="form-control form-control-sm"
            selected={endDate}
            onChange={value => {
              setEndDate(value);
              setFormData({ ...formData, endDate: value });
            }}
            placeholderText="Please select date"
            name="end_date"
            calendarStartDay={1}
            isClearable
            minDate={addDays(new Date(context.approved_flex_absences_till), 1)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm="4">
          Category
        </Form.Label>
        <Col>
          <Form.Select
            name="category"
            value={formData.category || ''}
            onChange={onChange}
            size="sm"
            required
          >
            <option value="">Please select category</option>
            {categories.map(category => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm="4">
          Entire Day?
        </Form.Label>
        <Col>
          <Form.Check
            className="mt-1"
            type="switch"
            name="entire_day"
            onChange={onChange}
            checked={formData.entire_day || endDate || ''}
            disabled={endDate}
          />
        </Col>
      </Form.Group>
      {formData.category == 'Flex' && (
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="4">
            Negative Hours?
          </Form.Label>
          <Col xs="auto">
            <Form.Check
              className="mt-1"
              type="switch"
              name="negative_hours"
              onChange={onChange}
              checked={formData.negative_hours || ''}
              label={
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Uncheck this if you worked extra</Tooltip>}
                >
                  <span className="ms-1">
                    <FontAwesomeIcon icon="info-circle" />
                  </span>
                </OverlayTrigger>
              }
            />
          </Col>
        </Form.Group>
      )}
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm="4">
          Hours?
        </Form.Label>
        <Col>
          <ReactDatePicker
            className="form-control form-control-sm"
            selected={hours}
            onChange={hours => {
              setHours(hours);
              setFormData({ ...formData, hours: hours });
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Hours"
            dateFormat="HH:mm"
            name="hours"
            locale="pt-BR"
            disabled={formData.entire_day || endDate || ''}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm="4">
          Note
        </Form.Label>
        <Col>
          <Form.Control
            as="textarea"
            name="note"
            value={formData.note || ''}
            onChange={onChange}
            required
          />
        </Col>
      </Form.Group>
    </Form>
  );
}

export default FlexAbsenceForm;
