import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import IconButton from 'components/common/IconButton';
import { AidaAppContext } from 'context/Context';
import { capitalize } from 'helpers/utils';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import useUserSelectBox from '../custom-form-hooks/useUserSelectBox';
import MonthlyNotes from './components/MonthlyNotes';

const Create = ({
  loadListing,
  setShow,
  clientId,
  setClientId,
  show,
  onHide
}) => {
  const emptyClient = {
    name: '',
    avaza_id: '',
    type: '',
    favicon: '',
    ecom: '',
    color: '',
    view_id: '',
    status: '',
    website_url: '',
    search_console_id: '',
    client_note: '',
    user_project_lead: ''
  };

  const [subscribers, setSubscribers] = useState([]);
  const [subscriptionValue, setsubscriptionValue] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [validated, setValidated] = useState(false);
  const [newClient, setNewClient] = useState(emptyClient);
  const { aidaAppContext, setAidaAppContext } = useContext(AidaAppContext);
  function loadMetricListing() {
    CallApi.get(`/metrics`, false).then(data => {
      if (data.status == 200) setMetrics(data.data);
    });
  }

  useEffect(() => {
    if (clientId) {
      CallApi.get(`/clients/${clientId}`, false).then(data => {
        setNewClient({
          name: data.data.name,
          avaza_id: data.data.avaza_id,
          type: data.data.type,
          ecom: data.data.ecom,
          favicon: data.data.favicon,
          status: data.data.status,
          color: data.data.color,
          view_id: data.data.view_id,
          website_url: data.data.website_url,
          search_console_id: data.data.search_console_id,
          client_note: data.data.client_note
            ? data.data.client_note
            : data.data.client_note,
          user_project_lead: data.data.user_project_lead
        });

        if (data.data.subscriptions.length > 0) {
          setsubscriptionValue(
            data.data.subscriptions.map(item => {
              const subscription = {};
              subscription['value'] = item.user.id;
              subscription['label'] = getUsersName(item.user);
              return subscription;
            })
          );
        }
      });
    }
    loadMetricListing();
    loadUserListing();
  }, [clientId]);

  function loadUserListing() {
    CallApi.get(`/users?filter[role.name]=admin,user`, false).then(data => {
      if (data.status == 200) {
        setSubscribers(
          data.data.map(user => {
            let subscriber = {};
            subscriber['value'] = user.id;
            subscriber['label'] = getUsersName(user);
            return subscriber;
          })
        );
      }
    });
  }

  const getUsersName = user => {
    let username = '';
    if (user.first_name) {
      if (user.last_name) {
        username = user.first_name + ' ' + user.last_name;
      } else {
        username = user.first_name;
      }
    } else {
      username = user.username;
    }
    return <NameWithIcon name={username} icon={user.avatar_url} />;
  };

  const onInputChange = e => {
    setNewClient({ ...newClient, [e.target.name]: e.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      toast.error('Validation Failed');
      setValidated(true);
      return;
    }

    let formData = new FormData(event.target);

    if (subscriptionValue) {
      subscriptionValue.forEach((subscription, key) => {
        formData.append(`subscription_id[${key}]`, subscription.value);
      });
    }

    formData.append('user_project_lead', newClient.user_project_lead);

    if (clientId === '') {
      CallApi.post(`/clients`, formData).then(json => {
        if (json.status == 200) {
          loadListing();
          setShow(false);
          dismissModal();
          setAidaAppContext({
            ...aidaAppContext,
            refreshClientSelectBox: !aidaAppContext.refreshClientSelectBox
          });
        }
      });
    } else {
      CallApi.put(`/clients/${clientId}`, formData).then(json => {
        if (json.status == 200) {
          loadListing();
          setShow(false);
          dismissModal();
          setClientId('');
        }
      });
    }
  };

  const colors = ['green', 'blue', 'yellow', 'grey'];

  const dismissModal = () => {
    setNewClient({});
    setsubscriptionValue(null);
    setValidated(false);
    onHide();
  };

  const UserProjectLeadSelectBox = useUserSelectBox();

  return (
    <Modal show={show} size={clientId ? 'xl' : ''} onHide={dismissModal}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add a new Client
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} lg={clientId ? 6 : 12}>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="upsert-client"
            >
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>
                  Name*{' '}
                  <LocalStorageTooltip identifier={`form_client_field_name`} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={newClient.name}
                  placeholder="Enter name"
                  onChange={e => onInputChange(e)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="avazaId">
                <Form.Label>
                  Avaza ID*{' '}
                  <LocalStorageTooltip
                    identifier={`form_client_field_avaza_id`}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  name="avaza_id"
                  value={newClient.avaza_id}
                  placeholder="Enter Avaza Id"
                  onChange={e => onInputChange(e)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please add avaza id.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="color">
                <Form.Label>
                  Color*{' '}
                  <LocalStorageTooltip identifier={`form_client_field_color`} />
                </Form.Label>
                <Form.Select
                  name="color"
                  value={newClient.color}
                  onChange={e => onInputChange(e)}
                  required
                >
                  <option value="">Please select color</option>
                  {colors.map((color, i) => (
                    <option key={i} value={color}>
                      {capitalize(color)}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please choose color.
                </Form.Control.Feedback>
              </Form.Group>

              {newClient.type == 'UA' && (
                <Form.Group className="mb-3" controlId="view_id">
                  <Form.Label>
                    View ID{' '}
                    <LocalStorageTooltip
                      identifier={`form_client_field_view_id`}
                    />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={newClient.view_id}
                    name="view_id"
                    placeholder="Enter view id"
                    onChange={e => onInputChange(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter view id.
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="favicon">
                <Form.Label className="w-100">
                  <div className="align-items-center row">
                    <div className="col">
                      <h6 className="mb-0">Favicon*</h6>
                    </div>
                    <div className="text-right col-auto">
                      <a
                        className="py-1 fs--1 font-sans-serif"
                        target="_blank"
                        rel="noreferrer"
                        href="https://favicongrabber.com/"
                      >
                        Download Icon
                      </a>
                    </div>
                  </div>
                </Form.Label>
                <Form.Control
                  type="file"
                  name="favicon"
                  placeholder="Select image"
                  onChange={e => onInputChange(e)}
                  required={clientId ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a favicon.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="client_type">
                <Form.Label>
                  Client Type*{' '}
                  <LocalStorageTooltip
                    identifier={`form_client_field_client_type`}
                  />
                </Form.Label>
                <Form.Select
                  name="ecom"
                  value={newClient.ecom}
                  onChange={e => onInputChange(e)}
                  required
                >
                  <option value="">Please select client type</option>
                  <option value="leadgen">leadgen</option>
                  <option value="ecom">ecom</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please choose client type.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="status">
                <Form.Label>
                  Status*{' '}
                  <LocalStorageTooltip
                    identifier={`form_client_field_status`}
                  />
                </Form.Label>
                <Form.Select
                  name="status"
                  value={newClient.status}
                  onChange={e => onInputChange(e)}
                  required
                >
                  <option value="">Please select a status</option>
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                  <option value="removed">Removed</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please choose a status.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="search_console_id">
                <Form.Label>
                  Search Console Id{' '}
                  <LocalStorageTooltip
                    identifier={`form_client_field_search_console_id`}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  value={newClient.search_console_id}
                  name="search_console_id"
                  placeholder="Enter search console id"
                  onChange={e => onInputChange(e)}
                />
                {/*<Form.Control.Feedback type="invalid">Please choose a website url.</Form.Control.Feedback>*/}
              </Form.Group>

              <Form.Group className="mb-3" controlId="client_note">
                <Form.Label>
                  Client Note{' '}
                  <LocalStorageTooltip
                    identifier={`form_client_field_client_note`}
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={newClient.client_note}
                  name="client_note"
                  onChange={e => onInputChange(e)}
                  placeholder="Enter client note"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Project Lead*{' '}
                  <LocalStorageTooltip
                    identifier={`form_client_field_project_lead`}
                  />
                </Form.Label>
                <UserProjectLeadSelectBox
                  name="user_project_lead"
                  value={newClient.user_project_lead}
                  onChange={projectLead =>
                    setNewClient({
                      ...newClient,
                      user_project_lead: projectLead.id
                    })
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Subscriber(s){' '}
                  <LocalStorageTooltip
                    identifier={`form_client_field_subscribers`}
                  />
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={subscribers}
                  placeholder="Select..."
                  isMulti
                  classNamePrefix="react-select"
                  value={subscriptionValue}
                  onChange={value => setsubscriptionValue(value)}
                />
                {/*<Form.Control.Feedback type="invalid">Please choose a secondary metric.</Form.Control.Feedback>*/}
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} lg={clientId ? 6 : 12}>
            {clientId && <MonthlyNotes client_id={clientId} />}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <IconButton
          icon="times-circle"
          size="sm"
          onClick={() => dismissModal()}
          variant="falcon-light"
        >
          Close
        </IconButton>
        <IconButton icon="save" size="sm" form="upsert-client" type="submit">
          Save
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

export default Create;
