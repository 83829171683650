export const pagesRoutes = {
  label: 'Pages',
  children: [
    {
      name: 'Budget Overviews',
      icon: 'comments',
      to: '/aida/budget-overviews',
      active: true,
      clientAppendable: false
    },
    {
      name: 'Tasks',
      icon: 'tasks',
      to: '/aida/tasks',
      active: true,
      clientAppendable: false
    },
    {
      name: 'Automations',
      icon: 'cogs',
      to: '/aida/lambda-automations',
      active: true,
      clientAppendable: true
    },
    {
      name: 'Reports',
      icon: 'chart-pie',
      to: '/aida/reports',
      active: true,
      clientAppendable: false
    }
  ]
};

export const toolRoutes = {
  label: 'tools',
  dropdownType: 'app',
  children: [
    {
      name: 'Avaza: Overview',
      icon: 'clock',
      to: '/aida/avaza-ads-overviews',
      active: true
    },
    {
      name: 'Avaza: Invoice Rows',
      icon: 'clock',
      to: '/aida/avaza-timerows',
      active: true
    },
    {
      name: 'Competitor Analysis',
      icon: 'chart-pie',
      to: '/competitor-analysis',
      active: true
    },
    {
      name: 'Keyword Research',
      icon: 'chart-pie',
      to: '/aida/keyword-research',
      active: true
    }
  ]
};

export const appRoutes = {
  label: 'settings',
  dropdownType: 'app',
  children: [
    {
      name: 'Clients',
      icon: 'users',
      to: '/aida/clients',
      active: true
    },
    {
      name: 'Accounts',
      icon: 'user-circle',
      to: '/aida/accounts',
      active: true
    },
    {
      name: 'Networks',
      icon: 'project-diagram',
      to: '/aida/networks',
      active: true
    },
    {
      name: 'Regions',
      icon: 'map-marker-alt',
      to: '/aida/regions',
      active: true
    },
    {
      name: 'Metrics',
      icon: 'chart-line',
      to: '/aida/metrics',
      active: true
    },
    {
      name: 'SEO Projects',
      icon: 'search',
      to: '/aida/seo-projects',
      active: true
    }
  ]
};

export const profileDropdownRoutes = [
  {
    name: 'Flex Absence',
    to: '/aida/flex-absence'
  }
];

export const dashboardRoutes = {};

export const modulesRoutes = {};

export default [pagesRoutes];
